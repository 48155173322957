import { getEnv } from '@move-web-essentials-utils/env';
import universalApiClient from '../../../../api/lib/apiClient';
import { MOVE_GOT_V2_API_BASE_PATH } from '../../../../shared/config';
import { getExperimentGroupsAsQueryParam } from './getExperimentGroupsAsQueryParam';

const getAdConfigBaseUrl = () => {
  if (IS_SERVER) {
    return `${getEnv(
      'move_consumer_api_gateway_url'
    )}${MOVE_GOT_V2_API_BASE_PATH}`;
  }
  return MOVE_GOT_V2_API_BASE_PATH;
};

export const fetchAdConfig = (
  params: { pagetype: string; experimentGroups?: Record<string, string> },
  options: { locale?: string }
) => {
  const experimentGroupsAsQueryParam =
    params.experimentGroups &&
    getExperimentGroupsAsQueryParam(params.experimentGroups);

  return universalApiClient({
    metricsId: 'svc_getHomepageAdConfig',
    ...options,
  })({
    method: 'GET',
    url: `${getAdConfigBaseUrl()}/settings?pagetype=${params.pagetype}${
      experimentGroupsAsQueryParam || ''
    }`,
  });
};
