import { IntlFormatters } from 'react-intl';

export const getMakeModelLabel = (
  ms: { makeName?: string; modelName?: string; trm?: string }[],
  formatMessage: IntlFormatters['formatMessage']
) => {
  const makes = ms
    .map(({ makeName }) => makeName)
    .filter(Boolean)
    .filter((item, index, array) => array.indexOf(item) === index);

  const models = ms
    .map(({ modelName }) => modelName)
    .filter(Boolean)
    .filter((item, index, array) => array.indexOf(item) === index);

  const trims = ms.map(({ trm }) => trm).filter(Boolean);

  const makeLabel =
    makes.length > 1
      ? formatMessage({ id: 'make_count' }, { count: makes.length })
      : makes[0];
  const modelLabel =
    models.length && (models.length > 1 || makes.length > 1)
      ? formatMessage({ id: 'model_count' }, { count: models.length })
      : models[0];

  const trimLabel =
    trims.length && (trims.length > 1 || models.length > 1 || makes.length > 1)
      ? formatMessage({ id: 'trim_count' }, { count: trims.length })
      : trims[0];

  if (makes.length === 1 && models.length === 1) {
    return `${makeLabel} ${modelLabel}${
      trims.length > 0 ? ` ${trimLabel}` : ''
    }`;
  }

  return (
    [makeLabel, modelLabel, trimLabel].filter(Boolean).join(', ') ||
    formatMessage({ id: 'all_makes' })
  );
};
