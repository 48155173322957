import React, { FC, SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import Card from '@move-ui/card';
import Text, { TextSize } from '@move-ui/text';
import { IMAGE_FIT, ImageLoader } from '@move-ui/image-loader';
import { getImageUrl } from '@move-web-essentials-utils/images';

import styles from './Listing.css';
import {
  getPriceToDisplay,
  getRelevantPrice,
  shouldShowPriceOnRequest,
} from '../../../utils/price';
import { Prices } from '../../../shared/types/Prices';
import { RenderAdditionalActionButtons } from './types';
import { ImageRef } from '../../types/ImageRef';
import { Taxes } from '../Taxes';

type Props = {
  id: string;
  index?: number;
  image?: ImageRef;
  title: string;
  structuredTitle?: string;
  mileage: string;
  prices: Prices;
  isDealer?: boolean;
  renderAdditionalActionButtons?: RenderAdditionalActionButtons;
  onImageLoaded?: (event: SyntheticEvent<HTMLImageElement, Event>) => void;
  isTitleHidden?: boolean;
  lazyLoad?: boolean;
  isMultiInquiry?: boolean;
  isConditionNew?: boolean;
  province?: string;
};

const Listing: FC<Props> = ({
  id,
  index,
  image,
  mileage,
  title,
  prices,
  structuredTitle,
  renderAdditionalActionButtons,
  onImageLoaded,
  isTitleHidden,
  lazyLoad = false,
  isMultiInquiry,
  isDealer,
  isConditionNew,
  province,
}) => {
  const { formatMessage } = useIntl();

  const showPriceOnRequest = shouldShowPriceOnRequest(prices, isDealer);
  const priceToDisplay = getPriceToDisplay(prices, isDealer);

  const src = image && getImageUrl(image.uri, { size: 225, format: 'webp' });

  const srcSet =
    image && `${getImageUrl(image.uri, { size: 225, format: 'webp' })} ${225}w`;

  const additionalButtons = renderAdditionalActionButtons?.({
    className: styles.actionButtons,
    id,
    index,
    isMultiInquiry,
  });

  return (
    <Card
      className={cx(styles.component, {
        [styles.withoutTitle]: isTitleHidden,
      })}
    >
      <div className={styles.imageWrapper}>
        <div className={styles.imageLoaderHabitat}>
          <ImageLoader
            fit={IMAGE_FIT.COVER}
            title={structuredTitle || title}
            srcSet={srcSet}
            sizes="225px"
            src={src}
            onImageLoaded={onImageLoaded}
            lazyLoad={lazyLoad}
          />
        </div>
        {additionalButtons}
      </div>
      <Text
        className={cx(styles.title, styles.data, {
          [styles.screenReaderOnly]: isTitleHidden,
        })}
        size={TextSize.S}
        color="neutral-100"
        bold
        data-testid="ListingSlider-Listing"
      >
        {structuredTitle || title}
      </Text>
      <Text size={TextSize.XXS} color="neutral-200" className={styles.data}>
        {mileage}
      </Text>
      <Text
        size={TextSize.L}
        bold
        color="neutral-100"
        className={styles.data}
        data-testid="ListingSlider-Price"
      >
        {showPriceOnRequest
          ? formatMessage({ id: 'price_on_request' })
          : priceToDisplay}
      </Text>
      <Taxes
        className={styles.data}
        isDealer={isDealer}
        price={getRelevantPrice(prices, isDealer)}
        isConditionNew={isConditionNew}
        province={province}
        isInCard
      />
    </Card>
  );
};

export default Listing;
