import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  FC,
  ComponentProps,
  ChangeEvent,
  SyntheticEvent,
} from 'react';
import { useIntl } from 'react-intl';
import { Link, LinkSize } from '@move-ui/link';

import { Autosuggest } from '@move-ui/autosuggest';
import { Dropdown } from '@move-ui/dropdown';
import { Checkbox } from '@move-ui/checkbox';
import { LocationIcon } from '@move-ui/icons-asset-catalog';
import useClickOutsideObserver from '@move-web-essentials-hooks/use-click-outside-observer';

import uuid from 'uuid/v4';
import poweredByGoogle from '@move-core/asset-catalog/images/ca/web/img-poweredbygoogle.svg';

import { isGeolocationAvailable } from '../../../../../lib/geoLocation';
import { locationToClientFormat } from '../../../../shared/utils/geoLocation';
import { resolveSuggestionDetails } from '../../../../stores/geoLocation/api';
import {
  getRadiusValue,
  convertRadiusToString,
} from '../../../../shared/domains/geoLocation/services';

import {
  RADIUS_OPTIONS,
  DEFAULT_LIMITED_TO_PROVINCE,
} from '../../../../shared/domains/geoLocation/config';

import LocationModalFooter from './LocationModalFooter';
import { LOCATION_RESULTS } from './LocationForm.tracking';
import { useResolveAddress } from './useResolveAddress';

import styles from './LocationForm.css';
import { DEFAULT_LOCATION_RADIUS } from '../../../../../shared/config';
import { LocationSuggestion } from './LocationSuggestion';
import useTracking from '../../../../utils/tracking/useTracking';
import { LocationArea } from '../../types/LocationArea';
import { Province } from '../../../../shared/types/Province';
import { Coordinates } from '../../../../shared/types/Coordinates';
import { LocationSuggestion as Suggestion } from '../../types/LocationSuggestion';

type Props = Pick<
  ComponentProps<typeof LocationModalFooter>,
  'renderResultCounter'
> & {
  onCancel: (value: { initialLocation?: LocationArea }) => void;
  onChange: (
    value: Pick<LocationArea, 'radius' | 'isLimitedToProvince'> &
      Pick<NonNullable<LocationArea['location']>, 'position' | 'province'>
  ) => void;
  onSubmit: (
    value: Pick<LocationArea, 'radius' | 'isLimitedToProvince'> & {
      addressText: string;
      resolvedLocation: LocationArea['location'];
    }
  ) => void;
  area?: LocationArea;
};

export const LocationForm: FC<Props> = ({
  onCancel,
  onChange,
  onSubmit,
  renderResultCounter,
  area,
}) => {
  const sessionToken = useRef(uuid());
  const initialLocationRef = useRef(area);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const initialAddress = initialLocationRef.current?.location?.address;
  // states for form inputs
  const [addressText, setAddressText] = useState(initialAddress ?? '');
  const [radius, setRadius] = useState(
    getRadiusValue(initialLocationRef.current)
  );
  const [isLimitedToProvince, setLimitedToProvince] = useState(
    initialLocationRef.current?.isLimitedToProvince ??
      DEFAULT_LIMITED_TO_PROVINCE
  );

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(
    initialAddress ? { value: initialAddress } : undefined
  );

  const { formatNumber, formatMessage } = useIntl();
  const { trackEvent } = useTracking();

  const geolocationEnabled = isGeolocationAvailable();
  const [error, setError] = useState(false);

  const {
    isLocateMe,
    isResolving,
    suggestions,
    fetchResults,
    allowLocateMe,
    resolvedLocation,
    setSuggestions,
    setResolvedLocation,
  } = useResolveAddress({
    initialLocation: initialLocationRef.current,
  });

  const triggerOnChange = (next: {
    position?: Coordinates | null;
    province?: Province | null;
    radius?: string | number;
    isLimitedToProvince?: boolean;
  }) => {
    const {
      position = resolvedLocation?.location?.position,
      province = resolvedLocation?.location?.province,
    } = next;

    onChange({
      position: position ?? undefined,
      province: province ?? undefined,
      radius: next.radius ?? radius,
      isLimitedToProvince: next.isLimitedToProvince ?? isLimitedToProvince,
    });
  };

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setError(false);
    setAddressText(value);

    if (value) {
      fetchResults(value, sessionToken.current);
    } else {
      setSuggestions([]);
    }
  };

  const handleAddressClear = () => {
    setError(false);
    setAddressText('');
    setSuggestions([]);

    triggerOnChange({ position: null, province: null });
  };

  const handleRadiusChange = (value: string) => {
    setRadius(value);
    triggerOnChange({ radius: value });
  };

  const handleLimitedToProvinceChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setLimitedToProvince(checked);

    triggerOnChange({ isLimitedToProvince: checked });
  };

  const handleReset = () => {
    setRadius(convertRadiusToString(DEFAULT_LOCATION_RADIUS));
    setLimitedToProvince(DEFAULT_LIMITED_TO_PROVINCE);
    handleAddressClear();

    trackEvent({ eventAction: 'LocationReset' });
  };

  const handleCancel = () =>
    onCancel({ initialLocation: initialLocationRef.current });

  useEffect(() => {
    if (isLocateMe && allowLocateMe !== undefined && resolvedLocation) {
      trackEvent({
        eventAction: `LocateMe${allowLocateMe ? 'Success' : 'Cancel'}`,
      });

      triggerOnChange(resolvedLocation);

      setAddressText(resolvedLocation?.location?.name || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocateMe, allowLocateMe, resolvedLocation]);

  const handleSelectSuggestion = useCallback(
    async (suggestion: Suggestion) => {
      setError(false);
      setSelectedSuggestion(suggestion);
      setAddressText(suggestion.value);
      if (suggestion.locateMe) {
        fetchResults();
        return;
      }

      try {
        const suggestionDetails = await resolveSuggestionDetails({
          placeId: suggestion.placeId,
          sessionToken: sessionToken.current,
        });

        const { province, value, city, zip, latitude, longitude } =
          locationToClientFormat(suggestionDetails) as any;

        // To improve UX we don't consider searches without city and province name - country search e.g. 'Canada'.
        // Keep only the value for the dropdown, so it be reset
        const location =
          !city && !province?.name
            ? { address: value }
            : {
                position: { latitude, longitude },
                address: value,
                province: {
                  code: province.code,
                  name: province.name,
                },
                postalCode: zip,
                city,
              };

        setResolvedLocation({ location, isLimitedToProvince, radius });
        triggerOnChange(location);
        // Renew sessionToken after selecting one address from the suggestion list
        sessionToken.current = uuid();
        return location;
      } catch {
        handleAddressClear();
        // Renew sessionToken after selecting one address from the suggestion list
        sessionToken.current = uuid();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionToken, triggerOnChange, handleAddressClear, setResolvedLocation]
  );

  const selectFirstSuggestion = async () => {
    if (resolvedLocation) {
      return;
    }
    if (suggestions.length > 0) {
      return await handleSelectSuggestion(suggestions[0]);
    }
    setError(addressText.length > 0 && addressText !== initialAddress);
  };

  useEffect(() => {
    if (isResolving || !isSubmitted) {
      return;
    }

    trackEvent(LOCATION_RESULTS, {
      isChange:
        Boolean(initialLocationRef.current) &&
        Object.keys(initialLocationRef.current || {}).length > 0,
      postalCode: resolvedLocation?.location?.postalCode,
      city: resolvedLocation?.location?.city,
      radius,
    });

    onSubmit({
      addressText,
      radius,
      isLimitedToProvince,
      resolvedLocation: resolvedLocation?.location,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isResolving,
    isSubmitted,
    addressText,
    radius,
    isLimitedToProvince,
    resolvedLocation,
  ]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const location = addressText === '' || resolvedLocation?.location;
    if (location) {
      setIsSubmitted(!!location);
    } else {
      const newLocation = await selectFirstSuggestion();
      setIsSubmitted(!!newLocation ?? false);
    }
  };

  const containerRef = useRef(null);
  useClickOutsideObserver(containerRef, selectFirstSuggestion);

  const handleRequestCurrentLocation = () => {
    trackEvent({ eventAction: 'LocateMeClick' });
    inputRef.current?.focus();
    // Set to an initial value for the menu to show with locate me option
    setAddressText(' ');
    setSuggestions([
      {
        value: formatMessage({ id: 'locate_me_loading' }),
        locateMe: formatMessage({ id: 'locate_me_automatically' }),
      },
    ]);
  };

  const options = RADIUS_OPTIONS.map((option) => ({
    label: formatMessage(
      { id: 'LocationFilter.distance' },
      { distance: formatNumber(option) }
    ),
    value: option.toString(),
  }));

  return (
    <form data-testid="LocationForm" autoComplete="off" onSubmit={handleSubmit}>
      <Link
        className={styles.reset}
        data-testid="LocationHeaderResetButton"
        size={LinkSize.Size300}
        onClick={handleReset}
        disabled={!addressText}
      >
        {formatMessage({ id: 'reset_all' })}
      </Link>
      <fieldset className={styles.component}>
        <div ref={containerRef}>
          <Autosuggest
            withItemDivider
            inputRef={inputRef}
            id="LocationAutosuggest"
            isResolving={isResolving}
            value={addressText || ''}
            suggestions={suggestions}
            menuClassName={styles.menu}
            onChange={handleAddressChange}
            onClearInput={handleAddressClear}
            renderSuggestion={LocationSuggestion}
            onClickIcon={handleRequestCurrentLocation}
            initialFocusIndex={suggestions?.length ? 0 : -1}
            iconRight={geolocationEnabled ? <LocationIcon /> : undefined}
            label={formatMessage({ id: 'location_input_label' })}
            placeholder={formatMessage({ id: 'location_input_hint' })}
            onSelect={handleSelectSuggestion}
            errorMessage={
              error ? formatMessage({ id: 'location_input_error' }) : undefined
            }
            isValid={!error}
            renderFooter={() => (
              <img
                src={poweredByGoogle}
                alt="powered by google"
                className={styles.imgFooter}
              />
            )}
          />
        </div>

        <Dropdown
          id="rd"
          data-testid="LocationRadiusDropdown"
          className={styles.distanceDropdown}
          label={formatMessage({ id: 'filter_label_location_radius' })}
          name="rd"
          value={radius}
          onChange={handleRadiusChange}
          required
          options={options}
          disabled={
            !selectedSuggestion || selectedSuggestion.value !== addressText
          }
        />
        <Checkbox
          className={styles.provinceCheckbox}
          data-testid="LimitToProvinceCheckbox"
          name="limitedToProvince"
          label={formatMessage({ id: 'limit_to_province' })}
          checked={isLimitedToProvince}
          onChange={handleLimitedToProvinceChange}
          disabled={
            !selectedSuggestion || selectedSuggestion.value !== addressText
          }
        />
      </fieldset>
      <LocationModalFooter
        renderResultCounter={renderResultCounter}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </form>
  );
};
