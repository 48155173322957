import { createSelector } from 'reselect';

export {
  selectMakeModelTree,
  selectFilterConfiguration,
} from '../../stores/referenceData';
import { State } from './types/State';

const selectLatestSearchState = (state: {
  features: { latestSearch: State };
}) => state.features?.latestSearch || {};

export const selectIsLoading = createSelector(
  selectLatestSearchState,
  (state) => state.isLoading
);

export const selectHasError = createSelector(
  selectLatestSearchState,
  (state) => !!state.error
);

export const selectListings = createSelector(
  selectLatestSearchState,
  (state) => state.listings
);
