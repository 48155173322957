import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ExternalLinkIcon } from '@move-ui/icons-asset-catalog';
import Text, { TextSize } from '@move-ui/text';
import Clickable from '@move-ui/clickable';
import styles from './HomePage.css';
import cx from 'classnames';
import { THEME } from '../constants';

const HomeCampaignLink = ({ campaign, onClick }) => {
  const handleClick = useCallback(() => {
    onClick?.(campaign.link.url);
    window.open(campaign.link.url);
  }, [campaign, onClick]);

  return (
    <Clickable
      type="button"
      className={cx(styles.campaignCta, {
        [styles.themeBrand]: campaign.theme === THEME.BRAND,
      })}
      onClick={handleClick}
    >
      <ExternalLinkIcon className={styles.campaignCtaIcon} />
      <Text color="neutral-800" size={TextSize.XXS} bold>
        {campaign.link.label}
      </Text>
    </Clickable>
  );
};

HomeCampaignLink.propTypes = {
  campaign: PropTypes.shape({
    link: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

export default HomeCampaignLink;
