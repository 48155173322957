import { EVENT_KEYS } from '../../../../lib/optimizely/constants';

export const RECENT_SEARCHES_DISPLAY = {
  eventAction: 'RecentSearchDisplay',
  getEventData: ({ count }: { count: string }) => ({
    eventLabel: `recentSearches=${count}`,
    nonInteraction: true,
  }),
  optimizelyEvents: [EVENT_KEYS.RECENT_SEARCH_PERFORMED],
};

export const RECENT_SEARCHES_CLICK = {
  eventAction: 'RecentSearchClick',
  getEventData: ({ count, position }: { count: string; position: string }) => ({
    eventLabel: `slot=${position};recentSearches=${count}`,
  }),
};
