import { useMemo } from 'react';
import { getMakeModelHitCount } from '../api';
import { useHitCounts } from './useHitCounts';
import { Query } from '../../../../stores/searchQuery';

export const useModelHitCount = (query: Query, fetchEnabled: boolean) => {
  const queryMemo = useMemo(
    () => ({
      ...query,
      ms:
        query.ms
          ?.map((value: string) => value?.split(';')[0])
          .filter(Boolean) || [],
    }),
    [query]
  );

  return useHitCounts(getMakeModelHitCount, queryMemo, 'MODEL', fetchEnabled);
};
