export const LAYOUT = {
  XX_LARGE: {
    CONTENT_WIDTH: 1280,
    COLUMNS: 2,
  },
  X_LARGE: {
    PAGE_MARGIN: 160,
    CONTENT_PERCENTAGE: 0.8,
    COLUMNS: 2,
  },
  LARGE: {
    PAGE_MARGIN: 80,
    CONTENT_PERCENTAGE: 0.8,
    COLUMNS: 2,
  },
  MEDIUM: {
    PAGE_MARGIN: 80,
    CONTENT_PERCENTAGE: 1,
    COLUMNS: 2,
  },
  SMALL: {
    PAGE_MARGIN: 64,
    CONTENT_PERCENTAGE: 1,
    COLUMNS: 1,
  },
  X_SMALL: {
    PAGE_MARGIN: 48,
    CONTENT_PERCENTAGE: 1,
    COLUMNS: 1,
  },
};
