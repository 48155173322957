import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import { TertiaryButton } from '@move-ui/button';
import { ArrowLeftIcon, ArrowRightIcon } from '@move-ui/icons-asset-catalog';
import { getBrowserType } from '@move-webapp/device';

import debounce from 'lodash/debounce';
import styles from './ScrollBox.css';

class ScrollBox extends React.Component {
  static propTypes = {
    areScrollButtonsVisible: PropTypes.bool,
    scrollItemCount: PropTypes.number.isRequired,
    onScrollPrevious: PropTypes.func,
    onScrollNext: PropTypes.func,
  };

  state = {
    canNavigatePrevious: false,
    canNavigateNext: false,
  };

  scrollContainerRef = React.createRef();

  lastTileRef = React.createRef();

  lastWidth = null;

  calculateNavigationPossibilities = debounce(() => {
    const { scrollLeft, scrollContainerWidth, tileWidth } =
      this.getScrollMetrics();
    const { scrollItemCount } = this.props;

    this.setState({
      canNavigateNext:
        scrollLeft + scrollContainerWidth < tileWidth * scrollItemCount,
      canNavigatePrevious: scrollLeft > 0,
    });
  }, 100);

  getScrollMetrics = () => {
    const tileWidth = this.lastTileRef?.current?.clientWidth;
    const scrollContainerWidth = this.scrollContainerRef?.current?.clientWidth;
    const scrollLeft = this.scrollContainerRef?.current?.scrollLeft;

    return {
      scrollContainerWidth: scrollContainerWidth + 1, // Fix minimum browser calculating inaccuracies
      scrollLeft,
      tileWidth,
      previousAndCurrentVisibleTilesCount: parseInt(
        Math.round((scrollContainerWidth + scrollLeft) / tileWidth),
        10
      ),
      visibleTilesCount: parseInt(scrollContainerWidth / tileWidth, 10),
    };
  };

  handleScrollPrevious = () => {
    if (!this.scrollContainerRef.current.scrollBy) return;

    const { tileWidth, scrollLeft, visibleTilesCount } =
      this.getScrollMetrics();

    const { onScrollPrevious } = this.props;

    const offset = scrollLeft % tileWidth;

    const countItemsToScroll =
      offset !== 0 ? visibleTilesCount - 1 : visibleTilesCount;

    this.scrollContainerRef.current.scrollBy({
      // workaround for smooth scroll behaviour bug in Safari 15.4+, https://bugs.webkit.org/show_bug.cgi?id=238497
      behavior: getBrowserType() !== 'Safari' ? 'smooth' : 'auto',
      top: 0,
      left: (countItemsToScroll * tileWidth + offset) * -1,
    });

    if (onScrollPrevious) {
      onScrollPrevious();
    }
  };

  handleScrollNext = () => {
    if (!this.scrollContainerRef.current.scroll) return;

    const { tileWidth, previousAndCurrentVisibleTilesCount } =
      this.getScrollMetrics();

    const { onScrollNext } = this.props;

    this.scrollContainerRef.current.scroll({
      // workaround for smooth scroll behaviour bug in Safari 15.4+, https://bugs.webkit.org/show_bug.cgi?id=238497
      behavior: getBrowserType() !== 'Safari' ? 'smooth' : 'auto',
      top: 0,
      left: previousAndCurrentVisibleTilesCount * tileWidth,
    });

    if (onScrollNext) {
      onScrollNext();
    }
  };

  handleWindowResize = () => {
    const currentWidth = window.innerWidth;

    // There are random resize events on iOS and Android. This prevents that they
    // get propagated.
    if (currentWidth === this.lastWidth) {
      return;
    }

    this.lastWidth = currentWidth;
    this.calculateNavigationPossibilities();
  };

  init = () => {
    const { areScrollButtonsVisible } = this.props;
    if (areScrollButtonsVisible) {
      this.calculateNavigationPossibilities();
      this.lastWidth = window.innerWidth;
      window.addEventListener('resize', this.handleWindowResize);
    }
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const {
      areScrollButtonsVisible: prevAreScrollButtonsVisible,
      scrollItemCount: prevScrollItemCount,
    } = prevProps;
    const { areScrollButtonsVisible, scrollItemCount } = this.props;

    if (
      (areScrollButtonsVisible && !prevAreScrollButtonsVisible) ||
      scrollItemCount !== prevScrollItemCount
    ) {
      this.init();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    this.calculateNavigationPossibilities.cancel();
  }

  render() {
    const { areScrollButtonsVisible, children, intl } = this.props;
    const { canNavigatePrevious, canNavigateNext } = this.state;

    return (
      <div
        className={styles.component}
        onScroll={
          areScrollButtonsVisible
            ? this.calculateNavigationPossibilities
            : undefined
        }
      >
        {areScrollButtonsVisible && canNavigatePrevious && (
          <div className={styles.scrollNavigationButtonContainer}>
            <TertiaryButton
              onClick={this.handleScrollPrevious}
              className={cx(styles.scrollButton, styles.scrollButtonPrevious)}
              aria-label={intl.formatMessage({ id: 'slider_previous' })}
            >
              <ArrowLeftIcon />
            </TertiaryButton>
          </div>
        )}

        {children({
          scrollContainerRef: this.scrollContainerRef,
          lastElementRef: this.lastTileRef,
        })}

        {areScrollButtonsVisible && canNavigateNext && (
          <div className={styles.scrollNavigationButtonContainer}>
            <TertiaryButton
              onClick={this.handleScrollNext}
              className={cx(styles.scrollButton, styles.scrollButtonNext)}
              aria-label={intl.formatMessage({ id: 'slider_next' })}
            >
              <ArrowRightIcon />
            </TertiaryButton>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(ScrollBox);
