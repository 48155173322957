export const trackClarivoy = (ids: (string | undefined)[] = []) => {
  const id = [...new Set(ids)].join(',');

  if (window.Clarivoy?.add3PARecord) {
    window.Clarivoy.add3PARecord(id, 'vdp');
  } else {
    window.addEventListener(
      'ClarivoyLoaded',
      () => window.Clarivoy?.add3PARecord?.(id, 'vdp'),
      false
    );
  }
};
