import { createSelector } from 'reselect';
import { selectQuery } from '../../stores/searchQuery';
import { selectFilterConfiguration } from '../../stores/referenceData';
import { countFilters } from '../../shared/domains/filters/services/query';
import { State as GlobalState } from '../../stores/types/State';

type State = NestedPick<GlobalState, 'features', 'filter'>;

const getFiltersState = (state: State) => state.features.filter || {};

export const getSorting = createSelector(
  getFiltersState,
  (filter) => filter.recentlyChangedFilters || []
);

export const selectFilterModal = createSelector(
  getFiltersState,
  (filter) => filter.filterModal
);

export const getIsDspOpen = createSelector(
  getFiltersState,
  (filter) => filter.dspIsOpen
);

export const filterChangesCount = createSelector(
  getFiltersState,
  (filter) => filter.changesCount
);

export const selectUsedFiltersCount = createSelector(
  selectQuery,
  selectFilterConfiguration,
  (query, filterConfiguration) => countFilters(query, filterConfiguration)
);
