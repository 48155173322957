import React, { FC, memo } from 'react';
import { getEnv } from '@move-web-essentials-utils/env';
import { getImageUrl } from '@move-web-essentials-utils/images';
import { InlineSchema } from '../../../../shared/components/InlineSchema/InlineSchema';
import { SliderData } from '../types/SliderData';
import { Slide } from '../types/Slide';

const publicDomain = getEnv('move_webapp_public_domain');

const createImage = (media: Slide['media']) => {
  const image = media?.[0]?.image;
  const imageUrl = image?.urls?.r1_0_47;
  if (!imageUrl) {
    return;
  }
  return {
    '@type': 'ImageObject',
    url: getImageUrl(imageUrl, { size: 640, protocol: 'https' }),
    description: image?.altText,
  };
};

type Props = {
  slider: SliderData;
};

export const ArticleSliderSchema: FC<Props> = memo(({ slider }) => (
  <InlineSchema
    data-testid="ArticleSliderSchema"
    data={{
      '@type': 'ItemList',
      url: `${publicDomain}/`,
      numberOfItems: slider.tiles.length,
      itemListOrder: 'https://schema.org/ItemListUnordered',
      itemListElement: slider.tiles.map(({ title, url, media }, i) => ({
        '@type': 'ListItem',
        position: i + 1,
        name: title,
        url: `${publicDomain}${url}`,
        image: createImage(media),
      })),
    }}
  />
));
