import { removeLocalePrefixFromUrl } from '../../../../lib/locale-utils';
import normalizeUrlSlash from '../../../../shared/utils/normalizeUrl';

type Make = { title: string; url: string };

// note that this is pretty similar to features/browseBy/utils/normalizeCategory.js
// if we need this logic even more often, we should consider creating an abstraction
// for now, keep the duplicate

// remove language prefix (e.g. /fr) from urls
// the prefix will be added by redux-first-router-link
const normalizeUrl = (locale: string) => (make: Make) => ({
  ...make,
  url: normalizeUrlSlash(removeLocalePrefixFromUrl(locale, make.url)),
});

const isValidMake = (make?: Partial<Make>) => make && make.title && make.url;

const normalizeMakes = (
  allMakes: (Partial<Make> | undefined)[],
  locale: string
) => (allMakes.filter(isValidMake) as Make[]).map(normalizeUrl(locale));

export default normalizeMakes;
