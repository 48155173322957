import getCustomPriceBucket from './getCustomPriceBucket';

export default [
  {
    setupPrebid() {
      window.pbjs.bidderSettings = {
        standard: {
          adserverTargeting: [
            {
              key: 'hb_pb',
              val: (bid) => getCustomPriceBucket(bid.cpm),
            },
            {
              key: 'hb_bidder',
              val: (bid) => bid.bidderCode,
            },
            {
              key: 'hb_size',
              val: (bid) => bid.size,
            },
            {
              key: 'hb_adid',
              val: (bid) => bid.adId,
            },
            {
              key: 'hb_deal',
              val: (bid) => bid.dealId,
            },
            {
              key: 'hb_bid_time',
              val: (bid) => bid.timeToRespond,
            },
            {
              key: 'hb_source',
              val: (bid) => bid.source,
            },
            {
              key: 'hb_format',
              val: (bid) => bid.mediaType,
            },
          ],
        },
        districtm: {
          bidCpmAdjustment: (bidCpm) => bidCpm * 0.9,
        },
        openx: {
          bidCpmAdjustment: (bidCpm) => bidCpm * 0.85,
        },
        aol: {
          bidCpmAdjustment: (bidCpm) => bidCpm * 0.88,
        },
        pubmatic: {
          bidCpmAdjustment: (bidCpm) => bidCpm * 0.92,
        },
      };
    },
  },
];
