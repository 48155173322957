import React, { FC, Fragment } from 'react';
import Text, { TextSize } from '@move-ui/text';
import { IntlFormatters, useIntl } from 'react-intl';
import { HistoryIcon } from '@move-ui/icons-asset-catalog';

import Card from '@move-ui/card';

import styles from './RecentSearch.css';
import { getMakeModelLabel } from '../../../../shared/utils/getMakeModelLabel';
import { RecentSearch as RecentSearchType } from '../types/RecentSearch';

type Props = {
  onItemClick: () => void;
  additionalFiltersCount?: number;
  query?: RecentSearchType['parsedQuery'];
};

const createPriceLabel = (
  { from, to }: { from?: string; to?: string },
  formatMessage: IntlFormatters['formatMessage']
) => {
  const price = {
    from: from && from !== '0' ? from : null,
    to: to && to !== '0' ? to : null,
  };

  if (price.from && price.to) {
    return formatMessage({ id: 'recentSearches.priceFromTo' }, price);
  }

  if (price.from) {
    return formatMessage({ id: 'recentSearches.priceFrom' }, price);
  }

  if (price.to) {
    return formatMessage({ id: 'recentSearches.priceTo' }, price);
  }

  return '';
};

const createMileageLabel = (
  { from, to }: { from?: string; to?: string },
  formatMessage: IntlFormatters['formatMessage']
) => {
  const milage = {
    from: from && from !== '0' ? from : null,
    to: to && to !== '0' ? to : null,
  };

  if (milage.from && milage.to) {
    return formatMessage({ id: 'recentSearches.mileageFromTo' }, milage);
  }

  if (milage.from) {
    return formatMessage({ id: 'recentSearches.mileageFrom' }, milage);
  }

  if (milage.to) {
    return formatMessage({ id: 'recentSearches.mileageTo' }, milage);
  }

  return '';
};

const createFilterLabel = ({ additionalFiltersCount }, formatMessage) => {
  if (additionalFiltersCount === 0) {
    return '';
  }

  return formatMessage(
    { id: 'recent_searches_filter_web' },
    {
      count: additionalFiltersCount,
    }
  );
};

export const RecentSearch: FC<Props> = ({
  additionalFiltersCount = 0,
  query = {
    makeModel: [],
    price: {},
    payment: {},
    mileage: {},
  },
  onItemClick,
}) => {
  const { formatMessage } = useIntl();
  const { makeModel, price, mileage, payment, frequency, term } = query;

  const makeModelLabel = getMakeModelLabel(makeModel, formatMessage);

  const priceLabel = createPriceLabel(price, formatMessage);
  const paymentLabel = createPriceLabel(payment, formatMessage);
  const fullPaymentsLabel = [paymentLabel, frequency, term]
    .filter(Boolean)
    .join(', ');
  const mileageLabel = createMileageLabel(mileage, formatMessage);
  const filterLabel = createFilterLabel(
    { additionalFiltersCount },
    formatMessage
  );

  const filtersLabel = [
    priceLabel || fullPaymentsLabel,
    mileageLabel,
    filterLabel,
  ]
    .filter((x) => x)
    .map((el, index, arr) => {
      if (index < arr.length - 1) {
        return (
          <Fragment key={el}>
            {el}
            &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
          </Fragment>
        );
      }
      return el;
    });

  return (
    <Card className={styles.component} role="link" onClick={onItemClick}>
      <span className={styles.headerContainer}>
        <HistoryIcon />
        <Text
          size={TextSize.XS}
          className={styles.label}
          color="neutral-100"
          bold
        >
          {makeModelLabel}
        </Text>
      </span>

      {filtersLabel && (
        <Text
          className={styles.filtersLabel}
          size={TextSize.XXXS}
          color="neutral-200"
        >
          {filtersLabel}
        </Text>
      )}
    </Card>
  );
};

export default RecentSearch;
