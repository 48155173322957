import uuid from 'uuid/v4';
import { parse as parseQueryString } from 'query-string';
import { isEmpty } from 'lodash';

import {
  REQUEST_RECENT_SEARCHES,
  RECEIVE_RECENT_SEARCHES,
  HANDLE_RECENT_SEARCHES_ERROR,
} from './actionTypes';
import fetchRecentSearches from './api';
import { getCurrentLocale } from '../../stores/i18n';

export const requestRecentSearchesAction = () => ({
  type: REQUEST_RECENT_SEARCHES,
});

export const receiveRecentSearchesAction = (payload) => ({
  type: RECEIVE_RECENT_SEARCHES,
  payload,
});

export const handleRecentSearchesErrorAction = (error) => ({
  type: HANDLE_RECENT_SEARCHES_ERROR,
  error,
});

export const convertApiSearchToClientSearch = ({ query, timestamp }) => {
  let parsedQuery = parseQueryString(query);
  const { ms } = parsedQuery;

  if (ms && !Array.isArray(ms)) {
    parsedQuery = {
      ...parsedQuery,
      ms: [parsedQuery.ms].filter(Boolean),
    };
  }

  return {
    id: uuid(),
    query: parsedQuery,
    timestamp,
  };
};

export const loadRecentSearches = async (dispatch, getState) => {
  const state = getState();
  const locale = getCurrentLocale(state);

  dispatch(requestRecentSearchesAction());

  // load recent searches via api
  try {
    const data = await fetchRecentSearches({ locale });
    const convertedSearches =
      !isEmpty(data.searches) &&
      data.searches.map(convertApiSearchToClientSearch);

    dispatch(receiveRecentSearchesAction(convertedSearches));
  } catch (err) {
    // fallback: locally stored recent searches
    dispatch(handleRecentSearchesErrorAction(err));
  }
};
