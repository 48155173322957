import { DIMENSION_SELECTED_CATEGORY_NAME } from '../../../utils/tracking/dimensions';

const getEventData = ({
  label,
  makeModelCategory,
}: {
  label: string;
  makeModelCategory?: string;
}) => ({
  eventLabel: label,
  [DIMENSION_SELECTED_CATEGORY_NAME]: makeModelCategory,
});

export const DROPDOWN_OPEN_ACTION = {
  eventAction: 'DropdownOpen',
  getEventData,
};

export const DROPDOWN_SUBMIT_ACTION = {
  eventAction: 'DropdownSubmit',
  getEventData,
};

export const DROPDOWN_CANCEL_ACTION = {
  eventAction: 'DropdownCancel',
  getEventData,
};

export const RESET_BUTTON_CLICK = {
  eventAction: 'DropdownReset',
  getEventData,
};

export const FREE_TEXT_SEARCH_BEGIN = {
  eventAction: 'FreeTextBegin',
  getEventData,
};

export const FREE_TEXT_SEARCH_CANCEL = {
  eventAction: 'FreeTextCancel',
  getEventData,
};
