import isEmpty from 'lodash/isEmpty';
import { FILTER_PARAMS } from '../config';
import { FilterParams } from '../types/FilterParams';

export const extractNonEmptyQueryParamsList = (
  filters: FilterParams
): ValueOf<typeof FILTER_PARAMS>[] =>
  Object.keys(filters)
    .filter((key) => !isEmpty(filters[key]))
    .map((key) => FILTER_PARAMS[key])
    .filter(Boolean)
    .sort();
