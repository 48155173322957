import React, { FC, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { PinIcon } from '@move-ui/icons-asset-catalog';
import { Link, LinkSize } from '@move-ui/link';
import Text, { TextSize } from '@move-ui/text';

import styles from './LocationLabel.css';
import useTracking from '../../../utils/tracking/useTracking';

type Props = {
  onClick: () => void;
  locationText?: string;
  radius?: string | number;
};

export const LocationLabel: FC<Props> = ({ onClick, locationText, radius }) => {
  const { formatMessage } = useIntl();
  const { trackEvent } = useTracking();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    trackEvent({
      eventAction: locationText ? 'LocationChange' : 'LocationSet',
    });
    onClick();
  };

  return (
    <div className={styles.component}>
      <div className={styles.label}>
        <PinIcon className={styles.pinIcon} data-testid="pinIcon" />
        <Text
          className={styles.text}
          color="neutral-200"
          size={TextSize.XS}
          data-testid="LocationLabelText"
        >
          {locationText
            ? formatMessage(
                { id: 'location_label' },
                { location: locationText, radius }
              )
            : formatMessage(
                { id: 'location_label_empty' },
                { location: locationText, radius }
              )}
        </Text>
      </div>

      <Link
        type="button"
        className={styles.link}
        onClick={handleClick}
        size={LinkSize.Size200}
        data-testid="LocationLabelLink"
      >
        {locationText
          ? formatMessage({ id: 'location_change' })
          : formatMessage({ id: 'location_set' })}
      </Link>
    </div>
  );
};
