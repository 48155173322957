import { useRef, useMemo, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import {
  resolveCurrentPosition,
  resolveLocationSuggestions,
} from '../../../../stores/geoLocation/api';
import { LocationArea } from '../../types/LocationArea';
import { LocationSuggestion } from '../../types/LocationSuggestion';

const DEBOUNCE_DELAY = 300;

export const useResolveAddress = ({
  initialLocation,
}: {
  initialLocation?: LocationArea;
}) => {
  const cleanupRef = useRef<() => void>();
  const isLocateMe = useRef(false);

  const [suggestions, setSuggestions] = useState<LocationSuggestion[]>([]);
  const [allowLocateMe, setAllowLocateMe] = useState<boolean>();
  const [isResolving, setIsResolving] = useState(false);
  const [resolvedLocation, setResolvedLocation] = useState(initialLocation);

  const fetchResults = async (textAddress?: string, sessionToken?: string) => {
    const controller = new AbortController();

    setIsResolving(true);
    setSuggestions([]);
    setResolvedLocation(undefined);

    cleanupRef.current = () => controller.abort();
    isLocateMe.current = textAddress === undefined;

    try {
      let data: (Pick<LocationSuggestion, 'placeId'> & {
        address: NonNullable<LocationSuggestion['address']>;
      })[] = [];

      if (isLocateMe.current) {
        const location = await resolveCurrentPosition({
          signal: controller.signal,
        });
        setResolvedLocation({ location });
        setAllowLocateMe(location !== undefined);
      } else {
        data = await resolveLocationSuggestions({
          sessionToken,
          address: textAddress,
          signal: controller.signal,
        });

        setSuggestions(
          data.map(({ address, ...rest }) => ({
            value: `${address.mainText}${
              address.secondaryText ? `, ${address.secondaryText}` : ''
            }`,
            address,
            ...rest,
          }))
        );
      }
      setIsResolving(false);
    } catch (error) {
      if ((error as Error).name !== 'AbortError') {
        setSuggestions([]);
        setIsResolving(false);
      }
    }
  };

  const debounceFetchResults = useMemo(
    () =>
      debounce((...args: Parameters<typeof fetchResults>) => {
        cleanupRef.current?.();
        fetchResults(...args);
      }, DEBOUNCE_DELAY),
    []
  );

  useEffect(
    () => () => {
      debounceFetchResults?.cancel();
      cleanupRef.current?.();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    isResolving,
    suggestions,
    allowLocateMe,
    setSuggestions,
    resolvedLocation,
    setResolvedLocation,
    isLocateMe: isLocateMe.current,
    fetchResults: debounceFetchResults,
  };
};
