import {
  DIMENSION_ONSITE_SEARCH_MAX_PRICE,
  DIMENSION_SELECTED_CATEGORY_NAME,
} from '../../../utils/tracking/dimensions';
import { EVENT_KEYS } from '../../../../lib/optimizely';

import { extractNonEmptyQueryParamsList } from '../utils/extractNonEmptyQueryParamsList';

export const HOME_SEARCH = {
  eventAction: 'HomeSearch',
  getEventData: ({
    filters,
    makeModelCategory,
  }: {
    filters: Parameters<typeof extractNonEmptyQueryParamsList>[0];
    makeModelCategory: string;
  }) => ({
    [DIMENSION_SELECTED_CATEGORY_NAME]: makeModelCategory,
    [DIMENSION_ONSITE_SEARCH_MAX_PRICE]: filters.price || undefined,
    eventLabel: extractNonEmptyQueryParamsList(filters).join(';') || 'none',
  }),
};

export const PRICE_CHANGE = {
  optimizelyEvents: [
    EVENT_KEYS.PRICE_QS_CHANGE,
    EVENT_KEYS.TOTAL_FILTER_CHANGE,
  ],
};

export const MILEAGE_CHANGE = {
  optimizelyEvents: [
    EVENT_KEYS.MILEAGE_QS_CHANGE,
    EVENT_KEYS.TOTAL_FILTER_CHANGE,
  ],
};

export const MAKE_MODEL_CHANGE = {
  optimizelyEvents: [
    EVENT_KEYS.MAKE_MODEL_QS_CHANGE,
    EVENT_KEYS.TOTAL_FILTER_CHANGE,
  ],
};

export const HOME_SEARCH_CLICK = {
  optimizelyEvents: [EVENT_KEYS.HOME_SEARCH, EVENT_KEYS.RESULTS_SEARCH],
};

export const FREQUENCY_OPEN = {
  eventAction: 'DropdownOpen',
  getEventData: () => ({ eventLabel: 'payfreq' }),
};

export const FREQUENCY_SUBMIT = {
  eventAction: 'DropdownSubmit',
  getEventData: () => ({ eventLabel: 'payfreq' }),
};

export const TAB_SWITCH = {
  eventAction: 'FilterTabSwitch',
  getEventData: (tab: string) => ({
    eventLabel: `location=quicksearch;tab=${tab}`,
  }),
};
