import React, { ComponentProps, FC, useState } from 'react';

import cx from 'classnames';

import { SelectedLocationLabel } from './SelectedLocationLabel';
import { LocationModal, SelectedLocationModal } from './LocationModal';
import styles from './LocationComponent.css';
import { getLocationText } from '../../../stores/geoLocation/utils/getLocationText';
import { LocationLabel } from './LocationLabel';

type Props = Pick<
  ComponentProps<typeof SelectedLocationModal>,
  'onApply' | 'onChange' | 'renderResultCounter'
> & {
  className?: string;
  dontSave?: boolean;
  currentArea?: ComponentProps<typeof LocationModal>['area'];
};

export const LocationComponent: FC<Props> = ({
  onApply,
  onChange,
  className,
  renderResultCounter,
  dontSave,
  currentArea,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const modalProps = {
    onApply,
    onChange,
    isOpen: isModalOpen,
    onClose: handleClose,
    onCancel: handleClose,
    renderResultCounter,
    dontSave,
  };

  return (
    <div className={cx(styles.container, className)}>
      {currentArea === undefined ? (
        <SelectedLocationLabel onClick={handleOpen} />
      ) : (
        <LocationLabel
          onClick={handleOpen}
          locationText={getLocationText(currentArea?.location)}
          radius={currentArea?.radius}
        />
      )}
      {currentArea === undefined ? (
        <SelectedLocationModal {...modalProps} />
      ) : (
        <LocationModal {...modalProps} area={currentArea} />
      )}
    </div>
  );
};
