export enum FilterName {
  Location = 'LOCATION',
  DigitalRetail = 'DIGITAL_RETAIL',
  MakeModelTrim = 'MAKE_MODEL',
  Year = 'CONSTRUCTION_YEAR',
  PricePayment = 'PRICE_PAYMENT',
  Price = 'PRICE',
  Payment = 'PAYMENT',
  Frequency = 'FREQUENCY',
  Term = 'TERM',
  Transmission = 'TRANSMISSION',
  Mileage = 'MILEAGE',
  BodyType = 'BODY_TYPE',
  Condition = 'CONDITION',
  FuelType = 'FUEL_TYPE',
  Drivetrain = 'DRIVETRAIN',
  SellerType = 'SELLER_TYPE',
  ExteriorColor = 'EXTERIOR_COLOR',
  Cylinders = 'CYLINDERS',
  EngineSize = 'ENGINE_SIZE',
  Power = 'POWER_IN_KW',
  Seats = 'SEATS',
  Doors = 'DOORS',
  Media = 'MEDIA',
  Keyword = 'KEYWORD',
  Extras = 'EXTRAS',
  DealerUpdates = 'COVID_RESPONSE',
  Carfax = 'VEHICLE_REPORT_ATTRIBUTES',
  VehicleReport = 'VEHICLE_REPORT',
}
