import React, { ComponentProps, FC } from 'react';
import { useSelector } from 'react-redux';
import {
  selectRadius,
  selectLocationText,
} from '../../../stores/geoLocation/selectors';
import { LocationLabel } from './LocationLabel';

type Props = Pick<ComponentProps<typeof LocationLabel>, 'onClick'>;

export const SelectedLocationLabel: FC<Props> = ({ onClick }) => {
  const locationText = useSelector(selectLocationText);
  const radius = useSelector(selectRadius);

  return (
    <LocationLabel
      onClick={onClick}
      locationText={locationText}
      radius={radius}
    />
  );
};
