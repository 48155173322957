import React, { FC, Fragment, useMemo, useEffect, ComponentProps } from 'react';

import { useIntl } from 'react-intl';
import { Headline, TextSize } from '@move-ui/text';
import { useDispatch, useSelector } from 'react-redux';

import { selectParsedRecentSearches } from '../../features/recentSearches/selectors';

import { loadRecentSearches } from '../../features/recentSearches/actionCreators';

import { RecentSearchesCarousel } from '../../features/recentSearches';
import { LatestSearchCarousel } from '../../features/latestSearchCarousel';

import styles from './RecentSearches.css';

type Props = Pick<ComponentProps<typeof RecentSearchesCarousel>, 'onSelect'> &
  Pick<ComponentProps<typeof LatestSearchCarousel>, 'goToSRP' | 'goToVip'>;

export const RecentSearches: FC<Props> = ({ goToVip, goToSRP, onSelect }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const recentSearches = useSelector(selectParsedRecentSearches);

  const latestQuery = useMemo(
    () => recentSearches?.[0]?.query,
    [recentSearches]
  );

  useEffect(() => {
    dispatch(loadRecentSearches);
  }, [dispatch]);

  const shouldDisplayLatestSearches = recentSearches?.length > 0;

  if (!shouldDisplayLatestSearches) {
    return null;
  }

  return (
    <section className={styles.component}>
      {recentSearches?.length > 1 && (
        <Fragment>
          <Headline bold color="neutral-100" level={2} size={TextSize.L}>
            {formatMessage({ id: 'home_recent_searches_title' })}
          </Headline>

          <RecentSearchesCarousel
            onSelect={onSelect}
            recentSearches={recentSearches}
          />
        </Fragment>
      )}

      <LatestSearchCarousel
        goToVip={goToVip}
        goToSRP={goToSRP}
        query={latestQuery}
      />
    </section>
  );
};
