import { Filter } from './../../../../stores/referenceData/types/Filter';
import { Query } from './../../../../stores/searchQuery/types/Query';
import { selectMakeModelForQueryString } from '../../../../stores/referenceData';
import { createFiltersWithSettings } from '../../../../shared/domains/filters/factories/filterSettingFactory';
import {
  isMakeModel,
  isExtraType,
  isBooleanType,
} from '../../../../shared/domains/filters/specifications/filters';

export const getMakeModels = ({
  query,
  makeModelTree,
}: {
  query?: Query;
  makeModelTree: Parameters<typeof selectMakeModelForQueryString>[0];
}) => {
  if (!query?.ms) {
    return [];
  }

  return selectMakeModelForQueryString(makeModelTree, query.ms);
};

const flat = <T>(values: T[]) =>
  values.reduce<T[]>((array, value) => array.concat(value), []);

const normalizeValues = (
  filters: string[],
  filterSetting: ReturnType<typeof createFiltersWithSettings>[0]
): string[] => {
  if (
    isExtraType(filterSetting.filter) ||
    isBooleanType(filterSetting.filter)
  ) {
    return flat([
      ...filters,
      ...(Object.values(filterSetting.value) as string[]),
    ]);
  }

  return [...filters, filterSetting.value as string];
};

export const countAppliedFilters = ({
  query,
  filterConfiguration,
}: {
  query?: Query;
  filterConfiguration: Filter[];
}) => {
  if (!query) {
    return 0;
  }

  const filters = createFiltersWithSettings(filterConfiguration, query)
    .filter(({ filter }) => !isMakeModel(filter))
    .reduce<string[]>(normalizeValues, []);

  return filters.length;
};

export const extractQueryToSearch = (query: Query): Query => ({
  ...query,
  sb: 'ct',
  od: 'down',
});
