import React, { FC, memo, useMemo } from 'react';
import { Headline, TextSize } from '@move-ui/text';
import { useIntl } from 'react-intl';

import {
  getMakeModels,
  countAppliedFilters,
} from '../domain/services/lastSearch';
import { getMakeModelLabel } from '../../../../shared/utils/getMakeModelLabel';

import styles from './Subtitle.css';
import { Query } from '../../../stores/searchQuery';
import { useSelector } from 'react-redux';
import { selectMakeModelTree, selectFilterConfiguration } from '../selectors';

type Props = {
  query: Query;
};

export const Subtitle: FC<Props> = memo(({ query }) => {
  const makeModelTree = useSelector(selectMakeModelTree);
  const filterConfiguration = useSelector(selectFilterConfiguration);

  const { formatMessage } = useIntl();
  const makeModels = useMemo(
    () =>
      getMakeModels({ query, makeModelTree }) as {
        makeName?: string;
        modelName?: string;
        trm?: string;
      }[],
    [query, makeModelTree]
  );
  const moreFiltersCounter = useMemo(
    () => countAppliedFilters({ query, filterConfiguration }),
    [query, filterConfiguration]
  );

  return (
    <Headline
      className={styles.component}
      color="neutral-200"
      level={3}
      size={TextSize.XS}
      data-testid="LatestSearchCarouselSubtitle"
    >
      {makeModels.length > 0
        ? getMakeModelLabel(makeModels, formatMessage)
        : formatMessage({ id: 'home_last_search_subtitle_make' })}
      {moreFiltersCounter ? (
        <span>
          ,{' '}
          {formatMessage(
            { id: 'home_last_search_subtitle_filter_web' },
            { count: moreFiltersCounter }
          )}
        </span>
      ) : (
        ''
      )}
    </Headline>
  );
});
