import { createSelector } from 'reselect';

import { countFilters } from '../../shared/domains/filters/services/query';
import {
  isFrequency,
  isTerm,
} from '../../shared/domains/filters/specifications/filters';
import {
  selectMakeModelForQueryString,
  selectMakeModelTree,
  selectFilterConfiguration,
} from '../../stores/referenceData';

import { transformRangeValueFromQueryString } from '../../utils/filters/range';

const selectRecentSearchesState = (state) => state.features.recentSearches;

export const selectRecentSearches = createSelector(
  selectRecentSearchesState,
  (recentSearches) => recentSearches.searches || []
);

export const selectRecentSearchesIsLoading = createSelector(
  selectRecentSearchesState,
  (recentSearches) => recentSearches.isLoading
);

export const selectRecentSearchesHasError = (state) =>
  Boolean(state.features.recentSearches.error);

export const selectParsedRecentSearches = createSelector(
  selectMakeModelTree,
  selectFilterConfiguration,
  selectRecentSearches,
  (makeModelTree, filterConfiguration, searches) =>
    searches?.map((search) => {
      const { query } = search;
      const { ms, p, ml } = query;
      const additionalFiltersCount = countFilters(query, filterConfiguration, [
        'p',
        'payment',
        'frequency',
        'term',
        'ml',
        'ms',
      ]);

      const makeModel = selectMakeModelForQueryString(makeModelTree, ms);
      const price = transformRangeValueFromQueryString({ value: p });
      const payment = transformRangeValueFromQueryString({
        value: query.payment,
      });
      const mileage = transformRangeValueFromQueryString({ value: ml });

      const allFilters = filterConfiguration.flatMap((filter) =>
        filter.tabs ? filter.tabs.flatMap((tab) => tab.filters) : filter
      );
      const termFilter = allFilters.find(isTerm);
      const term = termFilter?.values?.find(
        ({ value }) => value === query.term
      )?.title;
      const frequencyFilter = allFilters.find(isFrequency);
      const frequency = frequencyFilter?.values
        ?.find(({ value }) => value === query.frequency)
        ?.title?.toLocaleLowerCase();

      return {
        ...search,
        additionalFiltersCount,
        parsedQuery: {
          makeModel,
          price,
          payment,
          frequency,
          term,
          mileage,
        },
      };
    })
);
