import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '@move-ui/text';
import { THEME } from './constants';
import styles from './HeadlineBrand.css';

const HeadlineBrand = ({
  className,
  size = 'xl',
  title,
  tagName = 'div',
  theme = THEME.BRAND,
  ...rest
}) => {
  if (!title?.length) {
    return null;
  }

  const titleArray = typeof title === 'string' ? [title] : title;
  const ariaLabel = titleArray.join(' ');
  const Container = tagName;

  return (
    <Container
      className={cx(
        className,
        styles.component,
        styles[`size-${size}`],
        styles[`theme-${theme}`],
        styles[`headline-${theme}`]
      )}
      aria-label={ariaLabel}
      {...rest}
    >
      {titleArray.map((text, index) => (
        <Fragment key={text}>
          {Boolean(index) && <br />}
          <Text
            size={size}
            className={cx(styles.text, styles[`text-${theme}`])}
            key={text}
            aria-hidden
          >
            {text}
          </Text>
        </Fragment>
      ))}
    </Container>
  );
};

HeadlineBrand.propTypes = {
  className: PropTypes.string,
  tagName: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(THEME)),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  size: PropTypes.string,
};

export default HeadlineBrand;
