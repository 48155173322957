import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Headline, TextSize } from '@move-ui/text';
import { ImageLoader } from '@move-ui/image-loader';
import { getImageUrl } from '@move-web-essentials-utils/images';
import { MediaQueries } from '@move-ui/theme';

import RouterLink from '../../../shared/components/RouterLink';
import { LAYOUT } from '../config';
import styles from './Tile.css';

const textColorMap = {
  full: 'neutral-800',
  icon: 'neutral-100',
  'icon-small': 'neutral-100',
};

const shouldApplyMaskForCategory = (categoryId) => categoryId === 'make';
const calculateGridGaps = (gap, grid) => gap * (grid - 1);
const roundTwoDecimals = (value) => Math.round(value * 100) / 100;

const generateSizeResponsive = (mq, layout, grid) =>
  `${mq} calc((((100vw - ${layout.PAGE_MARGIN}px) * ${
    layout.CONTENT_PERCENTAGE
  }) - ${calculateGridGaps(layout.GRID_GAP, grid)}px) / ${grid})`;

const generateSizeFixed = (mq, layout, grid) =>
  `${mq} ${roundTwoDecimals(
    (layout.CONTENT_WIDTH - calculateGridGaps(layout.GRID_GAP, grid)) / grid
  )}px`;

const buildSrcSizes = (imageUrl, grid) => {
  let sizes;

  if (grid === 0) {
    sizes = `${LAYOUT.FIXED_GRID.IMAGE_SIZE}px`;
  } else {
    sizes = `
${generateSizeFixed(MediaQueries.XX_LARGE, LAYOUT.XX_LARGE, grid)},
${generateSizeResponsive(MediaQueries.X_LARGE, LAYOUT.X_LARGE, grid)},
${generateSizeResponsive(MediaQueries.LARGE, LAYOUT.LARGE, grid)},
${generateSizeResponsive(MediaQueries.MEDIUM, LAYOUT.MEDIUM, grid)},
${LAYOUT.FIXED_GRID.IMAGE_SIZE}px`;
  }

  const srcSetSizes = [100, 140, 200, 225, 275, 300, 400];

  const srcSet = srcSetSizes
    .map(
      (size) => `${getImageUrl(imageUrl, { size, format: 'webp' })} ${size}w`
    )
    .join(', ');

  return {
    src: getImageUrl(imageUrl, { size: 140, format: 'webp' }),
    srcSet,
    sizes,
  };
};

const Tile = ({
  id,
  categoryId,
  title,
  altText,
  url,
  imageUrl,
  type,
  grid,
  onClick,
  lazyLoad,
}) => (
  <RouterLink
    linkClassName={styles.component}
    to={url}
    onClick={onClick}
    rootTagName="div"
    masked={shouldApplyMaskForCategory(categoryId)}
    data-testid={`browseBy-${categoryId}-${id}`}
  >
    <ImageLoader
      {...buildSrcSizes(imageUrl, grid)}
      alt={altText}
      fit="cover"
      placeholderRatio={{ width: 1 }}
      reserveHeight
      lazyLoad={lazyLoad}
      role="presentation"
    />
    <Headline
      level={3}
      className={cx(styles.title, styles[`type-${type}`])}
      color={textColorMap[type]}
      size={TextSize.M}
      bold
    >
      {title}
    </Headline>
  </RouterLink>
);

Tile.propTypes = {
  id: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  grid: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  lazyLoad: PropTypes.bool,
};

export default Tile;
