import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, oneOf, shape, node, string } from 'prop-types';
import { AdvertisingProvider as OriginalAdvertisingProvider } from 'react-advertising';
import { plugins } from '../plugin';
import { PAGE_TYPES } from '../constants';
import {
  selectSRPValues,
  selectVIPValues,
  createSelectAdConfig,
} from '../selectors';
import { fetchAdConfig } from '../actionCreators';
import { PageType } from '../types/PageType';

const SELECTORS_MAPPING = {
  [PAGE_TYPES.HOME]: () => ({}),
  [PAGE_TYPES.SRP_WITH_SEARCH_RESULT]: selectSRPValues,
  [PAGE_TYPES.SRP_WITH_CATEGORY]: selectSRPValues,
  [PAGE_TYPES.VIP]: selectVIPValues,
  [PAGE_TYPES.VIP_AFTER_MESSAGE]: selectVIPValues,
};

export const AdvertisingProvider = ({
  selectorProps,
  groupName,
  isConfigNotReady,
  pageType,
  children,
}) => {
  const selectorsRef = useRef({});
  if (!selectorsRef.current[pageType]) {
    selectorsRef.current[pageType] = createSelectAdConfig(
      pageType,
      SELECTORS_MAPPING[pageType],
      groupName
    );
  }

  let config = useSelector((state) =>
    selectorsRef.current[pageType](state, selectorProps)
  );
  if (isConfigNotReady) {
    config = undefined;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdConfig(pageType));
  }, [pageType, dispatch]);

  return (
    <OriginalAdvertisingProvider config={config} plugins={plugins}>
      {children}
    </OriginalAdvertisingProvider>
  );
};

AdvertisingProvider.propTypes = {
  pageType: oneOf(Object.values(PageType)),
  selectorProps: shape({}),
  groupName: string,
  isConfigNotReady: bool,
  children: node,
};
