import {
  DIMENSION_L1_LOCATION_NAME,
  DIMENSION_L2_LOCATION_NAME,
  DIMENSION_ONSITE_SEARCH_DISTANCE,
} from '../../../../utils/tracking/dimensions';

export const LOCATION_RESULTS = {
  eventAction: 'LocationResults',
  getEventData: ({
    isChange,
    postalCode,
    city,
    radius,
  }: {
    isChange?: boolean;
    postalCode?: string;
    city?: string;
    radius?: string;
  }) => ({
    eventLabel: isChange ? 'change' : 'set',
    [DIMENSION_L1_LOCATION_NAME]: postalCode,
    [DIMENSION_L2_LOCATION_NAME]: city,
    [DIMENSION_ONSITE_SEARCH_DISTANCE]: radius
      ? parseInt(radius, 10)
      : undefined,
  }),
};
