import { defineMessages, useIntl } from 'react-intl';
import { Proposition, USP } from '../types';
import { FEATURE_DIGITAL_RETAIL } from '../../../../shared/featureFlags';

// Using placeholder in PhraseApp translations to indicate "url not set"
const EMPTY_URL = 'none';

const messages = defineMessages({
  tile1Image: {
    id: 'home_value_prop_tile1_image',
  },
  tile1Title: {
    id: 'home_value_prop_tile1_title',
  },
  tile1Description: {
    id: 'home_value_prop_tile1_description',
  },
  tile1Link: {
    id: 'home_value_prop_tile1_link',
  },
  tile2Image: {
    id: 'home_value_prop_tile2_image',
  },
  tile2Title: {
    id: 'home_value_prop_tile2_title',
  },
  tile2Description: {
    id: 'home_value_prop_tile2_description',
  },
  tile2Link: {
    id: 'home_value_prop_tile2_link',
  },
  tile3Image: {
    id: 'home_value_prop_tile3_image',
  },
  tile3Title: {
    id: 'home_value_prop_tile3_title',
  },
  tile3Description: {
    id: 'home_value_prop_tile3_description',
  },
  tile3Link: {
    id: 'home_value_prop_tile3_link',
  },
});

const useValuePropositions = (propositions: Proposition[] = []): USP[] => {
  const { formatMessage } = useIntl();

  return [
    ...(FEATURE_DIGITAL_RETAIL
      ? [
          {
            id: propositions[0],
            image: formatMessage(messages.tile1Image),
            title: formatMessage(messages.tile1Title),
            description: formatMessage(messages.tile1Description),
            url:
              formatMessage(messages.tile1Link) !== EMPTY_URL
                ? formatMessage(messages.tile1Link)
                : undefined,
          },
        ]
      : []),
    {
      id: propositions[1],
      image: formatMessage(messages.tile2Image),
      title: formatMessage(messages.tile2Title),
      description: formatMessage(messages.tile2Description),
      url:
        formatMessage(messages.tile2Link) !== EMPTY_URL
          ? formatMessage(messages.tile2Link)
          : undefined,
    },
    {
      id: propositions[2],
      image: formatMessage(messages.tile3Image),
      title: formatMessage(messages.tile3Title),
      description: formatMessage(messages.tile3Description),
      url:
        formatMessage(messages.tile3Link) !== EMPTY_URL
          ? formatMessage(messages.tile3Link)
          : undefined,
    },
  ];
};

export default useValuePropositions;
