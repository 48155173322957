import { removeLocalePrefixFromUrl } from '../../../../lib/locale-utils';
import normalizeUrl from '../../../../shared/utils/normalizeUrl';

export const isExternalUrl = (url) =>
  url.startsWith('http://') || url.startsWith('https://');

export default function normalizeTileUrls(tiles, locale) {
  return tiles.map((tile) => ({
    ...tile,
    url: isExternalUrl(tile.url)
      ? tile.url
      : normalizeUrl(removeLocalePrefixFromUrl(locale, tile.url)),
  }));
}
