import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectParsedRecentSearches } from '../../../features/recentSearches/selectors';
import { updateUserPositionFromQuery } from '../../../stores/geoLocation';
import { loadRecentSearches } from '../../../features/recentSearches/actionCreators';
import { requestLatestSearchResult } from '../../../features/latestSearchCarousel/actionCreators';
import { RecentSearches as BaseRecentSearches } from '../../../pageSegments/recentSearches';

const RecentSearches = ({ goToSrp, goToVip }) => {
  const recentSearches = useSelector(selectParsedRecentSearches);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadRecentSearches);
  }, [dispatch]);

  useEffect(() => {
    if (recentSearches?.length >= 1) {
      dispatch(requestLatestSearchResult(recentSearches?.[0]?.query));
    }
  }, [dispatch, recentSearches]);

  if (!(recentSearches?.length >= 1)) {
    return null;
  }

  const handleSelect = (query) => {
    dispatch(updateUserPositionFromQuery(query));
  };

  return (
    <BaseRecentSearches
      goToSRP={goToSrp}
      goToVip={goToVip}
      onSelect={handleSelect}
    />
  );
};

RecentSearches.propTypes = {
  goToSrp: PropTypes.func.isRequired,
  goToVip: PropTypes.func.isRequired,
};

export default RecentSearches;
