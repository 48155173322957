import React, { FC } from 'react';
import { sanitizeSchemaString } from '../../utils/sanitizeSchemaString';

type Props = {
  data: Record<string, unknown>;
};

export const InlineSchema: FC<Props> = ({ data, ...rest }) => (
  <script
    type="application/ld+json"
    {...rest}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: sanitizeSchemaString(
        JSON.stringify({
          '@context': 'https://schema.org',
          ...data,
        })
      ),
    }}
  />
);
