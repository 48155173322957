import React, { useMemo, useState, useEffect, useCallback, FC } from 'react';

import { useIntl } from 'react-intl';
import { Dropdown } from '@move-ui/dropdown';
import { useSelector } from 'react-redux';

import { useMakeHitCount } from '../../../shared/domains/filters/hooks/useMakeHitCount';
import {
  selectMakes,
  selectTopMakes,
  selectMakeTitle,
} from '../../../stores/referenceData';
import {
  selectMakeModelTrim,
  selectPreparatoryQuery,
  selectQuery,
} from '../../../stores/searchQuery';

import {
  createMakeOptions,
  createMakeModelLabel,
} from '../../../utils/filters/makeModel';
import { ApplyButton } from './ApplyButton';
import { ResetButton } from './ResetButton';
import { useDropdownSearch } from '../hooks/useDropdownSearch';
import { MakeModelTrimField } from '../types/MakeModelTrimField';

const MakeField: FC<MakeModelTrimField> = ({
  onOpen,
  onApply,
  minMenuWidth,
  onModalClose,
  useAppliedQuery,
}) => {
  const { formatNumber, formatMessage } = useIntl();
  const allMakesMessage = formatMessage({ id: 'filter_placeholder_make' });

  const allMakes = useSelector(selectMakes);
  const topMakes = useSelector(selectTopMakes);
  const labelTitle = useSelector(selectMakeTitle);
  const query = useSelector(
    useAppliedQuery ? selectQuery : selectPreparatoryQuery
  );
  const selectedMakeModels = useSelector(selectMakeModelTrim);

  const appliedValues = useMemo(
    () =>
      selectedMakeModels.map(({ parentId, i }) => (parentId ?? i).toString()),
    [selectedMakeModels]
  );
  const [makes, setMakes] = useState(appliedValues);
  const searchParams = useDropdownSearch(`make,${makes.length}`);

  const makeHitCount = useMakeHitCount(query);

  const makeOptions = useMemo(
    () => createMakeOptions(allMakes, makeHitCount, formatNumber),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [makeHitCount, formatNumber]
  );

  const topMakesOptions = useMemo(
    () => createMakeOptions(topMakes, makeHitCount, formatNumber),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [makeHitCount, formatNumber]
  );

  const sections = useMemo(
    () => [
      {
        label: formatMessage({ id: 'selector_section_popular_makes' }),
        values: topMakesOptions,
      },
      {
        label: formatMessage({ id: 'all_makes' }),
        values: makeOptions,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [makeOptions, topMakesOptions]
  );

  const handleApply = useCallback(
    (values: string[]) => {
      if (values.sort().join() === makes.sort().join()) {
        return;
      }
      setMakes(values);

      const selectedMakeIds = selectedMakeModels.map(
        (ms) => ms.parentId ?? ms.i
      );
      onApply(
        [
          // Remove all the makes that were unselected
          ...selectedMakeModels.filter((ms) =>
            values.includes((ms.parentId ?? ms.i).toString())
          ),
          // Format all the newly selected makes
          ...values
            .filter((ms) => !selectedMakeIds.includes(Number(ms)))
            .map((value) => {
              const make = allMakes.find(({ i }) => i === Number(value));
              const n = createMakeModelLabel({ make }, allMakesMessage);
              return { i: make?.i, n };
            }),
        ],
        'make'
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allMakes, onApply, selectedMakeModels]
  );

  const handleOpen = useCallback(() => onOpen('make'), [onOpen]);

  const handleModalClose = useCallback(
    () => onModalClose('make'),
    [onModalClose]
  );

  useEffect(() => {
    setMakes(appliedValues);
  }, [appliedValues]);

  return (
    <Dropdown
      id="make"
      name="make"
      onOpen={handleOpen}
      sections={sections}
      onChange={setMakes}
      onApply={handleApply}
      data-testid="MakeFilter"
      minMenuWidth={minMenuWidth}
      placeholder={allMakesMessage}
      value={makes}
      appliedValues={appliedValues}
      disabled={!makeOptions?.length}
      onModalClose={handleModalClose}
      label={labelTitle}
      multiple
      applyComponent={ApplyButton}
      resetComponent={(props) => <ResetButton {...props} label="make" />}
      modalLabel={formatMessage(
        { id: 'make_dropdown_title' },
        {
          count: makes.filter(
            (item, index, array) => array.indexOf(item) === index
          ).length,
        }
      )}
      displayLabel={appliedValues
        .map((make) => makeOptions.find(({ value }) => value === make)?.label)
        .filter((item, index, array) => array.indexOf(item) === index)
        .sort()
        .join(', ')}
      {...searchParams}
    />
  );
};

export default MakeField;
