import React, { ComponentProps, FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { PrimaryButton, ButtonSize, GhostButton } from '@move-ui/button';

import styles from './LocationModalFooter.css';

type Props = {
  renderResultCounter?: () => ReactNode;
  onCancel: ComponentProps<typeof GhostButton>['onClick'];
  onSubmit: ComponentProps<typeof PrimaryButton>['onClick'];
};

const LocationModalFooter: FC<Props> = ({
  renderResultCounter,
  onCancel,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.component}>
      <GhostButton
        className={styles.cancel}
        size={ButtonSize.Size200}
        onClick={onCancel}
        type="button"
        data-testid="LocationModalCancelButton"
      >
        {formatMessage({ id: 'cancel' })}
      </GhostButton>
      <PrimaryButton
        className={styles.results}
        size={ButtonSize.Size200}
        type="submit"
        data-testid="LocationModalSubmitButton"
        onClick={onSubmit}
        aria-label={formatMessage({ id: 'location_modal_submit_aria_label' })}
      >
        {renderResultCounter
          ? renderResultCounter()
          : formatMessage({ id: 'apply' })}
      </PrimaryButton>
    </div>
  );
};

export default LocationModalFooter;
