import { Price } from '../shared/types/Price';

export const NO_LUXURY_CHARGE_PROVINCES = new Set([
  'AB',
  'BC',
  'MB',
  'ON',
  'QC',
  'SK',
]);
export const LUXURY_THRESHOLD_PRICE = 100_000;

export const hasLuxurySurcharge = (
  isConditionNew?: boolean,
  price?: Price,
  isDealer?: boolean,
  province?: string
) =>
  !!(
    isConditionNew &&
    price?.amount &&
    price.amount >= LUXURY_THRESHOLD_PRICE &&
    isDealer &&
    province &&
    !NO_LUXURY_CHARGE_PROVINCES.has(province)
  );
