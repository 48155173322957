import React from 'react';
import PropTypes from 'prop-types';

import normalizeTileUrls from '../utils/normalizeTileUrls';
import { categoryShape } from '../propTypes';

import Category from './Category';

const BrowseBy = ({
  category,
  isAlwaysHorizontalScrollable,
  locale,
  onClick,
  lazyLoadFromIndex,
}) => (
  <Category
    id={category.id}
    isAlwaysHorizontalScrollable={isAlwaysHorizontalScrollable}
    title={category.title}
    tiles={normalizeTileUrls(category.tiles, locale)}
    type={category.type}
    onClickTile={({ tileId, tileIndex }) =>
      onClick?.({
        categoryId: category.id,
        tileId,
        tileIndex,
      })
    }
    lazyLoadFromIndex={lazyLoadFromIndex}
  />
);

BrowseBy.propTypes = {
  category: categoryShape.isRequired,
  isAlwaysHorizontalScrollable: PropTypes.bool,
  locale: PropTypes.string,
  onClick: PropTypes.func,
  lazyLoadFromIndex: PropTypes.number,
};

export default BrowseBy;
