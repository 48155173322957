import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link, LinkSize } from '@move-ui/link';
import { ChevronSmallIcon } from '@move-ui/icons-asset-catalog';
import { Headline, TextSize } from '@move-ui/text';
import { MediaQueries } from '@move-ui/theme';
import { useMediaQuery } from '@move-web-essentials-hooks/use-media-query';
import ScrollBox from '../../../shared/components/ScrollBox';
import { Tile } from './Tile';
import styles from './ArticleSlider.css';
import { ArticleSliderSchema } from './ArticleSliderSchema';
import { SliderData } from '../types/SliderData';

type Props = {
  slider: SliderData;
  onClick?: () => void;
  onSeeAllClick?: () => void;
};

export const ArticleSlider: FC<Props> = ({
  slider,
  onClick,
  onSeeAllClick,
}) => {
  const isMediumScreen = useMediaQuery(MediaQueries.MEDIUM);
  const { formatMessage } = useIntl();

  if (slider.tiles.length < 1) {
    return null;
  }

  return (
    <section className={styles.component}>
      <header className={styles.header}>
        <Headline
          level={2}
          className={styles.headline}
          size={TextSize.L}
          color="neutral-100"
        >
          {slider.title}
        </Headline>

        {slider.url && (
          <Link
            size={LinkSize.Size200}
            href={slider.url}
            onClick={onSeeAllClick}
          >
            {formatMessage({ id: 'slider_label_see_all' })}
            <ChevronSmallIcon className={styles.arrowIcon} />
          </Link>
        )}
      </header>

      <ScrollBox
        areScrollButtonsVisible={isMediumScreen}
        scrollItemCount={slider.tiles.length}
      >
        {({ scrollContainerRef, lastElementRef }) => (
          <ul ref={scrollContainerRef} className={styles.tiles}>
            {slider.tiles.map((tile, index) => (
              <Tile
                key={tile.id}
                tile={tile}
                onClick={onClick}
                index={index}
                ref={
                  index === slider.tiles.length - 1 ? lastElementRef : undefined
                }
              />
            ))}
          </ul>
        )}
      </ScrollBox>

      <ArticleSliderSchema slider={slider} />
    </section>
  );
};
