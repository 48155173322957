import React, { FC, MouseEvent } from 'react';
import { PrimaryButton, ButtonSize } from '@move-ui/button';
import { useIntl } from 'react-intl';
import styles from './ApplyButton.css';

type Props = {
  onClick: () => void;
};

export const ApplyButton: FC<Props> = ({ onClick, ...rest }) => {
  const { formatMessage } = useIntl();
  return (
    <PrimaryButton
      {...rest}
      size={ButtonSize.Size100}
      className={styles.applyButton}
      onClick={(event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onClick();
      }}
      data-testid="ApplyButton"
    >
      {formatMessage({ id: 'dropdown_apply' })}
    </PrimaryButton>
  );
};
