import useTracking from '../../../utils/tracking/useTracking';

const BROWSE_CATEGORY = 'usp';

const TILE_CLICK = {
  eventAction: 'browseClick',
  getEventData: ({
    category,
    tile,
    slot,
  }: {
    category: string;
    tile: string;
    slot: number;
  }) => ({
    eventLabel: `browseCategory=${category};tile=${tile};slot=${slot}`,
    nonInteraction: false,
  }),
};

const useTileTracking = (browseCategory = BROWSE_CATEGORY) => {
  const { trackEvent } = useTracking();

  return (id: string, position: number) => {
    trackEvent(TILE_CLICK, {
      category: browseCategory,
      tile: id,
      slot: position + 1,
    });
  };
};

export default useTileTracking;
