import { EVENT_KEYS as OPTIMIZELY_EVENTS } from '../../../lib/optimizely';
import { DIMENSION_SELECTED_CATEGORY_NAME } from '../../utils/tracking/dimensions';

export const MRP_CLICK = { eventAction: 'MRPClick' };
export const ARTICLE_SEE_ALL_CLICK = { eventAction: 'SeeAllAdvice' };

export const ADVANCED_SEARCH = {
  eventAction: 'AdvancedSearch',
  getEventData: ({ makeModelCategory }) => ({
    [DIMENSION_SELECTED_CATEGORY_NAME]: makeModelCategory,
  }),
};

export const ARTICLE_CLICK = {
  eventAction: 'articleClick',
  getEventData: ({ tileId, tileIndex }) => ({
    eventLabel: `position: ${tileIndex}; id: ${tileId}`,
  }),
};

export const BROWSE_CLICK = {
  eventAction: 'browseClick',
  getEventData: ({ categoryId, tileId, tileIndex }) => ({
    eventLabel: `browseCategory=${categoryId};tile=${tileId};slot=${tileIndex}`,
  }),
  optimizelyEvents: [OPTIMIZELY_EVENTS.RESULTS_SEARCH],
};

export const CAMPAIGN_CLICK = {
  eventAction: 'campaignClick',
  getEventData: ({ url }) => ({
    eventLabel: `url=${url}`,
  }),
};
