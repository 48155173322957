import React, { FC } from 'react';
import LoadingIndicatorIcon from '@move-ui/icons/progress/LoadingIndicator';

import styles from './Loading.css';

type LoadingProps = {
  'data-testid'?: string;
};

/**
 * TODO: implement UI representation of loading state
 * This would be the place where some kind of skeleton would be rendered.
 * For now we just show a simple spinner to at least give the use some kind of feedback.
 */
export const Loading: FC<LoadingProps> = (props) => (
  <div className={styles.component} {...props}>
    <LoadingIndicatorIcon className={styles.indicator} />
  </div>
);
