import React, {
  ChangeEvent,
  ComponentProps,
  FC,
  MouseEvent,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { CurrencyInput, NumberInput, Position } from '@move-ui/input';
import { ButtonSize, InlineButton, PrimaryButton } from '@move-ui/button';

import {
  HOME_SEARCH,
  HOME_SEARCH_CLICK,
  MAKE_MODEL_CHANGE,
  MILEAGE_CHANGE,
  PRICE_CHANGE,
} from './QuickSearch.tracking';

import styles from './QuickSearch.css';
import { SRP_BASE_PATHS } from '../../../../shared/config';
import { getCurrentLocale } from '../../../stores/i18n';
import { Query, selectCategoryName } from '../../../stores/searchQuery';
import useTracking from '../../../utils/tracking/useTracking';
import { transformMakeModelToQueryString } from '../../../utils/filters/makeModel';
import {
  transformRangeValueFromQueryString,
  transformRangeValueToQueryString,
} from '../../../utils/filters/range';
import { useResultsCount } from '../hooks/useResultsCount';
import { selectArea } from '../../../stores/geoLocation';
import { FILTER_PARAMS } from '../config';
import { relevancySortingQueryParams } from '../../../shared/domains/sorting/specifications/sorting';
import { QuickSearchHeader } from './QuickSearchHeader';
import { MakeModel } from '../../../shared/types/MakeModel';
import { selectFilterConfiguration } from '../../latestSearchCarousel/selectors';
import { Locale } from '../../../shared/types/Locale';
import {
  isMileage,
  isPrice,
} from '../../../shared/domains/filters/specifications/filters';

type Props = ComponentProps<typeof QuickSearchHeader> & {
  renderMakeModelField: (onChange: (values: MakeModel[]) => void) => ReactNode;
  onSubmit: (query: Query) => void;
  onChangeQuery: (query: Query) => void;
  className?: string;
};

export const QuickSearch: FC<Props> = ({
  className,
  renderMakeModelField,
  renderLocationFilter,
  onAdvancedSearchClick,
  onSubmit,
  onChangeQuery,
}) => {
  const [makeModel, setMakeModel] = useState<MakeModel[]>([]);
  const [query, setQuery] = useState<Query>({});

  const { formatMessage } = useIntl();
  const { trackEvent } = useTracking();

  const area = useSelector(selectArea);

  const locale = useSelector(getCurrentLocale);
  const makeModelCategory = useSelector(selectCategoryName);
  const filterConfiguration = useSelector(selectFilterConfiguration);

  const currencyPosition =
    locale === Locale.En ? Position.Left : Position.Right;

  const priceTitle = filterConfiguration.find(isPrice)?.title;
  const mileageTitle = filterConfiguration.find(isMileage)?.title;

  const handleQueryChange = (partialQuery: Query) => {
    const newQuery = { ...query, ...partialQuery };
    setQuery(newQuery);
    onChangeQuery(newQuery);
  };

  const handleToParamChange = (
    { target }: ChangeEvent<HTMLInputElement>,
    param: keyof Query
  ) =>
    handleQueryChange({
      [param]: transformRangeValueToQueryString({ to: target.value }),
    });

  const handleMakeModelChange = (value: MakeModel[]) => {
    setMakeModel(value);
    handleQueryChange({
      [FILTER_PARAMS.makeModel]: transformMakeModelToQueryString(value),
    });
    trackEvent(MAKE_MODEL_CHANGE);
  };

  const resultCountQuery = useMemo(() => {
    if (!area) {
      return { ...query };
    }
    const { location, isLimitedToProvince, radius } = area;
    const { position, province } = location || {};
    return {
      ...query,
      ll: position
        ? [position.latitude, position.longitude].join(',')
        : undefined,
      rd: radius?.toString() || undefined,
      aa: isLimitedToProvince ? province?.code : undefined,
    };
  }, [query, area]);

  const resultsCount = useResultsCount(resultCountQuery);

  const price = transformRangeValueFromQueryString({
    value: query[FILTER_PARAMS.price],
  });
  const mileage = transformRangeValueFromQueryString({
    value: query[FILTER_PARAMS.mileage],
  });

  const handleSubmit = (event: MouseEvent<Element>) => {
    event.preventDefault();
    trackEvent(HOME_SEARCH, {
      filters: {
        makeModel,
        price: price.to,
        mileage: mileage.to,
        dr: query.digital_retail_eligible,
      },
      makeModelCategory,
    });
    onSubmit({ ...query, ...relevancySortingQueryParams });
    trackEvent(HOME_SEARCH_CLICK);
  };

  return (
    <div className={cx(styles.component, className)}>
      <QuickSearchHeader
        renderLocationFilter={renderLocationFilter}
        onAdvancedSearchClick={onAdvancedSearchClick}
      />
      <form
        className={styles.form}
        autoComplete="off"
        data-testid="QuickSearch"
        action={SRP_BASE_PATHS[locale]}
      >
        <div className={styles.locationFilter}>{renderLocationFilter()}</div>
        <div className={styles.makeModel}>
          {renderMakeModelField(handleMakeModelChange)}
        </div>
        <div className={styles.priceAndMileageFilter}>
          <CurrencyInput
            aria-label={formatMessage({ id: 'filter_label_price_to' })}
            data-testid="quickSearchMaxPrice"
            id="quickSearchMaxPrice"
            min={0}
            max={9999999}
            onBlur={() => trackEvent(PRICE_CHANGE)}
            onlyInteger
            className={styles.price}
            label={priceTitle}
            placeholder={formatMessage({ id: 'QuickSearch.maxPrice' })}
            value={price.to}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleToParamChange(e, FILTER_PARAMS.price)
            }
            currency={formatMessage({ id: 'currency_sign' })}
            currencyPosition={currencyPosition}
          />
          <NumberInput
            aria-label={formatMessage({ id: 'filter_label_mileage_to' })}
            data-testid="quickSearchMaxMileage"
            id="quickSearchMaxMileage"
            min={0}
            max={9999999}
            onlyInteger
            className={styles.mileage}
            value={mileage.to}
            onBlur={() => trackEvent(MILEAGE_CHANGE)}
            label={mileageTitle}
            placeholder={formatMessage({ id: 'QuickSearch.maxMileage' })}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleToParamChange(e, FILTER_PARAMS.mileage)
            }
          />
        </div>
        <footer className={styles.footer}>
          <InlineButton
            className={styles.advancedSearchFooterButton}
            color="brand-300"
            data-testid="AdvancedSearchFooterButton"
            size={ButtonSize.Size200}
            type="button"
            onClick={onAdvancedSearchClick}
          >
            {formatMessage({ id: 'Home_advanced_search' })}
          </InlineButton>
          <PrimaryButton
            data-testid="quickSearchResultButton"
            type="submit"
            className={styles.submitButton}
            size={ButtonSize.Size200}
            onClick={handleSubmit}
          >
            {Number.isInteger(resultsCount)
              ? formatMessage(
                  { id: 'button_results' },
                  { numResultsTotal: resultsCount }
                )
              : formatMessage({ id: 'result_button_default' })}
          </PrimaryButton>
        </footer>
      </form>
    </div>
  );
};
