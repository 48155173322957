import React from 'react';
import PropTypes from 'prop-types';
import Text, { Headline, TextSize } from '@move-ui/text';
import { CheckmarkIcon } from '@move-ui/icons-asset-catalog';
import { ImageLoader } from '@move-ui/image-loader';
import { ButtonSize, PrimaryButton } from '@move-ui/button';
import { useIntl } from 'react-intl';
import Card from '@move-ui/card';
import { MediaQueries } from '@move-ui/theme';
import {
  getImageUrl,
  IMAGE_SIZES_AVAILABLE,
} from '@move-web-essentials-utils/images';

import styles from './MrpEntryPoint.css';
import RouterLink from '../../../shared/components/RouterLink';
import { goToMrp } from '../../../stores/page';
import { LAYOUT } from '../config';

const useBenefitLabels = () => {
  const { formatMessage } = useIntl();

  return [
    formatMessage({ id: 'mrp_teaser_benefit_1' }),
    formatMessage({ id: 'mrp_teaser_benefit_2' }),
    formatMessage({ id: 'mrp_teaser_benefit_3' }),
  ];
};

const generateSizeResponsive = (mq, layout) =>
  `${mq} calc(((100vw - ${layout.PAGE_MARGIN}px) * ${layout.CONTENT_PERCENTAGE}) / ${layout.COLUMNS})`;

const generateSizeFixed = (mq, layout) =>
  `${mq} calc(${layout.CONTENT_WIDTH}px / ${layout.COLUMNS})`;

const MrpEntryPoint = ({ locale, onClick = () => {} }) => {
  const { formatMessage } = useIntl();
  const benefitLabels = useBenefitLabels();

  const imageUrl = formatMessage({ id: 'mrp_teaser_image' });
  const imageSrc = getImageUrl(imageUrl, {
    size: 640,
    format: 'webp',
  });

  const srcSetSizes = [300, 400, 500, 640, 960, 1200];

  const imageSrcSet = IMAGE_SIZES_AVAILABLE.filter((x) =>
    srcSetSizes.includes(x)
  )
    .map(
      (size) => `${getImageUrl(imageUrl, { size, format: 'webp' })} ${size}w`
    )
    .join(', ');

  const imageSizes = `
      ${generateSizeFixed(MediaQueries.XX_LARGE, LAYOUT.XX_LARGE)},
      ${generateSizeResponsive(MediaQueries.X_LARGE, LAYOUT.X_LARGE)},
      ${generateSizeResponsive(MediaQueries.LARGE, LAYOUT.LARGE)},
      ${generateSizeResponsive(MediaQueries.MEDIUM, LAYOUT.MEDIUM)},
      ${generateSizeResponsive(MediaQueries.SMALL, LAYOUT.SMALL)},
      ${generateSizeResponsive('', LAYOUT.X_SMALL)}
    `;

  return (
    <div className={styles.component} data-testid="MrpEntryPoint">
      <Headline
        level={2}
        size={TextSize.L}
        color="neutral-100"
        className={styles.headline}
      >
        {formatMessage({ id: 'mrp_teaser_title' })}
      </Headline>
      <Card className={styles.card}>
        <RouterLink
          to={goToMrp(locale)}
          rootTagName="div"
          linkClassName={styles.link}
          onClick={onClick}
        >
          <div className={styles.benefitsAndCta}>
            <ul className={styles.benefits}>
              {benefitLabels.map((label) => (
                <li key={label}>
                  <CheckmarkIcon
                    className={styles.checkmarkIcon}
                    width="22"
                    height="22"
                  />
                  <Text size={TextSize.S} color="neutral-100">
                    {label}
                  </Text>
                </li>
              ))}
            </ul>
            <PrimaryButton
              size={ButtonSize.Size200}
              label={formatMessage({ id: 'mrp_teaser_cta' })}
              className={styles.button}
            />
          </div>
          <div className={styles.imageContainer}>
            <div className={styles.image}>
              <ImageLoader
                alt={formatMessage({ id: 'mrp_teaser_alt_text' })}
                title={formatMessage({ id: 'mrp_teaser_alt_text' })}
                fit="cover"
                src={imageSrc}
                srcSet={imageSrcSet}
                sizes={imageSizes}
                reserveHeight
              />
            </div>
          </div>
        </RouterLink>
      </Card>
    </div>
  );
};

MrpEntryPoint.propTypes = {
  locale: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default MrpEntryPoint;
