import React, { useMemo, useState, useEffect, useCallback, FC } from 'react';

import { useIntl } from 'react-intl';
import { Dropdown } from '@move-ui/dropdown';
import { useSelector } from 'react-redux';

import { useModelHitCount } from '../../../shared/domains/filters/hooks/useModelHitCount';
import {
  selectMakes,
  selectModels,
  selectModelTitle,
} from '../../../stores/referenceData';
import {
  selectPreparatoryQuery,
  selectMakeModelTrim,
  selectQuery,
} from '../../../stores/searchQuery';
import { createModelOptions } from '../../../utils/filters/makeModel';
import { ApplyButton } from './ApplyButton';
import { ResetButton } from './ResetButton';
import { sortByLabel } from '../utils/sortByLabel';
import { mapModelValues } from '../utils/mapModelValues';
import styles from './ModelField.css';
import { useDropdownSearch } from '../hooks/useDropdownSearch';
import { MakeModelTrimField } from '../types/MakeModelTrimField';

const ModelField: FC<MakeModelTrimField> = ({
  onOpen,
  onApply,
  onModalClose,
  minMenuWidth,
  useAppliedQuery,
}) => {
  const { formatNumber, formatMessage } = useIntl();

  const allMakes = useSelector(selectMakes);
  const allModels = useSelector(selectModels);
  const labelTitle = useSelector(selectModelTitle);
  const query = useSelector(
    useAppliedQuery ? selectQuery : selectPreparatoryQuery
  );
  const selectedMakeModels = useSelector(selectMakeModelTrim);

  const appliedValues = useMemo(
    () =>
      selectedMakeModels
        .filter(({ parentId }) => parentId)
        .map(({ parentId, i }) => `${parentId};${i}`),
    [selectedMakeModels]
  );
  const [models, setModels] = useState(appliedValues);
  const searchParams = useDropdownSearch(`model,${models.length}`);

  const modelHitCount = useModelHitCount(query, selectedMakeModels.length > 0);

  const modelOptions = useMemo(
    () =>
      selectedMakeModels
        .filter(
          (item, index, array) =>
            array.findIndex(({ makeName }) => item.makeName === makeName) ===
            index
        )
        .map((selectedMakeModel) => ({
          label: selectedMakeModel.n,
          values: createModelOptions(
            selectedMakeModel,
            allModels,
            modelHitCount,
            formatNumber
          ).sort(sortByLabel),
        }))
        .sort(sortByLabel),
    [allModels, modelHitCount, selectedMakeModels, formatNumber]
  );

  const handleApply = useCallback(
    (values: string[]) => {
      if (values.sort().join() === models.sort().join()) {
        return;
      }
      setModels(values);
      onApply(
        mapModelValues({
          values,
          selectedMakeModels,
          allMakes,
          allModels,
          formatMessage,
        }),
        'model'
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allModels, selectedMakeModels, formatMessage]
  );

  const handleOpen = useCallback(() => onOpen('model'), [onOpen]);

  const handleModalClose = useCallback(
    () => onModalClose('model'),
    [onModalClose]
  );

  useEffect(() => {
    setModels(appliedValues);
  }, [appliedValues]);

  return (
    <Dropdown
      id="model"
      name="model"
      selectClassName={styles.select}
      onOpen={handleOpen}
      {...(modelOptions.length > 1
        ? { sections: modelOptions }
        : { options: modelOptions[0]?.values || [] })}
      onChange={setModels}
      onApply={handleApply}
      data-testid="ModelFilter"
      minMenuWidth={minMenuWidth}
      placeholder={formatMessage({ id: 'filter_placeholder_model' })}
      value={models}
      appliedValues={appliedValues}
      onModalClose={handleModalClose}
      label={labelTitle}
      disabled={!allMakes?.length || !modelOptions?.length}
      multiple
      applyComponent={ApplyButton}
      resetComponent={(props) => <ResetButton {...props} label="model" />}
      modalLabel={formatMessage(
        { id: 'model_dropdown_title' },
        {
          count: models.filter(
            (item, index, array) => array.indexOf(item) === index
          ).length,
        }
      )}
      {...searchParams}
    />
  );
};

export default ModelField;
