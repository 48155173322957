import React, { FC } from 'react';
import {
  getImageUrl,
  IMAGE_SIZES_AVAILABLE,
} from '@move-web-essentials-utils/images';
import Card from '@move-ui/card';
import { ImageLoader } from '@move-ui/image-loader';
import Text, { Headline, TagName, TextSize } from '@move-ui/text';

import styles from './Box.css';

type Props = {
  image: string;
  title: string;
  description: string;
};

const Box: FC<Props> = ({ description, image, title }) => {
  const src = getImageUrl(image, {
    size: 300,
    format: 'webp',
  });

  const srcSet = IMAGE_SIZES_AVAILABLE.map(
    (size) =>
      `${getImageUrl(image, {
        size,
        format: 'webp',
      })} ${size}w`
  ).join(', ');

  return (
    <Card className={styles.card}>
      <div className={styles.image}>
        <ImageLoader
          srcSet={srcSet}
          src={src}
          title={title}
          className={styles.imageLoader}
          sizes="300px"
          lazyLoad
        />
      </div>
      <div className={styles.content}>
        <Headline
          level={3}
          size={TextSize.S}
          color="neutral-100"
          className={styles.header}
        >
          {title}
        </Headline>
        <Text
          tagName={TagName.P}
          size={TextSize.XS}
          color="neutral-200"
          className={styles.description}
        >
          {description}
        </Text>
      </div>
    </Card>
  );
};

export default Box;
