import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useDispatch, useSelector } from 'react-redux';

import { getSearchHit } from '../selectors';
import { requestSearchHits } from '../actionCreators';

import { selectArea } from '../../../stores/geoLocation';
import { selectPreparatoryQuery } from '../../../stores/searchQuery';

export const SearchHitCounter = () => {
  const { formatMessage } = useIntl();
  const area = useSelector(selectArea);
  const preparatoryQuery = useSelector(selectPreparatoryQuery);
  const counter = useSelector(getSearchHit);
  const dispatch = useDispatch();

  useEffect(() => {
    let locationQuery = {};
    if (area) {
      const { location, isLimitedToProvince, radius } = area;
      const { position } = location || {};
      locationQuery = {
        ll: position
          ? [position.latitude, position.longitude].join(',')
          : undefined,
        rd: radius ? radius.toString() : undefined,
        aa: isLimitedToProvince ? location?.province?.code : undefined,
      };
    }

    const newQuery = { ...locationQuery, ...preparatoryQuery };
    dispatch(requestSearchHits(newQuery));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preparatoryQuery, area]);

  return (
    <Fragment>
      {Number.isInteger(counter as number)
        ? formatMessage({ id: 'button_results' }, { numResultsTotal: counter })
        : formatMessage({ id: 'apply' })}
    </Fragment>
  );
};
