import React, { ComponentProps, FC } from 'react';
import { useSelector } from 'react-redux';
import { selectArea } from '../../../../stores/geoLocation';
import { LocationModal } from './LocationModal';

type Props = Omit<ComponentProps<typeof LocationModal>, 'area'>;

export const SelectedLocationModal: FC<Props> = (props) => {
  const area = useSelector(selectArea);
  return <LocationModal {...props} area={area} />;
};
