import { getEnv } from '@move-web-essentials-utils/env';
import apiClient from '../../../api/lib/apiClient';
import { MOVE_API_BASE_PATH } from '../../../shared/config';

export const METRICS_ID = 'svc_getRecentSearches';
const ENV_API = 'move_consumer_api_gateway_url';
const FETCH_URL = 'recent-searches';

const buildApiUrl = (url) =>
  IS_SERVER
    ? `${getEnv(ENV_API)}${MOVE_API_BASE_PATH}/${url}`
    : `${MOVE_API_BASE_PATH}/${url}`;

const fetchRecentSearches = (options = {}) => {
  const url = `${buildApiUrl(FETCH_URL)}`;
  const doRequest = apiClient({
    metricsId: METRICS_ID,
    ...options,
  });

  return doRequest({ url });
};

export default fetchRecentSearches;
