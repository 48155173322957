import { Prices } from '../../shared/types/Prices';
import { Price } from '../../shared/types/Price';

// amount that indicated a price on request
const REQUEST_PRICE_AMOUNT = 0;

/**
 * is it a price on request? ($0 for dealers is a "price on request")
 */
export const isPriceOnRequest = (isDealer?: boolean, price?: Price) =>
  !!isDealer &&
  (typeof price === 'undefined' ||
    (typeof price.amount !== 'undefined' &&
      price.amount <= REQUEST_PRICE_AMOUNT));

export const hasDriveAwayPrice = (isDealer?: boolean, prices?: Prices) =>
  isDealer && prices?.driveAwayPrice;

export const shouldShowPriceOnRequest = (prices: Prices, isDealer?: boolean) =>
  !(prices.consumerPrice || prices.driveAwayPrice) ||
  isPriceOnRequest(isDealer, prices.consumerPrice || prices.driveAwayPrice);

export const getRelevantPrice = (prices: Prices, isDealer?: boolean) =>
  hasDriveAwayPrice(isDealer, prices)
    ? prices.driveAwayPrice
    : prices.consumerPrice;

export const getPriceToDisplay = (prices: Prices, isDealer?: boolean) =>
  getRelevantPrice(prices, isDealer)?.localized;
