import React, { Fragment, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { PageSection } from '@move-ui/layout';
import { AdvertisingSlot } from 'react-advertising';
import { TextSize } from '@move-ui/text';

import { selectParsedRecentSearches } from '../../../features/recentSearches/selectors';
import { getCurrentLocale } from '../../../stores/i18n';
import { goToSrp, goToVip } from '../../../stores/page';
import { AllMakes } from '../../../features/allMakes';
import { QuickSearch } from '../../../features/quickSearch';
import PageTracking from '../../../utils/tracking/googleTagManager';
import {
  selectCategoryName,
  updatePreparatoryQuery,
} from '../../../stores/searchQuery';
import { SearchHitCounter } from '../../../features/searchHitCounter';
import { MakeModelTrimField } from '../../../features/makeModelTrimSearch';
import { DIMENSION_EXPERIMENT_VARIATION } from '../../../utils/tracking/dimensions';
import { LocationComponent } from '../../../features/location';
import { convertRadiusToString } from '../../../shared/domains/geoLocation/services';
import {
  AdvertisingProvider,
  selectGroupName,
  PAGE,
  getPageType,
} from '../../../features/advertising';
import Head from '../../../../shared/components/Head';
import Loading from '../../../shared/components/Loading';
import MrpEntryPoint from '../../../features/mrpEntryPoint';
import isFeatureEnabled from '../../../../lib/env/isFeatureEnabled';
import HeadlineBrand, {
  THEME as HEADLINE_THEME,
} from '../../../shared/components/HeadlineBrand';
import { AdvancedSearchModal } from './AdvancedSearchModal';
import { THEME } from '../constants';
import { DEFAULT_CAMPAIGN, CROSS_PROMOTIONS } from '../config';
import { ADVANCED_SEARCH, MRP_CLICK, CAMPAIGN_CLICK } from '../tracking';
import Category from './Category';
import { ValueProposition } from '../../../features/usp';
import styles from './HomePage.css';
import useTracking from '../../../utils/tracking/useTracking';
import { trackClarivoy } from '../../../shared/utils/trackClarivoy';
import {
  selectCategories,
  selectCategoryLoadingState,
  selectCampaign,
} from '../selectors';
import { HomeCampaignBackground } from './HomeCampaignBackground';
import HomeCampaignLink from './HomeCampaignLink';
import RecentSearches from './RecentSearches';
import { DEFAULT_LOCATION_RADIUS } from '../../../../shared/config';
import { openDSP } from '../../../features/filter/actionCreators';

const homepageThemeEnabled = isFeatureEnabled('homepage_theme');

const hasCampaignLink = (campaign) =>
  !!(campaign?.link?.url && campaign?.link?.label);

const isValidCampaign = ({ image, theme } = {}) =>
  typeof image === 'string' && Object.values(THEME).includes(theme);

const HomePage = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { trackEvent } = useTracking();

  const locale = useSelector(getCurrentLocale);
  const advertisingGroupName = useSelector(selectGroupName);
  const campaignData = useSelector(selectCampaign);
  const categories = useSelector(selectCategories);
  const isLoadingCategories = useSelector(selectCategoryLoadingState);
  const makeModelCategory = useSelector(selectCategoryName);
  const recentSearches = useSelector(selectParsedRecentSearches);
  const userHasSearches = recentSearches?.length >= 1;

  const handleAdvancedSearchClick = useCallback(() => {
    dispatch(openDSP());
    trackEvent(ADVANCED_SEARCH, { makeModelCategory });
  }, [dispatch, trackEvent, makeModelCategory]);

  const handleCampaignClick = useCallback(
    (url) => {
      trackEvent(CAMPAIGN_CLICK, { url });
    },
    [trackEvent]
  );

  useEffect(() => {
    trackClarivoy();
  }, []);

  const handleMrpEntryPointClick = useCallback(
    () => trackEvent(MRP_CLICK),
    [trackEvent]
  );

  const campaign =
    homepageThemeEnabled && campaignData && isValidCampaign(campaignData)
      ? campaignData
      : { ...DEFAULT_CAMPAIGN };

  const handleQueryChange = useCallback(
    (query) => dispatch(updatePreparatoryQuery(query)),
    [dispatch]
  );

  const renderMakeModelField = useCallback(
    (onChange) => (
      <MakeModelTrimField minMenuWidth="260px" onChange={onChange} />
    ),
    []
  );

  const renderLocationFilter = useCallback(
    () => (
      <LocationComponent
        onApply={() => {}}
        onChange={({
          position: { longitude, latitude } = {},
          province,
          radius,
          isLimitedToProvince,
        }) =>
          handleQueryChange({
            rd: convertRadiusToString(radius || DEFAULT_LOCATION_RADIUS),
            aa: isLimitedToProvince && province ? province.code : undefined,
            ll:
              longitude && latitude
                ? [latitude, longitude].join(',')
                : undefined,
          })
        }
        renderResultCounter={SearchHitCounter}
      />
    ),
    [handleQueryChange]
  );

  const handleSearch = useCallback(
    (query) => dispatch(goToSrp(query)),
    [dispatch]
  );

  const [firstCategory, secondCategory, ...restCategories] = [
    ...categories,
    CROSS_PROMOTIONS[locale],
  ].filter(Boolean);

  return (
    <AdvertisingProvider pageType={getPageType(PAGE.HOME)}>
      {campaign.trackingEnabled && (
        <AdvertisingSlot
          id="ad-homepage-takeover"
          className={styles.trackingPixel}
        />
      )}
      <PageTracking
        extendsData={{
          [DIMENSION_EXPERIMENT_VARIATION]: advertisingGroupName,
        }}
      >
        <Head
          title={formatMessage({ id: 'home_title_tag_vertical' })}
          description={formatMessage({
            id: 'home_meta_description_vertical',
          })}
          robots="index,follow,noodp"
        />
        <div data-testid="home" className={styles.component}>
          <PageSection
            className={cx(styles.searchSection)}
            data-testid="HomeSearchSection"
          >
            <HomeCampaignBackground campaign={campaign} />

            <div className={styles.campaignGrid}>
              <HeadlineBrand
                title={campaign?.headline}
                size={TextSize.XXXL}
                className={styles.headline}
                theme={
                  campaign?.theme === HEADLINE_THEME.BRAND
                    ? HEADLINE_THEME.NEUTRAL
                    : campaign?.theme
                }
              />
              {hasCampaignLink(campaign) && (
                <HomeCampaignLink
                  campaign={campaign}
                  onClick={handleCampaignClick}
                />
              )}
            </div>

            <QuickSearch
              className={styles.quickSearch}
              onAdvancedSearchClick={handleAdvancedSearchClick}
              onSubmit={handleSearch}
              onChangeQuery={handleQueryChange}
              renderMakeModelField={renderMakeModelField}
              renderLocationFilter={renderLocationFilter}
            />
          </PageSection>

          <PageSection widthBehaviour="fullContent">
            <div className={styles.intro}>
              <RecentSearches goToSrp={handleSearch} goToVip={goToVip} />
              {!userHasSearches && firstCategory && (
                <Category category={firstCategory} lazyLoadFromIndex={3} />
              )}
              <AdvertisingSlot
                className={cx(styles.topAd, styles.adContainer)}
                id="ad-home-top"
              />
            </div>

            <div className={styles.searchEntries}>
              {isLoadingCategories && <Loading />}
              {firstCategory && userHasSearches && (
                <div>
                  <Category category={firstCategory} lazyLoadFromIndex={0} />
                </div>
              )}

              {secondCategory && (
                <div className={styles.searchEntry}>
                  <Category category={secondCategory} lazyLoadFromIndex={0} />
                </div>
              )}

              <div className={styles.searchAdvertising}>
                <AdvertisingSlot
                  className={cx(styles.bottomAd, styles.midLandscapeBanner)}
                  id="ad-home-bottom"
                />
              </div>

              <ValueProposition className={styles.searchEntry} />

              {!isLoadingCategories && (
                <div className={styles.searchEntry}>
                  <MrpEntryPoint
                    locale={locale}
                    onClick={handleMrpEntryPointClick}
                  />
                </div>
              )}

              {restCategories?.length ? (
                restCategories.map((category) => (
                  <Fragment key={category.id}>
                    {category.id === 'make' && (
                      <AdvertisingSlot
                        className={cx(styles.bottomAd, styles.adHomeBottom2)}
                        id="ad-home-bottom2"
                      />
                    )}

                    <div key={category.id} className={styles.searchEntry}>
                      <Category category={category} lazyLoadFromIndex={0} />
                    </div>
                  </Fragment>
                ))
              ) : (
                <AdvertisingSlot
                  className={cx(styles.bottomAd, styles.adHomeBottom2)}
                  id="ad-home-bottom2"
                />
              )}
            </div>

            <AdvertisingSlot
              className={cx(styles.bottomAd, styles.adHomeBottom3)}
              id="ad-home-bottom3"
            />

            <AllMakes />
          </PageSection>

          <AdvancedSearchModal onSubmit={handleSearch} />
        </div>
      </PageTracking>
    </AdvertisingProvider>
  );
};

export default HomePage;
