import React from 'react';
import { func, node, string } from 'prop-types';
import RouterLink from '../../../../shared/components/RouterLink';
import { isExternalUrl } from '../../utils/normalizeTileUrls';
import styles from './Link.css';

const Link = ({ children, testId, to, onClick }) =>
  isExternalUrl(to) ? (
    <a
      className={styles.component}
      data-testid={testId}
      href={to}
      rel="nofollow noopener noreferrer"
      target="_blank"
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <RouterLink
      data-testid={testId}
      linkClassName={styles.component}
      rootTagName="div"
      to={to}
      onClick={onClick}
    >
      {children}
    </RouterLink>
  );

Link.propTypes = {
  children: node.isRequired,
  testId: string,
  to: string.isRequired,
  onClick: func,
};

export default Link;
