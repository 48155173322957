import React, { FC, Fragment } from 'react';
import Text, { TextSize } from '@move-ui/text';
import styles from './LocationSuggestion.css';
import { LocationSuggestion as Suggestion } from '../../types/LocationSuggestion';

export const LocationSuggestion: FC<Suggestion> = ({ locateMe, address }) => {
  if (locateMe) {
    return (
      <Text size={TextSize.M} className={styles.text}>
        {locateMe}
      </Text>
    );
  }

  const { mainText, secondaryText } = address || {};

  return (
    <span>
      <Text size={TextSize.M} className={styles.text}>
        {mainText}
      </Text>
      {secondaryText && (
        <Fragment>
          {', '}
          <Text size={TextSize.M} color="neutral-200" className={styles.text}>
            {secondaryText}
          </Text>
        </Fragment>
      )}
    </span>
  );
};
