import { createSelector } from 'reselect';
import { State } from '../../stores/types/State';

const getSearchHitCounter = (state: State) => state.features.searchHitCounter;

export const getSearchHitQuery = createSelector(
  getSearchHitCounter,
  (counter) => counter?.query
);

export const getSearchHit = createSelector(
  getSearchHitCounter,
  (counter) => counter?.result
);

export const selectIsLoadingSearchHit = createSelector(
  getSearchHitCounter,
  (counter) => !!counter?.isLoading
);
