import { Dispatch } from 'redux';
import { getListingsHitCount } from '../../../api/getListingsHitCount';
import { REQUEST_HEADER } from '../../../api/lib/apiClient/requestHeaders';
import {
  ERROR_SEARCH_HIT,
  RECEIVE_SEARCH_HIT,
  REQUEST_SEARCH_HIT,
} from './actionTypes';

import RequestController from '../../utils/RequestController';
import { getForwardedUrlParams } from '../../shared/domains/filters/utils/getForwardedUrlParams';
import { getQueryString } from '../../stores/location';
import { getCurrentLocale } from '../../stores/i18n';
import { State } from './../../stores/types/State';
import { Query } from '../../stores/searchQuery';

const requestController = new RequestController();

export const requestSearchHits =
  (query: Query) => async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: REQUEST_SEARCH_HIT });
    const state = getState();
    const queryString = getQueryString(state);
    const locale = getCurrentLocale(state);

    try {
      const { numResultsTotal } = (await requestController.resolveWithLatest(
        getListingsHitCount(query, {
          locale,
          headers: {
            [REQUEST_HEADER.TRACK_RECENT_SEARCH]: false,
            [REQUEST_HEADER.SEARCH_SOURCE]: 'search-hit-counter',
          },
          additionalParam: getForwardedUrlParams(queryString),
        })
      )) as { numResultsTotal: number };

      dispatch({
        type: RECEIVE_SEARCH_HIT,
        payload: { query, result: numResultsTotal },
      });

      return numResultsTotal;
    } catch (err) {
      if (requestController.isCanceledError(err as Error)) {
        return;
      }

      dispatch({
        type: ERROR_SEARCH_HIT,
        payload: err,
      });
    }
  };
