import { Query } from '../../stores/searchQuery';
import { REQUEST_HEADER } from '../../../api/lib/apiClient/requestHeaders';
import { getListings } from '../../../api/getListings';

export const fetchLatestSearchResults = (query: Query) =>
  getListings(
    {
      ...query,
      promotionsDisabled: 'true',
      psz: '10',
      sb: 'ct',
      od: 'down',
    },
    {
      headers: {
        [REQUEST_HEADER.TRACK_RECENT_SEARCH]: false,
        [REQUEST_HEADER.SEARCH_SOURCE]: 'last-search-carousel',
      },
    }
  );
