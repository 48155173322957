import React, { useEffect, useCallback, FC } from 'react';
import { useSelector } from 'react-redux';

import { selectRecentSearchesIsLoading } from '../selectors';
import LoadingState from '../../../shared/components/LoadingState';

import {
  RecentSearchesSlider,
  MAX_RECENT_SEARCHES_DISPLAY,
} from './RecentSearchesSlider';
import {
  RECENT_SEARCHES_CLICK,
  RECENT_SEARCHES_DISPLAY,
} from './RecentSearchesCarousel.tracking';

import styles from './RecentSearchesCarousel.css';
import useTracking from '../../../utils/tracking/useTracking';
import { RecentSearch } from '../types/RecentSearch';

type Props = {
  onSelect: () => void;
  recentSearches: RecentSearch[];
};

const RecentSearchesCarousel: FC<Props> = ({ onSelect, recentSearches }) => {
  const isLoading = useSelector(selectRecentSearchesIsLoading);
  const { trackEvent } = useTracking();

  const count = Math.min(recentSearches.length, MAX_RECENT_SEARCHES_DISPLAY);

  useEffect(() => {
    trackEvent(RECENT_SEARCHES_DISPLAY, { count });
  }, [trackEvent, count]);

  const handleItemClick = useCallback(
    (position) => {
      trackEvent(RECENT_SEARCHES_CLICK, { position, count });
    },
    [trackEvent, count]
  );

  return (
    <div data-testid="RecentSearchesCarousel">
      {isLoading && <LoadingState />}
      {count > 0 && (
        <section className={styles.component}>
          <RecentSearchesSlider
            onSelect={onSelect}
            recentSearches={recentSearches}
            onItemClick={handleItemClick}
          />
        </section>
      )}
    </div>
  );
};

export default RecentSearchesCarousel;
