import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { oneOfType, number } from 'prop-types';
import { getCurrentLocale } from '../../../stores/i18n';
import useTracking from '../../../utils/tracking/useTracking';
import * as EVENTS from '../tracking';
import { ArticleSlider, sliderShape } from '../../../features/articleSlider';
import {
  categoryShape as browseByCategoryShape,
  BrowseBy,
  LifestyleSlider,
} from '../../../features/browseBy';
import { CATEGORY_TYPES } from '../../../features/browseBy/types';

const Category = ({ category, lazyLoadFromIndex }) => {
  const locale = useSelector(getCurrentLocale);
  const { trackEvent } = useTracking();

  const handleBrowseByClick = useCallback(
    (options) => trackEvent(EVENTS.BROWSE_CLICK, options),
    [trackEvent]
  );

  const handleArticleClick = useCallback(
    (options) => trackEvent(EVENTS.ARTICLE_CLICK, options),
    [trackEvent]
  );

  const handleSeeAllClick = useCallback(() => {
    trackEvent(EVENTS.ARTICLE_SEE_ALL_CLICK);
  }, [trackEvent]);

  if (category.type === CATEGORY_TYPES.ARTICLE) {
    return (
      <ArticleSlider
        slider={category}
        onClick={handleArticleClick}
        onSeeAllClick={handleSeeAllClick}
      />
    );
  }

  if (category.type === CATEGORY_TYPES.FULL) {
    return (
      <LifestyleSlider category={category} onClick={handleBrowseByClick} />
    );
  }

  return (
    <BrowseBy
      category={category}
      locale={locale}
      onClick={handleBrowseByClick}
      lazyLoadFromIndex={lazyLoadFromIndex}
      isAlwaysHorizontalScrollable
    />
  );
};

Category.propTypes = {
  category: oneOfType([sliderShape, browseByCategoryShape]),
  lazyLoadFromIndex: number,
};

export default Category;
