import { MakeModelTrim } from './../../../shared/types/MakeModelTrim';
import { IntlFormatters } from 'react-intl';
import { MakeModel } from '../../../shared/types/MakeModel';
import { createMakeModelLabel } from '../../../utils/filters/makeModel';

export const mapTrimValues = ({
  values,
  selectedMakeModels,
  allMakes,
  allModels,
  formatMessage,
}: Pick<IntlFormatters, 'formatMessage'> & {
  values: string[];
  selectedMakeModels: MakeModel[];
  allMakes: MakeModel[];
  allModels: Record<string, MakeModelTrim[]>;
}) => {
  const allModelsMessage = formatMessage({ id: 'filter_placeholder_model' });
  const allTrimsMessage = formatMessage({ id: 'filter_placeholder_trim' });

  const formattedMakeModels = selectedMakeModels
    .map((ms) => {
      const { parentId, i, trm } = ms;
      if (!parentId || values.includes(`${parentId};${i};${trm}`)) {
        return ms;
      }
      if (!trm && values.some((val) => val.startsWith(`${parentId};${i}`))) {
        return null;
      }
      const make = allMakes.find((m) => m.i === parentId);
      const model = allModels[parentId]?.find((m) => m.i === i);
      const n = createMakeModelLabel({ make, model }, allModelsMessage);

      return { n, parentId: make?.i, i: model?.i };
    })
    .filter(Boolean) as MakeModel[];

  return [
    ...formattedMakeModels,
    ...values
      .filter(
        (makeModelTrim) =>
          !formattedMakeModels.some(
            (ms) => makeModelTrim === `${ms.parentId};${ms.i};${ms.trm}`
          )
      )
      .filter(Boolean)
      .map((ms) => {
        const [makeId, modelId, trm] = ms.split(';');
        const make = allMakes.find(({ i }) => i === Number(makeId));
        const model = allModels[makeId]?.find((m) => m?.i === Number(modelId));
        const n = createMakeModelLabel({ make, model }, allTrimsMessage);

        return { n, parentId: make?.i, i: model?.i, trm };
      }),
  ].filter(
    // filter out make+model if there is already a make+model+trim in the array
    (item, index, array) =>
      item.trm ||
      !array.some(
        ({ i, parentId, trm }, arrayIndex) =>
          index !== arrayIndex &&
          trm &&
          item.i === i &&
          item.parentId === parentId
      )
  );
};
