import { getCurrentLocale } from '../../stores/i18n';
import { fetchAdConfig as fetchAdConfigFromApi } from './api';
import {
  REQUEST_AD_CONFIG,
  HANDLE_RESPONSE_AD_CONFIG,
  HANDLE_RESPONSE_ERROR_AD_CONFIG,
  CONTACT,
} from './actionTypes';
import { isAdvertisingEnabled, selectConvertedAdConfig } from './selectors';
import { PAGE_TYPES } from './constants';
import {
  getExperimentVariation,
  AD_OPTIMIZATION,
} from '../../../lib/optimizely';

const PAGE_TYPE_MAPPING = {
  [PAGE_TYPES.HOME]: 'MOVECA_HOME',
  [PAGE_TYPES.SRP_WITH_SEARCH_RESULT]: 'MOVECA_SRPS',
  [PAGE_TYPES.SRP_WITH_CATEGORY]: 'MOVECA_SRPB',
  [PAGE_TYPES.VIP]: 'MOVECA_VIP',
  [PAGE_TYPES.VIP_AFTER_MESSAGE]: 'MOVECA_VIP_SUCCESS',
};

const requestAdConfig = () => ({ type: REQUEST_AD_CONFIG });

const handleResponseAdConfig = (page, adConfig) => ({
  type: HANDLE_RESPONSE_AD_CONFIG,
  payload: {
    page,
    adConfig,
  },
});

const handleResponseErrorAdConfig = (error) => ({
  type: HANDLE_RESPONSE_ERROR_AD_CONFIG,
  payload: error,
});

export const fetchAdConfig = (pageType) => async (dispatch, getState) => {
  const state = getState();
  if (!isAdvertisingEnabled(state)) {
    return;
  }

  if (selectConvertedAdConfig(pageType)(state)) {
    return;
  }

  // We run into problems if we try including inactive experiments. For example,
  // see the "For future reference" comment on KCAN-14416.
  const experimentsToForward = [AD_OPTIMIZATION];
  const experimentGroups = experimentsToForward.reduce((acc, experiment) => {
    const variation = getExperimentVariation(experiment);
    return variation
      ? { ...acc, [experiment.key]: variation.toUpperCase() }
      : acc;
  }, {});

  dispatch(requestAdConfig());

  try {
    const adConfig = await fetchAdConfigFromApi(
      {
        pagetype: PAGE_TYPE_MAPPING[pageType],
        ...(Object.keys(experimentGroups).length > 0
          ? { experimentGroups }
          : {}),
      },
      { locale: getCurrentLocale(state) }
    );

    dispatch(handleResponseAdConfig(pageType, adConfig));
  } catch (error) {
    dispatch(handleResponseErrorAdConfig(error));
  }
};

export const contact = () => ({ type: CONTACT });
