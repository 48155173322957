import React, {
  AllHTMLAttributes,
  FC,
  forwardRef,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import ReduxRouterLink from 'redux-first-router-link';
import { actionToPath, ReceivedAction, RoutesMap } from 'redux-first-router';
import cx from 'classnames';

import logger from '@move-web-essentials-utils/logger';

import { ENTER_KEY_CODE } from '../../../constants/keyCodes';
import { getBasename } from '../../../../lib/locale-utils';
import { getCurrentLocale, getDefaultLocale } from '../../../stores/i18n';
import { getRoutesMap } from '../../../stores/location';
import styles from './RouterLink.css';

type Props = Pick<
  AllHTMLAttributes<HTMLAnchorElement>,
  'rel' | 'target' | 'type' | 'title'
> & {
  masked?: boolean;
  rootTagName?: string;
  to: string | ReceivedAction;
  wrapperClassName?: string;
  linkClassName?: string;
  onMouseEnter?: MouseEventHandler<HTMLAnchorElement>;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onKeyDown?: KeyboardEventHandler<HTMLAnchorElement>;
  'data-testid'?: string;
  'data-test-ad-type'?: string;
};

const getHref = (
  to: Props['to'],
  routesMap: RoutesMap<string, unknown> | undefined
) => {
  try {
    return typeof to !== 'string' && routesMap
      ? actionToPath(to, routesMap)
      : to;
  } catch (error) {
    logger.error(`Cannot create a href for action ${JSON.stringify(to)}`);
    throw error;
  }
};

const RouterLink: FC<Props> = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      masked,
      wrapperClassName,
      linkClassName,
      rootTagName = 'span',
      children,
      onClick,
      to,
      onMouseEnter,
      onKeyDown,
      ...rest
    },
    ref
  ) => {
    const defaultLocale = useSelector(getDefaultLocale);
    const locale = useSelector(getCurrentLocale);
    const routesMap = useSelector(getRoutesMap);

    // Used to know if it is an human user and not a robot
    // so we can support "Open in new tab" for desktop users
    const [isHumanInteraction, setHumanInteraction] = useState(false);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLAnchorElement>) => {
        onKeyDown?.(event);
        if (event.keyCode === ENTER_KEY_CODE) {
          (event.target as HTMLElement).click();
        }
      },
      [onKeyDown]
    );

    const handleMouseEnter = useCallback(
      (event: MouseEvent<HTMLAnchorElement>) => {
        // We don't want setState to be called on touch devices. If we would, iOS
        // treats this onMouseEnter as a "content change" and will prevent the
        // click event, that actually should be called.
        if (
          'ontouchstart' in window ||
          (window.DocumentTouch && document instanceof window.DocumentTouch)
        ) {
          return;
        }

        setHumanInteraction(true);

        if (onMouseEnter) {
          onMouseEnter(event);
        }
      },
      [onMouseEnter]
    );

    const { rel, target, type, title } = rest;

    const isMasked = masked && !isHumanInteraction;

    const href = getHref(to, routesMap);

    const basename =
      locale &&
      defaultLocale &&
      getBasename({ currentLocale: locale, defaultLocale });

    const localizedHref = `${basename ?? ''}${href}`;

    return (
      <ReduxRouterLink
        tagName={rootTagName}
        className={wrapperClassName}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        to={to}
        {...rest}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          ref={ref}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          className={cx({ [styles.maskedLink]: isMasked }, linkClassName)}
          href={(!isMasked && localizedHref) || undefined}
          rel={rel}
          target={target}
          type={type}
          title={title}
        >
          {children}
        </a>
      </ReduxRouterLink>
    );
  }
);

RouterLink.displayName = 'RouterLink';

export default RouterLink;
