import React, {
  useEffect,
  useCallback,
  useRef,
  FC,
  ComponentProps,
} from 'react';
import { Link, LinkSize } from '@move-ui/link';
import { ChevronSmallIcon } from '@move-ui/icons-asset-catalog';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ListingsSlider from '../../../shared/components/ListingsSlider';

import { extractQueryToSearch } from '../domain/services/lastSearch';

import { Subtitle } from './Subtitle';
import { requestLatestSearchResult } from '../actionCreators';

import styles from './LatestSearchCarousel.css';

import {
  LAST_SEARCH_DISPLAY,
  LAST_SEARCH_SHOW_ALL_CLICK,
  LAST_SEARCH_AD_CLICK,
} from '../tracking';
import { trackOptimizelyEvent, EVENT_KEYS } from '../../../../lib/optimizely';
import { selectHasError, selectListings } from '../selectors';
import useTracking from '../../../utils/tracking/useTracking';
import { Query } from '../../../stores/searchQuery';

type Props = Pick<ComponentProps<typeof ListingsSlider>, 'goToVip'> &
  Pick<ComponentProps<typeof Subtitle>, 'query'> & {
    goToSRP: (query: Query) => void;
    query: Query;
  };

export const LatestSearchCarousel: FC<Props> = ({
  goToVip,
  goToSRP,
  query,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const alreadyTracked = useRef(false);

  const hasError = useSelector(selectHasError);
  const listings = useSelector(selectListings);
  const { trackEvent } = useTracking();

  const loadLatestSearches = (queryParams) =>
    dispatch(requestLatestSearchResult(queryParams));

  useEffect(() => {
    if (query) {
      loadLatestSearches(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listings?.length && !alreadyTracked.current) {
      alreadyTracked.current = true;
      trackEvent(LAST_SEARCH_DISPLAY);
    }
  }, [listings, trackEvent]);

  const handleItemClick = useCallback(
    (index) => {
      trackOptimizelyEvent(EVENT_KEYS.LAST_SEARCH_AD_CLICK);
      trackEvent(LAST_SEARCH_AD_CLICK(index + 1));
    },
    [trackEvent]
  );

  const handleLinkToSRPClick = useCallback(() => {
    goToSRP(extractQueryToSearch(query));
    trackEvent(LAST_SEARCH_SHOW_ALL_CLICK);
  }, [query, trackEvent, goToSRP]);

  if (hasError || !listings?.length) {
    return null;
  }

  return (
    <section data-testid="LatestSearchCarousel">
      <div className={styles.subtitle}>
        <Subtitle query={query} />

        <Link
          size={LinkSize.Size200}
          onClick={handleLinkToSRPClick}
          data-testid="LastSearchCarouselLinkToSRP"
        >
          {formatMessage({ id: 'home_last_search_linktext' })}
          <ChevronSmallIcon className={styles.arrowIcon} />
        </Link>
      </div>

      <ListingsSlider
        isTitleHidden
        list={listings}
        goToVip={goToVip}
        onItemClick={handleItemClick}
        data-testid="LatestSearchCarouseListingsSlider"
      />
    </section>
  );
};
