export const LAST_SEARCH_DISPLAY = {
  eventAction: 'LastSearchAdDisplay',
  getEventData: () => ({ nonInteraction: true }),
};

export const LAST_SEARCH_AD_CLICK = (position: number) => ({
  eventAction: 'LastSearchAdClick',
  getEventData: () => ({ eventLabel: `position=${position}` }),
});

export const LAST_SEARCH_SHOW_ALL_CLICK = { eventAction: 'ShowAllCarousel' };
