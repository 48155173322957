import React, { useMemo } from 'react';
import { func } from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, LinkSize } from '@move-ui/link';
import { ChevronSmallIcon } from '@move-ui/icons-asset-catalog';
import { Headline, TextSize } from '@move-ui/text';
import { MediaQueries } from '@move-ui/theme';
import { useMediaQuery } from '@move-web-essentials-hooks/use-media-query';
import { getCurrentLocale } from '../../../../stores/i18n';
import ScrollBox from '../../../../shared/components/ScrollBox';
import { categoryShape } from '../../propTypes';
import Tile from './Tile';
import styles from './LifestyleSlider.css';
import normalizeTileUrls from '../../utils/normalizeTileUrls';

const LifestyleSlider = ({ category, onClick = () => {}, onSeeAllClick }) => {
  const { id, tiles, title, url } = category;

  const locale = useSelector(getCurrentLocale);
  const isMediumScreen = useMediaQuery(MediaQueries.MEDIUM, false);
  const { formatMessage } = useIntl();
  const normalizedTiles = useMemo(
    () => normalizeTileUrls(tiles || [], locale),
    [tiles, locale]
  );

  if (!category.tiles?.length) {
    return null;
  }

  return (
    <section
      className={styles.component}
      data-testid={`browseBy-${category.id}-slider`}
    >
      <header className={styles.header}>
        <Headline
          level={2}
          className={styles.headline}
          size={TextSize.L}
          color="neutral-100"
          data-testid={`browseBy-${id}-headline`}
        >
          {title}
        </Headline>

        {url && (
          <Link size={LinkSize.Size200} href={url} onClick={onSeeAllClick}>
            {formatMessage({ id: 'slider_label_see_all' })}
            <ChevronSmallIcon className={styles.arrowIcon} />
          </Link>
        )}
      </header>

      <ScrollBox
        areScrollButtonsVisible={isMediumScreen}
        scrollItemCount={tiles.length}
      >
        {({ scrollContainerRef, lastElementRef }) => (
          <ul ref={scrollContainerRef} className={styles.tiles}>
            {normalizedTiles.map((tile, index) => (
              <Tile
                key={tile.id}
                tile={tile}
                onClick={onClick}
                index={index}
                ref={index === tiles.length - 1 ? lastElementRef : null}
                parentId={id}
              />
            ))}
          </ul>
        )}
      </ScrollBox>
    </section>
  );
};

LifestyleSlider.propTypes = {
  onClick: func,
  onSeeAllClick: func,
  category: categoryShape.isRequired,
};

export default LifestyleSlider;
