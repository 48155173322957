import React, { FC } from 'react';
import { MediaQueries } from '@move-ui/theme';
import { useDispatch } from 'react-redux';

import { useMediaQuery } from '@move-web-essentials-hooks/use-media-query';
import styles from './RecentSearchesSlider.css';
import { RecentSearch } from './RecentSearch';

import { goToSrp } from '../../../stores/page';
import { Query, setPreparatoryQuery } from '../../../stores/searchQuery';

import ScrollBox from '../../../shared/components/ScrollBox';
import { RecentSearch as RecentSearchType } from '../types/RecentSearch';

type Props = {
  onItemClick: (query: number) => void;
  onSelect: (query: Query) => void;
  recentSearches: RecentSearchType[];
};

export const MAX_RECENT_SEARCHES_DISPLAY = 5;

export const RecentSearchesSlider: FC<Props> = ({
  onSelect,
  onItemClick,
  recentSearches,
}) => {
  const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery(MediaQueries.LARGE);

  const handleSearchClick = (query: Query, index: number) => {
    onItemClick(index + 1);
    dispatch(setPreparatoryQuery(query));
    dispatch(goToSrp(query));
  };

  const list = recentSearches.slice(0, MAX_RECENT_SEARCHES_DISPLAY);

  return (
    <div data-testid="RecentSearchesSlider">
      <ScrollBox
        areScrollButtonsVisible={isLargeScreen}
        scrollItemCount={list.length}
      >
        {({ scrollContainerRef, lastElementRef }) => (
          <ul
            data-testid="RecentSearches"
            className={styles.list}
            role="presentation"
            ref={scrollContainerRef}
          >
            {list.map(
              ({ additionalFiltersCount, id, parsedQuery, query }, index) => (
                <li
                  key={id}
                  role="heading"
                  aria-level={3}
                  className={styles.item}
                  data-testid={`RecentSearchesItem-${index + 1}`}
                  ref={index === list.length - 1 ? lastElementRef : null}
                >
                  <RecentSearch
                    additionalFiltersCount={additionalFiltersCount}
                    query={parsedQuery}
                    onItemClick={() => {
                      onSelect(query);
                      handleSearchClick(query, index);
                    }}
                  />
                </li>
              )
            )}
          </ul>
        )}
      </ScrollBox>
    </div>
  );
};
