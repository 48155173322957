import React, { ComponentProps, FC } from 'react';
import { useDispatch } from 'react-redux';

import Modal, { ModalContent, SlideDirection } from '@move-ui/modal';
import { MediaQueries } from '@move-ui/theme';

import { useMediaQuery } from '@move-web-essentials-hooks/use-media-query';
import { LocationForm } from './LocationForm';
import { LocationModalHeader } from './LocationModalHeader';

import styles from './LocationModal.css';
import { createArea } from '../../../../shared/domains/geoLocation/entities';
import {
  removeArea,
  saveArea,
} from '../../../../stores/geoLocation/actionCreators';
import useTracking from '../../../../utils/tracking/useTracking';
import { LocationArea } from '../../types/LocationArea';

const modalLabelId = 'LocationModalLabel';

type Props = Pick<
  ComponentProps<typeof LocationForm>,
  'renderResultCounter' | 'onChange' | 'area'
> & {
  isOpen?: boolean;
  onApply: (value: LocationArea | null) => void;
  onCancel: () => void;
  onClose: () => void;
  dontSave?: boolean;
};

export const LocationModal: FC<Props> = ({
  onApply,
  onCancel,
  onChange,
  onClose,
  isOpen,
  renderResultCounter,
  dontSave,
  area,
}) => {
  const isMediumScreen = useMediaQuery(MediaQueries.MEDIUM);
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  const onSubmit: ComponentProps<typeof LocationForm>['onSubmit'] = ({
    addressText,
    isLimitedToProvince,
    radius,
    resolvedLocation,
  }) => {
    if (!addressText || addressText === '') {
      if (!dontSave) {
        dispatch(removeArea());
      }
      onApply(null);
    } else {
      const radiusNumber = radius ? parseInt(`${radius}`, 10) : undefined;
      const newArea = createArea({
        location: resolvedLocation || area?.location,
        isLimitedToProvince,
        radius: radiusNumber,
      });

      if (!dontSave) {
        dispatch(saveArea(newArea));
      }
      onApply(newArea);
    }

    onClose();
  };

  const handleCancel: ComponentProps<typeof LocationForm>['onCancel'] = ({
    initialLocation,
  }) => {
    const isChange = !!area && Object.keys(area).length > 0;
    trackEvent({
      eventAction: 'LocationCancel',
      getEventData: () => ({ eventLabel: isChange ? 'change' : 'set' }),
    });
    onChange({
      ...initialLocation,
      position: initialLocation?.location?.position,
    });
    onCancel();
  };

  return (
    <Modal
      ariaLabelledBy={modalLabelId}
      onRequestClose={() => handleCancel({ initialLocation: area })}
      isOpen={isOpen}
      hideCloseButton
      slideDirection={isMediumScreen ? undefined : SlideDirection.ToTop}
      classNames={{
        header: styles.header,
        base: styles.base,
        box: styles.contentBox,
        contentWrapper: styles.contentWrapper,
      }}
      renderHeader={() => <LocationModalHeader labelId={modalLabelId} />}
    >
      <ModalContent className={styles.content}>
        <LocationForm
          area={area}
          onCancel={handleCancel}
          onChange={onChange}
          onSubmit={onSubmit}
          renderResultCounter={renderResultCounter}
        />
      </ModalContent>
    </Modal>
  );
};
