import { Dispatch } from 'redux';
import { Query } from './../../stores/searchQuery/types/Query';
import { ActionType } from './types/ActionType';
import { State } from './types/State';
import { fetchLatestSearchResults } from './api';
import {
  RequestLatestSearchResultsAction,
  HandleResponseLatestSearchResultsAction,
  HandleResponseErrorLatestSearchResultsAction,
} from './types/Action';

export const requestLatestSearchResultsAction =
  (): RequestLatestSearchResultsAction => ({
    type: ActionType.RequestLatestSearchResults,
  });

export const handleLatestSearchResultSuccessAction = (
  payload: { items?: State['listings'] } | undefined
): HandleResponseLatestSearchResultsAction => ({
  type: ActionType.HandleResponseLatestSearchResults,
  payload,
});

export const handleLatestSearchResultErrorAction = (
  error: Error
): HandleResponseErrorLatestSearchResultsAction => ({
  type: ActionType.HandleResponseErrorLatestSearchResults,
  error,
});

export const requestLatestSearchResult =
  (query: Query) => async (dispatch: Dispatch) => {
    dispatch(requestLatestSearchResultsAction());

    try {
      const data = await fetchLatestSearchResults(query);
      dispatch(handleLatestSearchResultSuccessAction(data));
    } catch (error) {
      dispatch(handleLatestSearchResultErrorAction(error as Error));
    }
  };
