import { IntlFormatters } from 'react-intl';
import { MakeModel } from '../../../shared/types/MakeModel';
import { MakeModelTrim } from '../../../shared/types/MakeModelTrim';
import { createMakeModelLabel } from '../../../utils/filters/makeModel';

export const mapModelValues = ({
  values,
  selectedMakeModels,
  allMakes,
  allModels,
  formatMessage,
}: Pick<IntlFormatters, 'formatMessage'> & {
  values: string[];
  selectedMakeModels: MakeModel[];
  allMakes: MakeModel[];
  allModels: Record<string, MakeModelTrim[]>;
}) => {
  const allMakesMessage = formatMessage({ id: 'filter_placeholder_make' });
  const allModelsMessage = formatMessage({ id: 'filter_placeholder_model' });

  const selectedMakeIds = selectedMakeModels.map((ms) => ms.parentId ?? ms.i);
  const formattedMakeModels = selectedMakeModels
    .map((ms) => {
      const { parentId, i } = ms;
      if (
        !parentId ||
        values.some(
          (value) =>
            value === `${parentId};${i}` ||
            value.startsWith(`${parentId};${i};`)
        )
      ) {
        return ms;
      }
      if (selectedMakeIds.includes(parentId)) {
        const make = allMakes.find((m) => m.i === parentId);
        const n = createMakeModelLabel({ make }, allMakesMessage);
        return { i: make?.i, n };
      }
      return null;
    })
    .filter(Boolean) as MakeModel[];

  return [
    ...formattedMakeModels,
    ...values
      .filter(
        (makeModelTrim) =>
          !formattedMakeModels.some(
            (ms) => makeModelTrim === `${ms.parentId};${ms.i}`
          )
      )
      .filter(Boolean)
      .map((ms) => {
        const [makeId, modelId] = ms.split(';');
        const make = allMakes.find(({ i }) => i === Number(makeId));
        const model = allModels[makeId]?.find((m) => m?.i === Number(modelId));
        const n = createMakeModelLabel({ make, model }, allModelsMessage);

        return { n, parentId: make?.i, i: model?.i };
      }),
  ].filter(
    // filter out make if there is already a make+model in the array
    (item, index, arr) =>
      item.parentId ||
      !arr.some(
        ({ parentId }, arrayIndex) =>
          index !== arrayIndex && parentId && item.i === parentId
      )
  );
};
