export const transformRangeValueFromQueryString = (
  { value = '' }: { value?: string },
  parseFn = (theValue: string): string | number => parseInt(theValue, 10)
) => {
  if (!value) {
    return { from: '', to: '' };
  }
  const [from, to] = value.split(':');

  const parsedFrom = parseFn(from);
  const parsedTo = parseFn(to);

  return {
    from: Number.isNaN(parsedFrom) ? '' : `${parsedFrom}`,
    to: Number.isNaN(parsedTo) ? '' : `${parsedTo}`,
  };
};

type Range = {
  from?: string;
  to?: string;
};

export const transformRangeValueToQueryString = ({
  from = '',
  to = '',
}: Range) => (from || to ? `${from}:${to}` : undefined);
