import React, { useRef, useEffect } from 'react';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  SearchHitCounter,
  selectIsLoadingSearchHit,
} from '../../../features/searchHitCounter';
import { MakeModelTrimField } from '../../../features/makeModelTrimSearch';

import trackMakeModelChange from './AdvancedSearchModal.tracking';
import { DetailSearch } from '../../../features/filter/components/DetailSearch';
import {
  selectPreparatoryQuery,
  updatePreparatoryQuery as updatePreparatoryQueryAction,
} from '../../../stores/searchQuery';

import { getIsDspOpen } from '../../../features/filter/selectors';

export const AdvancedSearchModal = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const queryRef = useRef({});
  const isOpen = useSelector(getIsDspOpen);
  const shouldWaitToSubmit = useSelector(selectIsLoadingSearchHit);
  const preparatoryQuery = useSelector(selectPreparatoryQuery);

  useEffect(() => {
    if (!isOpen) {
      queryRef.current = preparatoryQuery;
    }
  }, [isOpen, preparatoryQuery]);

  const updatePreparatoryQuery = (query) =>
    dispatch(updatePreparatoryQueryAction(query));

  // eslint-disable-next-line react/prop-types
  const renderMakeModelSearch = ({ setMakeModel }) => (
    <MakeModelTrimField
      withTrim
      onChange={(e) => {
        setMakeModel(e);
        trackMakeModelChange();
      }}
    />
  );

  return (
    <DetailSearch
      onClose={() => updatePreparatoryQuery(queryRef.current)}
      onChangeQuery={updatePreparatoryQuery}
      onSubmit={() => onSubmit(preparatoryQuery)}
      query={preparatoryQuery}
      renderMakeModelSearch={renderMakeModelSearch}
      renderResultCounter={() => <SearchHitCounter key={`${isOpen}`} />}
      shouldWaitToSubmit={shouldWaitToSubmit}
    />
  );
};

AdvancedSearchModal.propTypes = {
  onSubmit: func.isRequired,
};
