import { PageType } from './types/PageType';
import { Page } from './types/Page';

export const getPageType = (
  page: Page,
  {
    isLifestyleSrp,
    hasContactedDealer,
  }: { isLifestyleSrp?: boolean; hasContactedDealer?: boolean } = {}
) => {
  switch (page) {
    case Page.Home:
      return PageType.Home;

    case Page.SRP:
      if (isLifestyleSrp === undefined) {
        throw new Error(
          'must provide property `isLifestyleSrp` in options for srp'
        );
      }
      return isLifestyleSrp
        ? PageType.SRPWithCategory
        : PageType.SRPWithSearchResult;

    case Page.VIP:
      if (hasContactedDealer === undefined) {
        throw new Error(
          'must provide property `hasContactedDealer` in options for vip'
        );
      }
      return hasContactedDealer ? PageType.VIPAfterMessage : PageType.VIP;

    default:
      throw new Error(`does not support page value ${page}`);
  }
};
