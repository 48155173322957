export enum Proposition {
  DigitalRetail = 'DigitalRetail',
  PriceTrans = 'PriceTrans',
  CPO = 'cpo',
  BestDeal = 'BestDeal',
  DealerTrust = 'DealerTrust',
  PriceAnalysis = 'PriceAnalysis',
  CarHistory = 'CarHistory',
}

export type USP = {
  id: Proposition;
  image: string;
  title: string;
  description: string;
  url?: string;
};
