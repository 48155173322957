import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { Headline, TextSize } from '@move-ui/text';

import { ALL_MAKES } from '../config';
import RouterLink from '../../../shared/components/RouterLink';
import useTracking from '../../../utils/tracking/useTracking';
import { BROWSE_CLICK } from '../tracking';
import normalizeMakes from '../utils/normalizeMakes';
import styles from './AllMakes.css';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../stores/i18n';

export const AllMakes: FC = memo(() => {
  const locale = useSelector(getCurrentLocale);

  const { trackEvent } = useTracking();
  const { formatMessage } = useIntl();

  if (!ALL_MAKES[locale]?.length) {
    return null;
  }

  const allMakes = normalizeMakes(ALL_MAKES[locale], locale);

  return (
    <section className={styles.component}>
      <Headline
        bold
        className={styles.headline}
        color="neutral-100"
        level={2}
        size={TextSize.L}
      >
        {formatMessage({ id: 'all_makes' })}
      </Headline>

      <ul className={styles.linkList} data-testid="all-makes-link-list">
        {allMakes.map(({ url = '', title }) => (
          <RouterLink
            key={url}
            to={url}
            rootTagName="li"
            wrapperClassName={styles.linkListItem}
            linkClassName={styles.link}
            onClick={() => trackEvent(BROWSE_CLICK, title)}
          >
            <Headline
              className={styles.make}
              level={4}
              bold
              color="neutral-200"
              size={TextSize.M}
            >
              {title}
            </Headline>
          </RouterLink>
        ))}
      </ul>
    </section>
  );
});
