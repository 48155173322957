import React, { useRef, FC } from 'react';

import { MediaQueries } from '@move-ui/theme';
import RouterLink from '../RouterLink';
import LoadingState from '../LoadingState';
import { ErrorLoadingResource } from '../Error';
import ScrollBox from '../ScrollBox';
import { useMediaQuery } from '@move-web-essentials-hooks/use-media-query';

import useVIPProvisionImage from '../../hooks/useVIPProvisionImage';

import Listing from './Listing';

import styles from './ListingsSlider.css';
import { CONTACT_TYPE } from '../../../constants/sellerTypes';
import { ListingItem, RenderAdditionalActionButtons } from './types';

type Props = {
  list: ListingItem[];
  renderAdditionalActionButtons?: RenderAdditionalActionButtons;
  goToVip: (item: ListingItem, transactionId?: string) => void;
  hasError?: boolean;
  isLoading?: boolean;
  onItemClick?: (index: number) => void;
  onScrollPrevious?: (event: Event) => void;
  onScrollNext?: (event: Event) => void;
  isTitleHidden?: boolean;
  lazyLoadImages?: boolean;
  isMultiInquiry?: boolean;
};

const RouterLinkAny: any = RouterLink;

const ListingsSlider: FC<Props> = ({
  list,
  isLoading,
  hasError,
  renderAdditionalActionButtons,
  goToVip,
  onItemClick = () => {},
  onScrollPrevious = () => {},
  onScrollNext = () => {},
  isTitleHidden,
  lazyLoadImages,
  isMultiInquiry,
}) => {
  const isLargeScreen = useMediaQuery(MediaQueries.LARGE);
  const hasItems = Boolean(list?.length);
  const imgDOMRef = useRef({});
  const [setVIPProvisionImage] = useVIPProvisionImage();

  if (!isLoading && !hasError && !hasItems) {
    return null;
  }

  return (
    <div>
      <div className={styles.content}>
        {isLoading && <LoadingState />}
        {hasError && <ErrorLoadingResource />}

        {hasItems && (
          <div data-testid="ListingsSlider">
            <ScrollBox
              areScrollButtonsVisible={isLargeScreen}
              scrollItemCount={list.length}
              onScrollPrevious={onScrollPrevious}
              onScrollNext={onScrollNext}
            >
              {({ scrollContainerRef, lastElementRef }) => (
                <ul className={styles.tiles} ref={scrollContainerRef}>
                  {list.map((item, index) => (
                    <li
                      key={`${item.id}`}
                      className={styles.tile}
                      data-testid={`ListingsSliderAd-${index}`}
                      ref={index === list.length - 1 ? lastElementRef : null}
                    >
                      <RouterLinkAny
                        key={item.id}
                        linkClassName={styles.link}
                        to={goToVip(item, item.transactionId)}
                        rootTagName="div"
                        onClick={() => {
                          setVIPProvisionImage(imgDOMRef.current[item.id]);
                          onItemClick(index);
                        }}
                        masked
                      >
                        <Listing
                          id={item.id}
                          index={index}
                          image={item.images[0]}
                          title={item.title}
                          structuredTitle={item.structuredTitle}
                          prices={item.prices}
                          isDealer={item.contact?.type !== CONTACT_TYPE.PRIVATE}
                          mileage={item.attr?.ml || ''}
                          isMultiInquiry={isMultiInquiry}
                          renderAdditionalActionButtons={
                            renderAdditionalActionButtons
                          }
                          onImageLoaded={(evt) => {
                            imgDOMRef.current[item.id] =
                              evt?.nativeEvent?.target;
                          }}
                          isTitleHidden={isTitleHidden}
                          lazyLoad={lazyLoadImages}
                          province={item.contact?.province}
                          isConditionNew={
                            item.condition === 'New' ||
                            item.condition === 'Neuf'
                          }
                        />
                      </RouterLinkAny>
                    </li>
                  ))}
                </ul>
              )}
            </ScrollBox>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingsSlider;
