import React, {
  ChangeEvent,
  ComponentProps,
  FC,
  MouseEvent,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { CurrencyInput, NumberInput, Position } from '@move-ui/input';
import { ButtonSize, InlineButton, PrimaryButton } from '@move-ui/button';

import {
  FREQUENCY_OPEN,
  FREQUENCY_SUBMIT,
  HOME_SEARCH,
  HOME_SEARCH_CLICK,
  MAKE_MODEL_CHANGE,
  MILEAGE_CHANGE,
  PRICE_CHANGE,
  TAB_SWITCH,
} from '../QuickSearch.tracking';

import styles from './QuickSearch.css';
import { SRP_BASE_PATHS } from '../../../../../shared/config';
import { getCurrentLocale } from '../../../../stores/i18n';
import { Query, selectCategoryName } from '../../../../stores/searchQuery';
import useTracking from '../../../../utils/tracking/useTracking';
import { transformMakeModelToQueryString } from '../../../../utils/filters/makeModel';
import {
  transformRangeValueFromQueryString,
  transformRangeValueToQueryString,
} from '../../../../utils/filters/range';
import { useResultsCount } from '../../hooks/useResultsCount';
import { selectArea } from '../../../../stores/geoLocation';
import { FILTER_PARAMS } from '../../config';
import { relevancySortingQueryParams } from '../../../../shared/domains/sorting/specifications/sorting';
import { QuickSearchHeader } from './QuickSearchHeader';
import { MakeModel } from '../../../../shared/types/MakeModel';
import { Locale } from '../../../../shared/types/Locale';
import {
  isFrequency,
  isMileage,
  isPayment,
  isPrice,
  isPricePayment,
} from '../../../../shared/domains/filters/specifications/filters';
import { FilterName, selectAllFilters } from '../../../../stores/referenceData';
import { SelectBar } from '@move-ui/select-bar';
import { Dropdown } from '@move-ui/dropdown';
import { Frequency } from '../../../../shared/types/Frequency';

type Props = ComponentProps<typeof QuickSearchHeader> & {
  renderMakeModelField: (onChange: (values: MakeModel[]) => void) => ReactNode;
  onAdvancedSearchClick: () => void;
  onSubmit: (query: Query) => void;
  onChangeQuery: (query: Query) => void;
  className?: string;
};

/**
 * Example of a PRICE_PAYMENT filter that this component can work with.
 * The PRICE_PAYMENT must be a tabbed filter where it has 2 tabs (titles of the tabs are used).
 * The tabs must include the PRICE, PAYMENT and FREQUENCY filters (everything else is optional).
 */
// const pricePaymentFilter = {
//   name: 'PRICE_PAYMENT',
//   description: 'Filter for price and payments search',
//   title: 'Price',
//   tabs: [
//     {
//       id: 'PRICE',
//       title: 'Vehicle price',
//       filters: [
//         {
//           name: 'PRICE',
//           description: 'Price of the Car',
//           title: 'Price',
//           type: 'RANGE',
//           param: 'p',
//           min: 0,
//           max: 9999999,
//           lowerBoundLabel: 'From',
//           upperBoundLabel: 'To',
//           currency: true,
//         },
//       ],
//     },
//     {
//       id: 'PAYMENT',
//       title: 'Payments',
//       filters: [
//         {
//           name: 'PAYMENT',
//           description: 'Payment for the Car',
//           title: 'Payment',
//           type: 'RANGE',
//           param: 'payment',
//           min: 0,
//           max: 9999999,
//           lowerBoundLabel: 'Min. Payment',
//           upperBoundLabel: 'Max. Payment',
//           currency: true,
//         },
//         {
//           name: 'FREQUENCY',
//           title: 'Frequency',
//           type: 'SINGLE',
//           param: 'frequency',
//           values: [
//             {
//               name: 'WEEKLY',
//               value: 'WEEKLY',
//               title: 'Weekly',
//               selected: true,
//             },
//             { name: 'BI_WEEKLY', value: 'BI_WEEKLY', title: 'Bi-Weekly' },
//             { name: 'MONTHLY', value: 'MONTHLY', title: 'Monthly ' },
//           ],
//         },
//       ],
//     },
//   ],
//   type: 'TABBED',
// };

/**
 * This is a B variant of the QuickSearch that has the payments filters.
 * For this component to work, the filterData.json (and its value from the backend)
 * must contain the PRICE_PAYMENT filter.
 */
export const QuickSearch: FC<Props> = ({
  className,
  renderMakeModelField,
  renderLocationFilter,
  onAdvancedSearchClick,
  onSubmit,
  onChangeQuery,
}) => {
  const [makeModel, setMakeModel] = useState<MakeModel[]>([]);
  const [query, setQuery] = useState<Query>({});

  const { formatMessage } = useIntl();
  const { trackEvent } = useTracking();

  const area = useSelector(selectArea);

  const locale = useSelector(getCurrentLocale);
  const makeModelCategory = useSelector(selectCategoryName);
  const filters = useSelector(selectAllFilters);

  const currencyPosition =
    locale === Locale.En ? Position.Left : Position.Right;

  const priceFilter = filters.find(isPrice);
  const mileageFilter = filters.find(isMileage);
  const pricePaymentFilter = filters.find(isPricePayment);
  const paymentFilter = filters.find(isPayment);
  const frequencyFilter = filters.find(isFrequency);

  const [tab, setTab] = useState<string>(FilterName.Price);
  const showPrice = tab === FilterName.Price;

  const handleQueryChange = (partialQuery: Query) => {
    const newQuery = { ...query, ...partialQuery };
    setQuery(newQuery);
    onChangeQuery(newQuery);
  };

  const handleToParamChange = (
    { target }: ChangeEvent<HTMLInputElement>,
    param: keyof Query
  ) =>
    handleQueryChange({
      [param]: transformRangeValueToQueryString({ to: target.value }),
    });

  const handleMakeModelChange = (value: MakeModel[]) => {
    setMakeModel(value);
    handleQueryChange({
      [FILTER_PARAMS.makeModel]: transformMakeModelToQueryString(value),
    });
    trackEvent(MAKE_MODEL_CHANGE);
  };

  const resultCountQuery = useMemo(() => {
    if (!area) {
      return { ...query };
    }
    const { location, isLimitedToProvince, radius } = area;
    const { position, province } = location || {};
    return {
      ...query,
      ll: position
        ? [position.latitude, position.longitude].join(',')
        : undefined,
      rd: radius?.toString() || undefined,
      aa: isLimitedToProvince ? province?.code : undefined,
    };
  }, [query, area]);

  const resultsCount = useResultsCount(resultCountQuery);

  const getFromValue = (value?: string) =>
    transformRangeValueFromQueryString({ value });

  const price = getFromValue(query[FILTER_PARAMS.price]);
  const mileage = getFromValue(query[FILTER_PARAMS.mileage]);
  const payment = getFromValue(query[FILTER_PARAMS.payment]);

  const handleSubmit = (event: MouseEvent<Element>) => {
    event.preventDefault();
    trackEvent(HOME_SEARCH, {
      filters: {
        makeModel,
        price: price.to,
        mileage: mileage.to,
        dr: query.digital_retail_eligible,
      },
      makeModelCategory,
    });
    onSubmit({ ...query, ...relevancySortingQueryParams });
    trackEvent(HOME_SEARCH_CLICK);
  };

  const tabOptions = useMemo(
    () =>
      pricePaymentFilter?.tabs?.map(({ id, title }) => ({
        id,
        value: id,
        label: title,
      })) || [],
    [pricePaymentFilter?.tabs]
  );

  const changeTab = (newTab: string) => {
    setTab(newTab);
    if (newTab === FilterName.Price) {
      handleQueryChange({
        [FILTER_PARAMS.payment]: undefined,
        [FILTER_PARAMS.frequency]: undefined,
      });
    } else {
      handleQueryChange({
        [FILTER_PARAMS.price]: undefined,
        [FILTER_PARAMS.mileage]: undefined,
        [FILTER_PARAMS.frequency]: frequencyFilter?.values?.find(
          ({ selected }) => selected
        )?.value as Frequency,
      });
    }
    trackEvent(TAB_SWITCH, newTab);
  };

  return (
    <div className={cx(styles.component, className)}>
      <QuickSearchHeader renderLocationFilter={renderLocationFilter}>
        <SelectBar
          options={tabOptions}
          className={styles.tabSwitcherDesktop}
          onChange={changeTab}
        />
      </QuickSearchHeader>
      <form
        className={styles.form}
        autoComplete="off"
        data-testid="QuickSearch"
        action={SRP_BASE_PATHS[locale]}
      >
        <div className={styles.content}>
          <div className={styles.locationFilter}>{renderLocationFilter()}</div>
          <SelectBar
            options={tabOptions}
            className={styles.tabSwitcher}
            onChange={changeTab}
            large
          />
          <div className={styles.makeModel}>
            {renderMakeModelField(handleMakeModelChange)}
          </div>
          <div className={styles.filterRow}>
            {showPrice && priceFilter && (
              <CurrencyInput
                aria-label={formatMessage({ id: 'filter_label_price_to' })}
                data-testid="quickSearchMaxPrice"
                id="quickSearchMaxPrice"
                min={priceFilter.min}
                max={priceFilter.max}
                onBlur={() => trackEvent(PRICE_CHANGE)}
                onlyInteger
                className={styles.price}
                label={priceFilter.title}
                placeholder={formatMessage({ id: 'QuickSearch.maxPrice' })}
                value={price.to}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleToParamChange(e, FILTER_PARAMS.price)
                }
                currency={formatMessage({ id: 'currency_sign' })}
                currencyPosition={currencyPosition}
              />
            )}
            {showPrice && mileageFilter && (
              <NumberInput
                aria-label={formatMessage({ id: 'filter_label_mileage_to' })}
                data-testid="quickSearchMaxMileage"
                id="quickSearchMaxMileage"
                min={mileageFilter.min}
                max={mileageFilter.max}
                onlyInteger
                className={styles.mileage}
                value={mileage.to}
                onBlur={() => trackEvent(MILEAGE_CHANGE)}
                label={mileageFilter.title}
                placeholder={formatMessage({ id: 'QuickSearch.maxMileage' })}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleToParamChange(e, FILTER_PARAMS.mileage)
                }
              />
            )}
            {!showPrice && paymentFilter && (
              <CurrencyInput
                aria-label={formatMessage({ id: 'filter_label_payment_to' })}
                data-testid="quickSearchMaxPayment"
                id="quickSearchMaxPayment"
                min={paymentFilter.min}
                max={paymentFilter.max}
                onlyInteger
                className={styles.payment}
                label={paymentFilter.title}
                placeholder={formatMessage({ id: 'QuickSearch.maxPayment' })}
                value={payment.to}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleToParamChange(e, FILTER_PARAMS.payment)
                }
                currency={formatMessage({ id: 'currency_sign' })}
                currencyPosition={currencyPosition}
              />
            )}
            {!showPrice && frequencyFilter && (
              <Dropdown
                id="quickSearchFrequency"
                data-testid="quickSearchFrequency"
                name={FILTER_PARAMS.frequency}
                label={frequencyFilter.title}
                options={frequencyFilter.values?.map(({ value, title }) => ({
                  value,
                  label: title,
                }))}
                required
                value={query[FILTER_PARAMS.frequency]}
                onChange={(value) => {
                  handleQueryChange({
                    [FILTER_PARAMS.frequency]: value as Frequency,
                  });
                  trackEvent(FREQUENCY_SUBMIT);
                }}
                onOpen={() => trackEvent(FREQUENCY_OPEN)}
              />
            )}
          </div>
          <footer className={styles.footer}>
            <InlineButton
              className={styles.advancedSearchFooterButton}
              color="brand-300"
              data-testid="AdvancedSearchFooterButton"
              size={ButtonSize.Size200}
              type="button"
              onClick={onAdvancedSearchClick}
            >
              {formatMessage({ id: 'Home_advanced_search' })}
            </InlineButton>
            <PrimaryButton
              data-testid="quickSearchResultButton"
              type="submit"
              className={styles.submitButton}
              size={ButtonSize.Size200}
              onClick={handleSubmit}
            >
              {Number.isInteger(resultsCount)
                ? formatMessage(
                    { id: 'button_results' },
                    { numResultsTotal: resultsCount }
                  )
                : formatMessage({ id: 'result_button_default' })}
            </PrimaryButton>
          </footer>
        </div>
        <InlineButton
          className={styles.advancedSearchButtonDesktop}
          color="brand-300"
          data-testid="AdvancedSearchButton"
          size={ButtonSize.Size200}
          type="button"
          onClick={onAdvancedSearchClick}
        >
          {formatMessage({ id: 'Home_advanced_search' })}
        </InlineButton>
      </form>
    </div>
  );
};
