import React, { useMemo, useState, useEffect, useCallback, FC } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown } from '@move-ui/dropdown';

import { selectMakeModelTrim } from '../../../stores/searchQuery';
import {
  selectMakes,
  selectModels,
  selectTrimTitle,
} from '../../../stores/referenceData';

import { ApplyButton } from './ApplyButton';
import { ResetButton } from './ResetButton';
import { sortByLabel } from '../utils/sortByLabel';
import { mapTrimValues } from '../utils/mapTrimValues';
import { useDropdownSearch } from '../hooks/useDropdownSearch';
import { MakeModelTrimField } from '../types/MakeModelTrimField';

const TrimField: FC<MakeModelTrimField> = ({
  withLabel,
  minMenuWidth,
  onOpen,
  onApply,
  onModalClose,
}) => {
  const { formatMessage } = useIntl();

  const allMakes = useSelector(selectMakes);
  const allModels = useSelector(selectModels);
  const selectedMakeModels = useSelector(selectMakeModelTrim);

  const makes = useMemo(
    () =>
      selectedMakeModels
        .map(({ parentId, i }) => parentId ?? i)
        .filter(Boolean)
        .filter((item, index, array) => array.indexOf(item) === index),
    [selectedMakeModels]
  );
  const models = useMemo(
    () => selectedMakeModels.filter(({ parentId }) => parentId),
    [selectedMakeModels]
  );

  const appliedValues = useMemo(
    () =>
      models
        .filter(({ trm }) => trm)
        .map(({ parentId, i, trm }) => `${parentId};${i};${trm}`),
    [models]
  );
  const [trims, setTrims] = useState(appliedValues);
  const searchParams = useDropdownSearch(`trim,${trims.length}`);

  const labelTitle = useSelector(selectTrimTitle);

  const trimOptions = useMemo(
    () =>
      models
        .filter((model) => model.trims?.length > 0)
        .filter(
          (item, index, array) =>
            array.findIndex(({ modelName }) => item.modelName === modelName) ===
            index
        )
        .map((model) => ({
          label: model.n,
          values: model.trims
            .map((label) => {
              const { parentId, i } = model;
              const value = `${parentId};${i};${label}`;
              return {
                key: value,
                label,
                value,
              };
            })
            .sort(sortByLabel),
        }))
        .sort(sortByLabel),
    [models]
  );

  const handleApply = useCallback(
    (values) => {
      if (values.sort().join() === trims.sort().join()) {
        return;
      }
      setTrims(values);
      onApply(
        mapTrimValues({
          values,
          selectedMakeModels,
          allMakes,
          allModels,
          formatMessage,
        }),
        'trim'
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedMakeModels, allMakes, allModels, formatMessage]
  );

  const handleOpen = useCallback(() => onOpen('trim'), [onOpen]);

  const handleModalClose = useCallback(
    () => onModalClose('trim'),
    [onModalClose]
  );

  useEffect(() => {
    setTrims(appliedValues);
  }, [appliedValues]);

  const modelCount = useMemo(
    () =>
      models.filter(
        (item, index, array) =>
          array.findIndex(({ modelName }) => item.modelName === modelName) ===
          index
      ),
    [models]
  );

  return (
    <Dropdown
      id="trim"
      name="trim"
      value={trims}
      appliedValues={appliedValues}
      onOpen={handleOpen}
      {...(trimOptions.length > 1 || modelCount.length > 1 || makes.length > 1
        ? { sections: trimOptions }
        : { options: trimOptions[0]?.values || [] })}
      onChange={setTrims}
      onApply={handleApply}
      data-testid="TrimFilter"
      minMenuWidth={minMenuWidth}
      placeholder={formatMessage({ id: 'filter_placeholder_trim' })}
      onModalClose={handleModalClose}
      disabled={!models?.length || !trimOptions?.length}
      label={withLabel ? labelTitle : ''}
      multiple
      applyComponent={ApplyButton}
      resetComponent={(props) => <ResetButton {...props} label="trim" />}
      modalLabel={formatMessage(
        { id: 'trim_dropdown_title' },
        {
          count: trims.filter(
            (item, index, array) => array.indexOf(item) === index
          ).length,
        }
      )}
      {...searchParams}
    />
  );
};

export default TrimField;
