import React, { forwardRef, useCallback } from 'react';
import Text, { Headline, TagName, TextSize } from '@move-ui/text';
import { ImageLoader, IMAGE_FIT } from '@move-ui/image-loader';
import { getImageUrl } from '@move-web-essentials-utils/images';
import { PlayPreviewIcon } from '@move-ui/icons-asset-catalog';
import Card from '@move-ui/card';
import styles from './Tile.css';
import { Slide } from '../types/Slide';

const imgRatio = 'r1_0_47';

type Props = {
  tile: Slide;
  index: number;
  onClick?: (tile: { tileId: Slide['id']; tileIndex: Props['index'] }) => void;
};

export const Tile = forwardRef<HTMLLIElement, Props>(
  ({ tile, index, onClick }, ref) => {
    const { id, media = [], url, title, subTitle } = tile;

    const handleClick = useCallback(
      () => onClick?.({ tileId: id, tileIndex: index }),
      [onClick, id, index]
    );

    const imageUrl = media[0]?.image?.urls?.[imgRatio];
    if (!imageUrl) {
      return null;
    }

    const src = getImageUrl(imageUrl, { size: 300, format: 'webp' });
    const src2x = getImageUrl(imageUrl, { size: 640, format: 'webp' });
    const srcSet = `${src}, ${src2x} 2x`;

    const isVideoArticle = media[0]?.video?.id;
    const placeholderRatio = { width: 1, height: 0.47 };

    return (
      <li key={id} className={styles.tile} ref={ref}>
        <Card className={styles.card}>
          <a href={url} onClick={handleClick} className={styles.link}>
            <article className={styles.article}>
              <ImageLoader
                srcSet={srcSet}
                src={src}
                alt={media[0]?.image?.altText}
                fit={IMAGE_FIT.COVER}
                placeholderRatio={placeholderRatio}
                reserveHeight
                lazyLoad
              />
              {isVideoArticle && (
                <div className={styles.videoIcon}>
                  <PlayPreviewIcon />
                </div>
              )}
              <div className={styles.details}>
                <Headline
                  level={3}
                  className={styles.headline}
                  color="neutral-100"
                  size={TextSize.S}
                  bold
                >
                  {title}
                </Headline>
                <br /> <br />
                <Text
                  className={styles.previewText}
                  color="neutral-200"
                  size={TextSize.XS}
                  tagName={TagName.P}
                >
                  {subTitle}
                </Text>
              </div>
            </article>
          </a>
        </Card>
      </li>
    );
  }
);
