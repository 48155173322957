import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useTracking from '../../../utils/tracking/useTracking';
import {
  FREE_TEXT_SEARCH_BEGIN,
  FREE_TEXT_SEARCH_CANCEL,
} from '../components/MakeModelTrim.tracking';
import { NoResults } from '../components/NoResults';
import { selectCategoryName } from '../../../stores/searchQuery';

export const useDropdownSearch = (label: string) => {
  const { formatMessage } = useIntl();
  const { trackEvent } = useTracking();
  const makeModelCategory = useSelector(selectCategoryName);
  const previousValue = useRef('');
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value.length > 0 && previousValue.current.length === 0) {
      trackEvent(FREE_TEXT_SEARCH_BEGIN, { label });
    }
    previousValue.current = value;
    // label doesn't need to be a dependency to not trigger again on selections
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return {
    withSearch: true,
    noResultsComponent: <NoResults />,
    searchProps: {
      placeholder: formatMessage({ id: 'dropdown_search_placeholder' }),
      onChange: setValue,
      onClear: () => {
        setValue('');
        trackEvent(FREE_TEXT_SEARCH_CANCEL, { label, makeModelCategory });
      },
    },
  };
};
