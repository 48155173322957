import { Query } from '../../../stores/searchQuery';
import { getListingsHitCount } from '../../../../api/getListingsHitCount';

const formatHitCounts = (
  filterHitCounts: {
    name: string;
    hitCounts: { value: string; count: number }[];
  }[]
) =>
  Object.fromEntries(
    filterHitCounts.map(({ name, hitCounts }) => [
      name,
      Object.fromEntries(hitCounts.map(({ value, count }) => [value, count])),
    ])
  );

type Props = {
  query: Query;
  name: string;
};
type Options = {
  locale?: string;
  additionalParam?: string;
};

export const getFilterHitCount = async (
  {
    query,
    name,
    withFilterHitcounts = name,
  }: Props & { withFilterHitcounts?: string },
  options: Options
) => {
  const { filterHitCounts } = await getListingsHitCount(
    { ...query, withFilterHitcounts },
    options
  );

  return formatHitCounts(filterHitCounts)[name];
};

export const getMakeModelHitCount = (props: Props, options: Options) =>
  getFilterHitCount({ ...props, withFilterHitcounts: 'MAKE_MODEL' }, options);
