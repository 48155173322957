export const DEFAULT_CAMPAIGN = {
  trackingEnabled: false,
  headline: [],
  link: null,
  theme: 'brand',
};

export const CAMPAIGN_BACKGROUND = 'brand';

export const CROSS_PROMOTIONS = {
  'en-CA': {
    id: 'rxpromo_kijiji',
    title: 'Find parts, services and other types of vehicles on Kijiji',
    tiles: [
      {
        altText: 'Tires & Parts',
        id: 'tiresParts',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/ea/eaa11ee8-e439-4aa3-b750-abc13a8fd9c6',
        media: [
          {
            image: {
              altText: 'Tires & Parts',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/ea/eaa11ee8-e439-4aa3-b750-abc13a8fd9c6',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-auto-parts-tires/c31?utm_campaign=rxpromo_HomepageSlider&utm_content=web_TiresParts&utm_medium=referral&utm_source=kijijiautos',
        title: 'Tires & Parts',
      },
      {
        altText: 'Automotive Services',
        id: 'autoServices',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/99/99335140-fa8b-433f-88ea-ea2879887091',
        media: [
          {
            image: {
              altText: 'Automotive Services',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/99/99335140-fa8b-433f-88ea-ea2879887091',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-automotive-services/c142?utm_campaign=rxpromo_HomepageSlider&utm_content=web_AutoServices&utm_medium=referral&utm_source=kijijiautos',
        title: 'Automotive Services',
      },
      {
        altText: 'Motorcycles',
        id: 'motorcycles',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/69/69fe224f-f367-4078-8037-64dcc9ee84e0',
        media: [
          {
            image: {
              altText: 'Motorcycles',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/69/69fe224f-f367-4078-8037-64dcc9ee84e0',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-motorcycles/c30?utm_campaign=rxpromo_HomepageSlider&utm_content=web_Motorcycles&utm_medium=referral&utm_source=kijijiautos',
        title: 'Motorcycles',
      },
      {
        altText: 'RVs, Campers & Trailers',
        id: 'rvsCampersTrailers',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/b6/b6f70419-177d-49ba-b18e-36cd2e31846c',
        media: [
          {
            image: {
              altText: 'RVs, Campers & Trailers',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/b6/b6f70419-177d-49ba-b18e-36cd2e31846c',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-rv-camper-trailer/c172?utm_campaign=rxpromo_HomepageSlider&utm_content=web_RVsCampersTrailers&utm_medium=referral&utm_source=kijijiautos',
        title: 'RVs, Campers & Trailers',
      },
      {
        altText: 'ATVs & Snowmobiles',
        id: 'atvsSnowmobiles',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/34/3497331e-0a1f-40c4-a3d3-44a431a946e1',
        media: [
          {
            image: {
              altText: 'ATVs & Snowmobiles',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/34/3497331e-0a1f-40c4-a3d3-44a431a946e1',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-atv-snowmobile/c171?utm_campaign=rxpromo_HomepageSlider&utm_content=web_ATVsSnowmobiles&utm_medium=referral&utm_source=kijijiautos',
        title: 'ATVs & Snowmobiles',
      },
      {
        altText: 'Boats',
        id: 'boats',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/e1/e1f331b0-991d-40ce-a181-644c842dac36',
        media: [
          {
            image: {
              altText: 'Boats',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/e1/e1f331b0-991d-40ce-a181-644c842dac36',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-boat-watercraft/c29?utm_campaign=rxpromo_HomepageSlider&utm_content=web_Boats&utm_medium=referral&utm_source=kijijiautos',
        title: 'Boats',
      },
    ],
    type: 'full',
  },
  'fr-CA': {
    id: 'rxpromo_kijiji',
    title:
      'Trouvez des pièces, des services et d’autres types de véhicules sur Kijiji',
    tiles: [
      {
        altText: 'Pièces de véhicules et pneus',
        id: 'tiresParts',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/ea/eaa11ee8-e439-4aa3-b750-abc13a8fd9c6',
        media: [
          {
            image: {
              altText: 'Pièces de véhicules et pneus',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/ea/eaa11ee8-e439-4aa3-b750-abc13a8fd9c6',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-auto-parts-tires/c31?siteLocale=fr_CA&utm_campaign=rxpromo_HomepageSlider&utm_content=web_TiresParts&utm_medium=referral&utm_source=kijijiautos',
        title: 'Pièces de véhicules et pneus',
      },
      {
        altText: 'Services automobiles',
        id: 'autoServices',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/99/99335140-fa8b-433f-88ea-ea2879887091',
        media: [
          {
            image: {
              altText: 'Services automobiles',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/99/99335140-fa8b-433f-88ea-ea2879887091',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-automotive-services/c142?siteLocale=fr_CA&utm_campaign=rxpromo_HomepageSlider&utm_content=web_AutoServices&utm_medium=referral&utm_source=kijijiautos',
        title: 'Services automobiles',
      },
      {
        altText: 'Motos',
        id: 'motorcycles',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/69/69fe224f-f367-4078-8037-64dcc9ee84e0',
        media: [
          {
            image: {
              altText: 'Motos',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/69/69fe224f-f367-4078-8037-64dcc9ee84e0',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-motorcycles/c30?siteLocale=fr_CA&utm_campaign=rxpromo_HomepageSlider&utm_content=web_Motorcycles&utm_medium=referral&utm_source=kijijiautos',
        title: 'Motos',
      },
      {
        altText: 'VR, caravanes et remorques',
        id: 'rvsCampersTrailers',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/b6/b6f70419-177d-49ba-b18e-36cd2e31846c',
        media: [
          {
            image: {
              altText: 'VR, caravanes et remorques',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/b6/b6f70419-177d-49ba-b18e-36cd2e31846c',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-rv-camper-trailer/c172?siteLocale=fr_CA&utm_campaign=rxpromo_HomepageSlider&utm_content=web_RVsCampersTrailers&utm_medium=referral&utm_source=kijijiautos',
        title: 'VR, caravanes et remorques',
      },
      {
        altText: 'VTT et motoneiges',
        id: 'atvsSnowmobiles',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/34/3497331e-0a1f-40c4-a3d3-44a431a946e1',
        media: [
          {
            image: {
              altText: 'VTT et motoneiges',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/34/3497331e-0a1f-40c4-a3d3-44a431a946e1',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-atv-snowmobile/c171?siteLocale=fr_CA&utm_campaign=rxpromo_HomepageSlider&utm_content=web_ATVsSnowmobiles&utm_medium=referral&utm_source=kijijiautos',
        title: 'VTT et motoneiges',
      },
      {
        altText: 'Bateaux et véhicules marins',
        id: 'boats',
        imageUrl:
          'https://media.kijiji.ca/api/v1/autos-prod-statics/images/e1/e1f331b0-991d-40ce-a181-644c842dac36',
        media: [
          {
            image: {
              altText: 'Bateaux et véhicules marins',
              urls: {
                r1_0_75:
                  'https://media.kijiji.ca/api/v1/autos-prod-statics/images/e1/e1f331b0-991d-40ce-a181-644c842dac36',
              },
            },
          },
        ],
        url: 'https://www.kijiji.ca/b-boat-watercraft/c29?siteLocale=fr_CA&utm_campaign=rxpromo_HomepageSlider&utm_content=web_Boats&utm_medium=referral&utm_source=kijijiautos',
        title: 'Bateaux et véhicules marins',
      },
    ],
    type: 'full',
  },
};
