import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import Text, { TextSize } from '@move-ui/text';
import { areTaxesExcluded } from '../../../utils/areTaxesExcluded';
import { hasLuxurySurcharge } from '../../../utils/hasLuxurySurcharge';
import { Price } from '../../types/Price';
import { Province } from '../../../pages/drp/apiTypes';

type Props = {
  className?: string;
  isDealer?: boolean;
  textSize?: TextSize;
  price?: Price;
  isConditionNew?: boolean;
  province?: string;
  isInCard?: boolean;
  isAbbreviated?: boolean;
};

export const Taxes: FC<Props> = ({
  className,
  isDealer,
  price,
  textSize = TextSize.XXXS,
  isConditionNew,
  province,
  isInCard,
  isAbbreviated,
}) => {
  const { formatMessage } = useIntl();
  if (!areTaxesExcluded(isDealer, price)) {
    return null;
  }
  let messageId = 'plus_taxes';
  if (hasLuxurySurcharge(isConditionNew, price, isDealer, province)) {
    if (isInCard) {
      messageId = 'plus_taxes_luxury_surcharge_card';
    } else if (isAbbreviated) {
      messageId = 'plus_taxes_luxury_surcharge_abbr';
    } else {
      messageId = 'plus_taxes_luxury_surcharge';
    }
  }
  if (isDealer && province == Province.AB) {
    messageId = 'plus_gst';
  }

  return (
    <Text
      data-testid="priceItemTax"
      color="neutral-200"
      size={textSize}
      className={className}
    >
      {formatMessage({
        id: messageId,
      })}
    </Text>
  );
};
