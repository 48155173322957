import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import Text, { TextSize } from '@move-ui/text';
import { SynchronizeWarningIcon } from '@move-ui/icons-asset-catalog';

import styles from './ErrorLoadingResource.css';

interface ErrorLoadingResourceProps {
  className?: string;
  iconSize?: number;
  textSize?: TextSize;
}

const ErrorLoadingResource: FC<ErrorLoadingResourceProps> = ({
  className,
  iconSize = 64,
  textSize = TextSize.S,
}) => {
  const { formatMessage } = useIntl();
  return (
    <div className={cx(className, styles.component)}>
      <SynchronizeWarningIcon
        aria-label={formatMessage({
          id: 'error_message_icon_label',
        })}
        className={styles.icon}
        height={iconSize}
        width={iconSize}
      />

      <div className={styles.message}>
        <Text size={textSize} color="neutral-200" bold>
          {formatMessage({ id: 'error_message_headline_loading_not_possible' })}
        </Text>

        <Text size={textSize} color="neutral-200" bold>
          {formatMessage({
            id: 'error_message_subheadline_loading_not_possible',
          })}
        </Text>
      </div>
    </div>
  );
};

export default ErrorLoadingResource;
