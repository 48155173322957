import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import debounce from 'lodash/debounce';

import { getCurrentLocale } from '../../../../stores/i18n';
import { selectArea } from '../../../../stores/geoLocation';
import { getQueryString } from '../../../../stores/location';
import { getForwardedUrlParams } from '../utils/getForwardedUrlParams';
import { Query } from '../../../../stores/searchQuery';

const DEBOUNCE_DELAY = 300;

const getLocationQuery = (area: ReturnType<typeof selectArea> = undefined) => {
  if (!area) {
    return {};
  }

  const { location, isLimitedToProvince, radius } = area;
  const { position } = location || {};

  return {
    ll: position
      ? [position.latitude, position.longitude].join(',')
      : undefined,
    rd: radius ? radius.toString() : undefined,
    aa: isLimitedToProvince ? location?.province?.code : undefined,
  };
};

export const useHitCounts = (
  getHitCounts: (
    args: {
      query: Query;
      name: string;
    },
    options: { locale?: string; additionalParam?: string }
  ) => Promise<Record<string, number>>,
  query: Query,
  name: string,
  fetchEnabled = true
) => {
  const area = useSelector(selectArea);
  const locale = useSelector(getCurrentLocale);
  const queryString = useSelector(getQueryString);

  const cleanupRef = useRef<() => void>();
  const [hitCount, setHitCount] = useState<Record<string, number> | null>(null);

  const fetchResults = async (nextQuery: Query) => {
    const controller = new AbortController();
    cleanupRef.current = () => controller.abort();

    try {
      const hitCounts = await getHitCounts(
        {
          query: { ...getLocationQuery(area), ...nextQuery },
          name,
        },
        { additionalParam: getForwardedUrlParams(queryString), locale }
      );

      setHitCount(hitCounts);
    } catch (error) {
      if ((error as Error).name !== 'AbortError') {
        setHitCount({});
      }
    }
  };

  // Keep the same instance of the fetch results count and rely on parameters being passed
  const debounceFetchResults = useMemo(
    () =>
      debounce((...args: Parameters<typeof fetchResults>) => {
        cleanupRef.current?.();
        fetchResults(...args);
      }, DEBOUNCE_DELAY),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (fetchEnabled) {
      debounceFetchResults(query);
    }
  }, [query, debounceFetchResults, fetchEnabled]);

  useEffect(
    () => () => {
      debounceFetchResults.cancel();
      cleanupRef.current?.();
    },
    [debounceFetchResults]
  );

  return hitCount;
};
