import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { getListingsHitCount } from '../../../../api/getListingsHitCount';
import { REQUEST_HEADER } from '../../../../api/lib/apiClient/requestHeaders';

import RequestController from '../../../utils/RequestController';
import { getCurrentLocale } from '../../../stores/i18n';
import { getForwardedUrlParams } from '../../../shared/domains/filters/utils/getForwardedUrlParams';
import { getQueryString } from '../../../stores/location';
import { Query } from '../../../stores/searchQuery';

const DEBOUNCE_DELAY = 300;

const requestController = new RequestController();
const headers = {
  [REQUEST_HEADER.TRACK_RECENT_SEARCH]: false,
  [REQUEST_HEADER.SEARCH_SOURCE]: 'search-hit-counter',
};

export const useResultsCount = (query: Query) => {
  const [count, setCount] = useState<number | undefined>();
  const locale = useSelector(getCurrentLocale);
  const queryString = useSelector(getQueryString);

  const fetchResultsCount = useCallback(
    // Keep the same instance of the fetchResultsCount by relying on parameters being passed down
    async (nextQuery: Query, nextLocale: string, additionalParam: string) => {
      try {
        const { numResultsTotal } = await requestController.resolveWithLatest(
          getListingsHitCount(nextQuery, {
            locale: nextLocale,
            headers,
            additionalParam,
          })
        );
        setCount(numResultsTotal);
      } catch {
        setCount(undefined);
      }
    },
    []
  );

  const debounceFetchResultsCount = useCallback(
    (...args: Parameters<typeof fetchResultsCount>) =>
      debounce(fetchResultsCount, DEBOUNCE_DELAY)(...args),
    [fetchResultsCount]
  );

  useEffect(() => {
    debounceFetchResultsCount(
      query,
      locale,
      getForwardedUrlParams(queryString)
    );
  }, [debounceFetchResultsCount, query, locale, queryString]);

  return count;
};
