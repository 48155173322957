import React, { Fragment, useCallback } from 'react';
import { func, number, string } from 'prop-types';
import cx from 'classnames';
import Text, { Headline, TextSize } from '@move-ui/text';
import { ImageLoader } from '@move-ui/image-loader';
import { getImageUrl } from '@move-web-essentials-utils/images';
import {
  ExternalLinkIcon,
  PlayPreviewIcon,
} from '@move-ui/icons-asset-catalog';
import Card from '@move-ui/card';
import Link from './Link';
import { tileShape } from '../../propTypes';
import { isExternalUrl } from '../../utils/normalizeTileUrls';
import styles from './Tile.css';

const imgRatio = 'r1_0_75';

const Tile = React.forwardRef(({ tile, parentId, index, onClick }, ref) => {
  const handleClick = useCallback(() => {
    onClick({
      categoryId: parentId,
      tileId: tile?.id,
      tileIndex: index,
    });
  }, [tile?.id, parentId, index, onClick]);

  const imageUrl = tile?.media?.[0]?.image?.urls?.[imgRatio];
  if (!imageUrl) {
    return null;
  }

  const isVideoArticle = Boolean(tile?.media?.[0]?.video?.id);
  const placeholderRatio = {
    width: 1,
    height: 0.76,
  };

  const src = getImageUrl(imageUrl, {
    size: 300,
    format: 'webp',
  });
  const srcSet = `${src}, ${getImageUrl(imageUrl, {
    size: 640,
    format: 'webp',
  })} 2x`;

  const isExternalLink = isExternalUrl(tile.url);

  return (
    <li key={tile.id} className={styles.tile} ref={ref}>
      <Card className={styles.card}>
        <Link
          to={tile.url}
          onClick={handleClick}
          testId={`browseBy-${parentId}-${tile.id}`}
        >
          <article className={styles.article}>
            <ImageLoader
              srcSet={srcSet}
              src={src}
              alt={tile.media?.[0]?.image?.altText}
              fit="cover"
              placeholderRatio={placeholderRatio}
              reserveHeight
              lazyLoad
            />
            {isVideoArticle && (
              <div className={styles.videoIcon}>
                <PlayPreviewIcon />
              </div>
            )}
            <div className={styles.details}>
              <Headline
                level={3}
                className={cx({
                  [styles.headline]: !isExternalLink,
                  [styles.externalHeadline]: isExternalLink,
                })}
                color="neutral-100"
                size={TextSize.S}
                bold
              >
                {tile.title}
              </Headline>
              {isExternalLink && (
                <ExternalLinkIcon className={styles.externalLinkIcon} />
              )}
              {Boolean(tile.subTitle) && (
                <Fragment>
                  <br />
                  <br />
                  <Text
                    className={styles.previewText}
                    color="neutral-200"
                    size={TextSize.XS}
                    tagName="p"
                  >
                    {tile.subTitle}
                  </Text>
                </Fragment>
              )}
            </div>
          </article>
        </Link>
      </Card>
    </li>
  );
});

Tile.propTypes = {
  tile: tileShape.isRequired,
  parentId: string,
  index: number.isRequired,
  onClick: func.isRequired,
};

export default Tile;
