export const LAYOUT = {
  FIXED_GRID: {
    IMAGE_SIZE: 140,
  },
  XX_LARGE: {
    CONTENT_WIDTH: 832,
    GRID_GAP: 16,
  },
  X_LARGE: {
    CONTENT_PERCENTAGE: 0.8,
    PAGE_MARGIN: 160,
    GRID_GAP: 16,
  },
  LARGE: {
    CONTENT_PERCENTAGE: 0.8,
    PAGE_MARGIN: 80,
    GRID_GAP: 16,
  },
  MEDIUM: {
    CONTENT_PERCENTAGE: 1,
    PAGE_MARGIN: 80,
    GRID_GAP: 16,
  },
};
