const BRACKET_PENNIES = 10;
const BRACKET_NICKELS = 25;
const PENNIES_PER_$ = 100;
const NICKELS_PER_$ = 20;
const MAX_BUCKET = '101300';

const convertToBucketString = (count) => `${100000 + Math.floor(count)}`;

/**
 * @name getCustomPriceBucket
 * Given a CPM from a bidding partner, compute/identify the DFP price bucket.
 * IDs are sequential values starting at 1¢ (e.g., "100001", "100002", "100003", ...).
 * If all price buckets were penny for penny, the ID is simple to derive.
 * For example, a CPM of 59¢ would go in the price bucket "100059" (100000 + 0.59 * 100).
 * But, after a threshold of 10$, the buckets are by the nickel.
 * Therefore, an approach similar to income tax bracketing is used.
 * So, we can count increments across thresholds and keep the IDs sequential.
 * The price bucket IDs and CPM thresholds are devised by Ad Ops.
 * @param {Number} cpm The CPM (cost per 1000 impressions) to be bucketed.
 * @returns {String}
 */
export default (cpm) => {
  if (cpm < BRACKET_PENNIES) {
    return convertToBucketString(cpm * PENNIES_PER_$);
  }
  if (cpm < BRACKET_NICKELS) {
    const pennies = BRACKET_PENNIES * PENNIES_PER_$;
    const count = pennies + (cpm - BRACKET_PENNIES) * NICKELS_PER_$;
    return convertToBucketString(count);
  }
  return MAX_BUCKET;
};
