import React, {
  useCallback,
  Fragment,
  useEffect,
  useRef,
  FC,
  ComponentProps,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { DetailSearchModal } from '../../../shared/components/DetailSearchModal';
import usePreloadComponentOnIdle from '../../../shared/hooks/usePreloadComponentOnIdle';
import { getIsDspOpen, selectFilterModal } from '../selectors';
import { closeDSP, resetFilterModal } from '../actionCreators';

import {
  Query,
  selectPreparatoryQuery,
  setPreparatoryQuery,
} from '../../../stores/searchQuery';
import { selectFilterConfiguration } from '../../../stores/referenceData';
import FilterModal from './FilterModal';
import { FilterMode } from '../types/FilterMode';

type Props = Pick<
  ComponentProps<typeof DetailSearchModal | typeof FilterModal>,
  'renderMakeModelSearch' | 'renderResultCounter'
> & {
  onChangeQuery: (query: Query) => void;
  onSubmit: (query: Query) => void;
  onClose?: () => void;
  query: Query;
  shouldWaitToSubmit?: boolean;
};

export const DetailSearch: FC<Props> = ({
  onChangeQuery,
  onSubmit,
  onClose,
  query,
  renderMakeModelSearch,
  renderResultCounter,
  shouldWaitToSubmit,
}) => {
  const dispatch = useDispatch();
  const queryBeforeFilterModal = useRef<Query>({});
  const onSetQuery = useCallback(
    (newQuery: Query) => dispatch(setPreparatoryQuery(newQuery)),
    [dispatch]
  );
  const close = () => {
    dispatch(closeDSP());
    onClose?.();
  };
  const preparatoryQuery = useSelector(selectPreparatoryQuery);
  const isOpen = useSelector(getIsDspOpen);

  const filterConfiguration = useSelector(selectFilterConfiguration);
  const filterModal = useSelector(selectFilterModal);

  const handleOnCancel = useCallback(
    () => onSetQuery(queryBeforeFilterModal.current),
    [onSetQuery]
  );

  const handleSubmit = useCallback(() => {
    onSubmit(preparatoryQuery);
    dispatch(closeDSP());
  }, [onSubmit, preparatoryQuery, dispatch]);

  const LazyDetailSearchModal = usePreloadComponentOnIdle(
    DetailSearchModal,
    () => isOpen
  );

  const LazyFilterModal = usePreloadComponentOnIdle(
    FilterModal,
    () => !!filterModal?.type
  );

  const currentFilter = filterConfiguration?.find(
    ({ name }) => name === filterModal?.type
  );
  const filterModalOpen =
    !!currentFilter && filterModal?.mode === FilterMode.DSP;

  useEffect(() => {
    if (!filterModalOpen) {
      queryBeforeFilterModal.current = preparatoryQuery;
    }
  }, [filterModalOpen, preparatoryQuery]);

  return (
    <Fragment>
      {filterModalOpen && (
        <LazyFilterModal
          filterConfig={currentFilter}
          data={filterModal}
          query={preparatoryQuery}
          onSubmitFilter={() => dispatch(resetFilterModal())}
          onChangeQuery={onChangeQuery}
          onCancel={handleOnCancel}
          renderResultCounter={renderResultCounter}
          shouldWaitToSubmit={shouldWaitToSubmit}
          renderMakeModelSearch={renderMakeModelSearch}
          skipRecentChangessubmit
        />
      )}
      <LazyDetailSearchModal
        query={query}
        isOpen={isOpen}
        onChangeQuery={onChangeQuery}
        onSetQuery={onSetQuery}
        onSubmit={handleSubmit}
        onRequestClose={close}
        renderMakeModelSearch={renderMakeModelSearch}
        renderResultCounter={renderResultCounter}
        shouldWaitToSubmit={shouldWaitToSubmit}
      />
    </Fragment>
  );
};
