import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import Text, { TextSize } from '@move-ui/text';

import styles from './LocationModalHeader.css';

type Props = {
  labelId?: string;
};

export const LocationModalHeader: FC<Props> = ({ labelId }) => {
  const { formatMessage } = useIntl();
  return (
    <header className={styles.component}>
      <h2 id={labelId} className={styles.headline}>
        <Text
          size={TextSize.L}
          color="neutral-100"
          bold
          data-testid="LocationModalHeaderTitle"
        >
          {formatMessage({ id: 'filter_label_location' })}
        </Text>
      </h2>
    </header>
  );
};
