//
// use this file to define A/B tests
//
// IMPORTANT: don't put feature tests here, use feature-tests.js for that
//
// example a/b test:
// export const MY_AB_TEST = {
//  key: 'MOVE-1234-my-ab-test',
//  ticketCode: '1234',
//  variations: {
//    VARIATION_CONTROL: 'a',
//    VARIATION_TEST: 'b',
//  },
// };

type AbExperiment = {
  key: string;
  ticketCode: string;
  variations: { [key: string]: string };
};

export const SRP_HIDE_HIGHLIGHTS: AbExperiment = {
  key: 'MOVE-10887-srp-hide-highlights',
  ticketCode: '10887',
  variations: {
    SHOW_HIGHLIGHTS: 'a',
    HIDE_HIGHLIGHTS: 'b',
  },
};

export const CONDENSED_VIP: AbExperiment = {
  key: 'kcan-13220-condensed-vip-redesign',
  ticketCode: '13220',
  variations: {
    VARIATION_CONTROL: 'a',
    VARIATION_CONDENSED_VIP: 'b',
  },
};

export const SAVE_LISTING_CTA_SRP: AbExperiment = {
  key: 'kcan-11947_save_listing_cta_srp',
  ticketCode: '11947',
  variations: {
    VARIATION_CONTROL: 'a',
    VARIATION_SAVE_CTA: 'b',
  },
};

export const SAVE_LISTING_CTA_VIP: AbExperiment = {
  key: 'kcan-11947_save_listing_cta_vip',
  ticketCode: '11947',
  variations: {
    VARIATION_CONTROL: 'a',
    VARIATION_SAVE_CTA: 'b',
  },
};

export const SAVE_SEARCH_CTA_SRP: AbExperiment = {
  key: 'kcan-11947_save_search_cta_srp',
  ticketCode: '11947',
  variations: {
    VARIATION_CONTROL: 'a',
    VARIATION_SAVE_CTA: 'b',
  },
};

export const AD_OPTIMIZATION: AbExperiment = {
  key: 'kcan-14416-ad-optimization',
  ticketCode: '14416',
  variations: {
    VARIATION_CONTROL: 'a',
    VARIATION_AD_OPTIMIZATION: 'b',
  },
};
