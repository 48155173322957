import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '@move-web-essentials-utils/images';
import { MediaQueries } from '@move-ui/theme';

import cx from 'classnames';
import { useMediaQuery } from '@move-web-essentials-hooks/use-media-query';

import { CAMPAIGN_BACKGROUND } from '../config';
import { THEME } from '../constants';
import styles from './HomePage.css';

export const HomeCampaignBackground = ({ campaign }) => {
  const isMediumScreen = useMediaQuery(MediaQueries.MEDIUM);
  return (
    <span
      className={cx(styles.campaignBackground, {
        [styles.themeBrand]:
          campaign.theme === THEME.BRAND && CAMPAIGN_BACKGROUND === 'brand',
      })}
      style={
        campaign.image && isMediumScreen
          ? {
              backgroundImage: `url(${getImageUrl(campaign.image, {
                size: 1600,
              })})`,
            }
          : {}
      }
      data-testid="HomeCampaignBackground"
    />
  );
};

HomeCampaignBackground.propTypes = {
  campaign: PropTypes.shape({
    trackingEnabled: PropTypes.bool,
    headline: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.string,
    link: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    theme: PropTypes.oneOf(Object.values(THEME)),
  }),
};
