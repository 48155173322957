import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Headline, TextSize } from '@move-ui/text';
import styles from './QuickSearchHeader.css';

type Props = {
  renderLocationFilter: () => ReactNode;
};

export const QuickSearchHeader: FC<Props> = ({
  renderLocationFilter,
  children,
}) => {
  const { formatMessage } = useIntl();
  return (
    <header className={styles.header}>
      <Headline
        size={TextSize.XXL}
        level={1}
        color="neutral-100"
        className={styles.headline}
      >
        {formatMessage({ id: 'home_h1' })}
      </Headline>
      <div className={styles.headerLocationFilter}>
        {renderLocationFilter()}
      </div>
      {children}
    </header>
  );
};
