import { Action } from './types/Action';
import { State } from './types/State';

export const addRecentlyChangedFilter = (
  payload: State['recentlyChangedFilters'][0]
) => ({
  type: Action.RecentlyChangedFilter,
  payload,
});

export const resolveLocation = (payload: State['resolvedLocation']) => ({
  type: Action.ResolveLocation,
  payload,
});

export const setFilterModal = (payload: State['filterModal']) => ({
  type: Action.SetFilterModal,
  payload,
});

export const resetFilterModal = () => ({
  type: Action.ResetFilterModal,
});

export const openDSP = () => ({
  type: Action.OpenDSP,
});

export const closeDSP = () => ({
  type: Action.CloseDSP,
});

export const incrementChangesCount = () => ({
  type: Action.IncrementChangesCount,
});

export const resetChangesCount = () => ({
  type: Action.ResetChangesCount,
});
