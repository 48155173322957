import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setVIPProvisionalBase64Image } from '../../../pages/vip/actionCreators';

const useVIPProvisionImage = () => {
  const dispatch = useDispatch();
  const setVIPProvisionImage = useCallback(
    (image) => dispatch(setVIPProvisionalBase64Image(image)),
    [dispatch]
  );

  return [setVIPProvisionImage];
};

export default useVIPProvisionImage;
