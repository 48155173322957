import React from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import cx from 'classnames';
import { Headline, TextSize } from '@move-ui/text';
import { MediaQueries } from '@move-ui/theme';
import ScrollBox from '../../../shared/components/ScrollBox';
import { isValidBrowseByCategoryType, CATEGORY_TYPES } from '../types';
import { mediaShape } from '../propTypes';
import Tile from './Tile';
import styles from './Category.css';
import { useMediaQuery } from '@move-web-essentials-hooks/use-media-query';

const DEFAULT_IMG_RATIO = 'r1_1';

const getGridStyles = (categoryStyles, count) => {
  if (count % 5 === 0) {
    return categoryStyles['by-5'];
  }
  if (count % 4 === 0) {
    return categoryStyles['by-4'];
  }
  return categoryStyles['by-3'];
};

const getGridColumns = (count, fixed, type) => {
  if (fixed) {
    // 0 represents fixed width always
    return 0;
  }
  if (count % 5 === 0) {
    return 5;
  }
  if (count % 4 === 0) {
    return 4;
  }
  return type === CATEGORY_TYPES.FULL ? 3 : 6;
};

const getTypeStyles = (categoryStyles, type) =>
  isValidBrowseByCategoryType(type)
    ? categoryStyles[`type-${type}`]
    : categoryStyles['type-full'];

const Category = ({
  title: categoryTitle,
  id: categoryId,
  imgRatio = DEFAULT_IMG_RATIO,
  tiles,
  type,
  onClickTile,
  isAlwaysHorizontalScrollable,
  lazyLoadFromIndex,
}) => {
  const isLargeScreen = useMediaQuery(MediaQueries.LARGE);

  if (!tiles?.length || !isValidBrowseByCategoryType(type)) {
    return null;
  }

  return (
    <section
      className={cx(styles.component, {
        [styles.isAlwaysHorizontalScrollable]: isAlwaysHorizontalScrollable,
      })}
      data-testid="browseBy-category"
    >
      <Headline
        level={2}
        className={styles.headline}
        size={TextSize.L}
        color="neutral-100"
        data-testid={`browseBy-${categoryId}-headline`}
      >
        {categoryTitle}
      </Headline>

      <ScrollBox
        areScrollButtonsVisible={isAlwaysHorizontalScrollable && isLargeScreen}
        scrollItemCount={tiles.length}
      >
        {({ scrollContainerRef, lastElementRef }) => (
          <ul className={styles.tiles} ref={scrollContainerRef}>
            {tiles.map(({ id, title, url, media }, index) => (
              <li
                key={`${categoryId}_${id}`}
                className={cx(
                  styles.tile,
                  getTypeStyles(styles, type),
                  getGridStyles(styles, tiles.length)
                )}
                ref={index === tiles.length - 1 ? lastElementRef : null}
              >
                <Tile
                  title={title}
                  altText={media?.[0]?.image?.altText ?? ''}
                  url={url}
                  imageUrl={
                    media?.[0]?.image?.urls?.[imgRatio] ||
                    media?.[0]?.image?.urls?.[DEFAULT_IMG_RATIO]
                  }
                  type={type}
                  grid={getGridColumns(
                    tiles.length,
                    isAlwaysHorizontalScrollable,
                    type
                  )}
                  categoryId={categoryId}
                  id={id}
                  onClick={() =>
                    onClickTile?.({ tileId: id, tileIndex: index + 1 })
                  }
                  lazyLoad={index >= lazyLoadFromIndex}
                />
              </li>
            ))}
          </ul>
        )}
      </ScrollBox>
    </section>
  );
};

Category.propTypes = {
  id: string.isRequired,
  imgRatio: string,
  title: string.isRequired,
  type: string.isRequired,
  tiles: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
      url: string.isRequired,
      media: arrayOf(mediaShape).isRequired,
    })
  ),
  onClickTile: func,
  isAlwaysHorizontalScrollable: bool,
  isLargeScreen: bool,
  lazyLoadFromIndex: number,
};

export default Category;
