import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import Text, { TextSize } from '@move-ui/text';
import styles from './NoResults.css';

export const NoResults: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <Text
      size={TextSize.M}
      bold
      className={styles.noResults}
      color="neutral-200"
    >
      {formatMessage({ id: 'dropdown_search_no_results' })}
    </Text>
  );
};
