import { omitBy, isEmpty } from 'lodash';
import { Filter } from '../../../../stores/referenceData';
import { Query } from '../../../../stores/searchQuery';
import { filterQueryParamsToCount } from '../specifications/query';

export const countFilters = (
  query: Query = {},
  filterConfiguration: Filter[] = [],
  exclude?: string[]
) => {
  const queryParamsToCount = filterQueryParamsToCount(
    Object.keys(omitBy(query, isEmpty) || {}) as (keyof Query)[],
    exclude
  );

  return filterConfiguration
    .flatMap((filter) =>
      filter.tabs ? filter.tabs.flatMap((tab) => tab.filters) : filter
    )
    .reduce((acc, { param, values }) => {
      // if a filter param is used directly
      if (queryParamsToCount.includes(param as keyof Query)) {
        return acc + 1;
      }

      // or if a value is used
      if (!param && values) {
        return (
          acc +
          values.filter((filterValue) => {
            const valueParam = filterValue.param as keyof Query;
            if (!queryParamsToCount.includes(valueParam)) {
              return false;
            }

            if (Array.isArray(query[valueParam])) {
              return query[valueParam]?.includes(filterValue.value);
            }

            return query[valueParam] === filterValue.value;
          }).length
        );
      }

      return acc;
    }, 0);
};
