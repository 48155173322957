export const getExperimentGroupsAsQueryParam = (
  experimentGroups: Record<string, string>
) => {
  let queryParam = '';

  Object.entries(experimentGroups).forEach(
    ([experimentName, experimentVariation]) => {
      queryParam += `&experimentgroup[${experimentName}]=${experimentVariation}`;
    }
  );

  return queryParam;
};
