import queryString from 'querystring';
import apiClient from './lib/apiClient';
import { urls } from './urls';
import { omitFalsyValues } from './omitFalsyValues';
import { Query } from '../app/stores/searchQuery';

export const getListingsHitCount = (
  query: Query & { withFilterHitcounts?: string },
  options: {
    locale?: string;
    headers?: Record<string, string | boolean | number>;
    additionalParam?: string;
  } = {}
): Promise<{
  numResultsTotal: number;
  filterHitCounts: {
    name: string;
    hitCounts: { value: string; count: number }[];
  }[];
}> => {
  const { additionalParam, ...restOptions } = options;
  const qs = queryString.stringify(omitFalsyValues(query));
  const doRequest = apiClient({
    metricsId: 'svc_getListingsHitCount',
    ...restOptions,
  });

  return doRequest({
    url: `${urls.hitCount}${qs || additionalParam ? '?' : ''}${qs}${
      additionalParam ? `${qs ? '&' : ''}${additionalParam}` : ''
    }`,
  });
};
