import React, { useCallback, Fragment, FC } from 'react';
import { useSelector } from 'react-redux';

import {
  DROPDOWN_CANCEL_ACTION,
  DROPDOWN_OPEN_ACTION,
  DROPDOWN_SUBMIT_ACTION,
} from './MakeModelTrim.tracking';

import TrimField from './TrimField';
import MakeField from './MakeField';
import ModelField from './ModelField';

import styles from './MakeModelTrim.css';
import useTracking from '../../../utils/tracking/useTracking';
import { selectCategoryName } from '../../../stores/searchQuery';
import {
  getMakeModelTrimTrackingCategory,
  transformMakeModelToQueryString,
} from '../../../utils/filters/makeModel';
import { selectMakeModelTree } from '../../../stores/referenceData';
import { MakeModelTrim as MakeModelTrimType } from '../../../shared/types/MakeModelTrim';

type Props = {
  withTrim?: boolean;
  withLabel?: boolean;
  minMenuWidth?: string;
  useAppliedQuery?: boolean;
  onChange?: (query: Partial<MakeModelTrimType>[]) => void;
  onTrackClick?: () => void;
};

const MakeModelTrim: FC<Props> = ({
  onChange,
  minMenuWidth,
  onTrackClick,
  withTrim,
  withLabel,
  useAppliedQuery,
}) => {
  const { trackEvent } = useTracking();
  const makeModelTree = useSelector(selectMakeModelTree);
  const makeModelCategory = useSelector(selectCategoryName);

  const handleOpen = useCallback(
    (label: string) => {
      trackEvent(DROPDOWN_OPEN_ACTION, { label, makeModelCategory });
      onTrackClick?.();
    },
    [makeModelCategory, trackEvent, onTrackClick]
  );

  const handleApply = useCallback(
    (values: Partial<MakeModelTrimType>[], label: string) => {
      const getCount = () => {
        if (label === 'make') {
          return values
            .map(({ i, parentId }) => parentId ?? i)
            .filter((item, index, array) => array.indexOf(item) === index)
            .length;
        }
        if (label === 'model') {
          return values
            .filter(({ parentId }) => parentId)
            .map(({ i }) => i)
            .filter((item, index, array) => array.indexOf(item) === index)
            .length;
        }
        return values.map(({ trm }) => trm).filter(Boolean).length;
      };

      onChange?.(values);

      const trackingMakeModelsCategory = getMakeModelTrimTrackingCategory({
        makeModelTree,
        makeModelQuery: transformMakeModelToQueryString(values),
      });

      trackEvent(DROPDOWN_SUBMIT_ACTION, {
        label: `${label}, ${getCount()}`,
        makeModelCategory:
          trackingMakeModelsCategory && trackingMakeModelsCategory.length > 0
            ? trackingMakeModelsCategory
            : undefined,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [makeModelCategory, trackEvent, onChange]
  );

  const handleModalClose = useCallback(
    (label: string) =>
      trackEvent(DROPDOWN_CANCEL_ACTION, { label, makeModelCategory }),
    [makeModelCategory, trackEvent]
  );

  return (
    <Fragment>
      <MakeField
        onOpen={handleOpen}
        withLabel={withLabel}
        onApply={handleApply}
        minMenuWidth={minMenuWidth}
        onModalClose={handleModalClose}
        useAppliedQuery={useAppliedQuery}
      />
      <div className={styles.gap} />
      <ModelField
        onOpen={handleOpen}
        withLabel={withLabel}
        onApply={handleApply}
        minMenuWidth={minMenuWidth}
        onModalClose={handleModalClose}
        useAppliedQuery={useAppliedQuery}
      />
      {withTrim && (
        <Fragment>
          <div className={styles.gap} />
          <TrimField
            onOpen={handleOpen}
            withLabel={withLabel}
            onApply={handleApply}
            minMenuWidth={minMenuWidth}
            onModalClose={handleModalClose}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default MakeModelTrim;
