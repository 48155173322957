import React, { FC, ComponentProps } from 'react';
import { GhostButton, ButtonSize } from '@move-ui/button';
import { useIntl } from 'react-intl';
import useTracking from '../../../utils/tracking/useTracking';
import { RESET_BUTTON_CLICK } from './MakeModelTrim.tracking';

type Props = Omit<
  ComponentProps<typeof GhostButton>,
  'label' | 'onClick' | 'size'
> & {
  label?: string;
  onClick: () => void;
};

export const ResetButton: FC<Props> = ({ label, onClick, ...rest }) => {
  const { formatMessage } = useIntl();
  const { trackEvent } = useTracking();
  return (
    <GhostButton
      {...rest}
      size={ButtonSize.Size100}
      onClick={() => {
        trackEvent(RESET_BUTTON_CLICK, { label });
        onClick();
      }}
    >
      {formatMessage({ id: 'dropdown_reset_all' })}
    </GhostButton>
  );
};
