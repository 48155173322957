import React, { ComponentProps, FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { isPricePayment } from '../../shared/domains/filters/specifications/filters';
import { selectAllFilters } from '../../stores/referenceData';

import { QuickSearch as QuickSearchA } from './components/QuickSearch';
import { QuickSearch as QuickSearchB } from './components/QuickSearchB/QuickSearch';

type Props = ComponentProps<typeof QuickSearchA | typeof QuickSearchB>;

export const QuickSearch: FC<Props> = memo((props) => {
  const filters = useSelector(selectAllFilters);

  const Component = filters.find(isPricePayment) ? QuickSearchB : QuickSearchA;

  return <Component {...props} />;
});
