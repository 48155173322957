import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { Headline, TextSize } from '@move-ui/text';
import { Link } from '@move-ui/link';
import useTileTracking from '../hooks/useTileTracking';
import useValuePropositions from '../hooks/useValuePropositions';
import { VALUE_PROPOSITIONS } from '../config';
import Box from './Box';

import styles from './ValueProposition.css';

type Props = {
  className?: string;
};

const ValueProposition: FC<Props> = ({ className }) => {
  const { formatMessage } = useIntl();
  const trackTileClick = useTileTracking();
  const propositions = useValuePropositions(VALUE_PROPOSITIONS);

  return (
    <section className={cx(styles.section, className)}>
      <Headline
        className={styles.headline}
        level={2}
        color="neutral-100"
        size={TextSize.L}
      >
        {formatMessage({ id: 'home_value_prop_title' })}
      </Headline>
      <ul className={styles.list}>
        {propositions.map(({ id, image, description, url, title }, index) => (
          <li className={styles.item} key={id}>
            {Boolean(url) ? (
              <Link href={url} onClick={() => trackTileClick(id, index)}>
                <Box description={description} image={image} title={title} />
              </Link>
            ) : (
              <Box description={description} image={image} title={title} />
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ValueProposition;
